<template>
  <!-- eslint-disable -->
  <!-- <div class="header_module" v-if="createdState || $isQw"> -->
  <div class="header_module">
    <div class="top-box flex-a-c-center">
      <div class="flex-a-c-center flex-1">
        <div class="logo-img-box">
          <Img :src="'/common/ic_logo.png'" class="logo-img" />
        </div>
        <div
          class="flex-a-c-center_icon_workbench flex-new"
          :class="
            topMenuActive === 'workbench'
              ? 'flex-a-c-center_workbench_select'
              : ''
          "
          @click="topMenuTab('workbench')"
        >
          <Icon icon-class="icon-gongzuotai" class="home-icon" />
          <p class="home-icon-title">工作台</p>
        </div>
        <div class="line_coum"></div>
        <div class="nav-list-div">
          <div
            class="flex-a-c-center_icon2"
            :class="
              topMenuActive === 'okr' ? 'flex-a-c-center_icon2_style' : ''
            "
            @click="topMenuTab('okr')"
          >
            <el-badge :hidden="!okrRedPoint" is-dot style="display: block">
              <p class="home-icon-title">OKR</p>
            </el-badge>
          </div>
          <div
            class="flex-a-c-center_icon2"
            :class="
              topMenuActive === 'todo' ? 'flex-a-c-center_icon2_style' : ''
            "
            @click="topMenuTab('todo')"
          >
            <el-badge :hidden="!todoRedPoint" is-dot style="display: block">
              <p class="home-icon-title">Todo</p>
            </el-badge>
          </div>
          <div
            class="flex-a-c-center_icon2"
            :class="topMenuActive === 'pm' ? 'flex-a-c-center_icon2_style' : ''"
            @click="topMenuTab('pm')"
          >
            <p class="home-icon-title">项目</p>
          </div>
          <div
            class="flex-a-c-center_icon2"
            :class="
              topMenuActive === 'analyse' ||
              topMenuActive === 'companyAnalyse' ||
              topMenuActive === 'departAnalyse'
                ? 'flex-a-c-center_icon2_style'
                : ''
            "
            @click="topMenuTab('analyse')"
          >
            <p class="home-icon-title">统计</p>
          </div>
          <div
            class="flex-a-c-center_icon2"
            :class="
              topMenuActive === 'conclusion'
                ? 'flex-a-c-center_icon2_style'
                : ''
            "
            @click="topMenuTab('conclusion')"
          >
            <el-badge
              :hidden="summaryCount === 0"
              :value="summaryCount"
              :max="99"
              style="display: block"
            >
              <p class="home-icon-title">总结</p>
            </el-badge>
          </div>
          <!--v-if="(companyInfo.version != 0 || (!$isQw && companyInfo.version != 0))"-->
          <div
            class="flex-a-c-center_icon2"
            :class="
              topMenuActive === 'kpi' ? 'flex-a-c-center_icon2_style' : ''
            "
            @click="topMenuTab('kpi')"
            v-if="(companyInfo.createDate && new Date(companyInfo.createDate.replace(/\-/g, '/')) < new Date('2023/08/18 00:00:01') && new Date() < new Date('2023/12/01 23:59:59')) || (companyInfo.createDate || '') === ''"
          >
            <p class="home-icon-title">绩效</p>
          </div>
          <!-- v-if="getKpiV2State()" -->
          <div
            class="flex-a-c-center_icon2 kpi-v2"
            :class="
              topMenuActive === 'kpiV2' ? 'flex-a-c-center_icon2_style' : ''
            "
            @click="topMenuTab('kpiV2')"
          >
            <p class="home-icon-title">绩效评估</p>
            <!-- <span>公测</span> -->
          </div>
          <!-- v-if="companyInfo.version != 0 || !$isQw" -->
          <div
            class="flex-a-c-center_icon2 flex-a-c-center_icon-meeting"
            :class="
              topMenuActive === 'meeting' ? 'flex-a-c-center_icon2_style' : ''
            "
            @click="topMenuTab('meeting')"
          >
            <p class="home-icon-title">咪厅</p>
            <!-- <span class="sign-span-bus">公测</span> -->
          </div>
          <!-- v-if="
              userInfo.companyId == '1165952161026109440' ||
              userInfo.companyId == '1166538611434151936' ||
              userInfo.companyId == '1343521676216602624'
          "-->
          <!-- 屏蔽 校管家 -->
          <div
            v-if="userInfo.companyId != '1339443199637032960'"
            class="flex-a-c-center_icon2 flex-a-c-center_icon-classroom"
            :class="
              topMenuActive === 'classroom' ? 'flex-a-c-center_icon2_style' : ''
            "
            @click="topMenuTab('classroom')"
          >
            <p class="home-icon-title">训练营</p>
            <Icon icon-class="icon-HOT" class="classroom-icon" />
          </div>
          <!-- 91百人活动 -->
          <!-- <div
            v-if="companyInfo.version && companyInfo.version == 0"
            class="activity-div"
            @click="openActivity"
          >
            <img
              class="activity-img"
              src="@/assets/imgs/advertisement/activity_3.png"
              alt
            />
          </div>-->
          <!-- <div
            v-if="companyInfo.version && ((companyInfo.version != 1 && companyInfo.version != 2) || expireDays <= 0) && doubleElevenDay >= 0 && companyInfo.version != 3"
            class="activity-div double-eleven"
            @click="openDoubleEleven(true)"
          >
            <img class="activity-img" src="@/assets/imgs/header/doubleElevenHeader.png" alt />
            <div class="text-wrap" v-if="doubleElevenDay === 0">
              <span>剩余最后一天</span>
            </div>
            <div class="text-wrap" v-else>
              距结束仅剩
              <span>{{doubleElevenDay}}&nbsp;</span>天
            </div>
          </div>-->
          <div
            class="banner-img"
            @click="openDoubleEleven(true)"
            v-if="companyInfo.version == 0 || companyInfo.version == 3"
          >
            <img src="@/assets/imgs/common/21Banner.png" alt />
          </div>
        </div>
      </div>

      <div class="right-box">
        <!-- 视频 -->
        <!-- <guide-popover
          ref="nextTargetPop"
          pop-key="headerVideoPopover"
          title="视频引导"
          :hide-click-emit="true"
          :animate-state="true"
          inner-text="点击查看关联KR的任务"
          placement="bottom"
        >
          <div class="msg-box rel msg-rg-10" slot="guidepop">
            <videoList />
          </div>
        </guide-popover>-->
        <!-- 通知 -->
        <el-tooltip content="查找" :open-delay="300" placement="bottom" effect="light">
          <div class="msg-box rel msg-rg-10" @click="headerSearchFunction">
            <Icon
              icon-class="icon-Search"
              :class="
                searchBoolean == true || headerSearchIcon == true
                  ? 'menu-select'
                  : ''
              "
            />
          </div>
        </el-tooltip>
        <!-- 日程 -->
        <el-tooltip
          content="日程"
          :open-delay="300"
          placement="bottom"
          effect="light"
          v-if="!this.$isQw && !this.$isDD"
        >
          <div class="contact-box msg-box rel" @click="calendarFunction">
            <Icon
              icon-class="icon-calendar"
              :class="
                calendarBoolean || $route.path === '/calendar'
                  ? 'menu-select'
                  : ''
              "
            />
          </div>
        </el-tooltip>
        <!-- 通讯录 -->
        <el-tooltip content="通讯录" :open-delay="300" placement="bottom" effect="light">
          <div class="contact-box msg-box rel" @click="contactsFunction">
            <Icon
              icon-class="icon-contacts_menu"
              :class="
                contactsBoolean || $route.path === '/members'
                  ? 'menu-select'
                  : ''
              "
            />
          </div>
        </el-tooltip>
        <div class="split-line"></div>
        <!-- v-if="$isQw" -->
        <el-tooltip content="审批" :open-delay="300" placement="bottom" effect="light">
          <div class="msg-box rel" @click="approveListShow">
            <div>
              <Icon
                icon-class="icon-shenpilishi-pc"
                :class="$route.path === '/approveList' ? 'menu-select' : ''"
              />
            </div>
            <div
              v-if="approvalRedCount > 0"
              class="right-box_radius_number"
              :class="
                approvalRedCount > 99 ? 'right-box_radius_number_max' : ''
              "
            >{{ approvalRedCount > 99 ? '99+' : approvalRedCount }}</div>
          </div>
        </el-tooltip>
        <div
          class="msg-box rel"
          :class="{ active: notifyUnreadCount }"
          @click="getMessageNotificationShow"
        >
          <el-tooltip content="通知" :open-delay="300" placement="bottom" effect="light">
            <div>
              <Icon
                icon-class="icon-xiaoxi"
                :class="
                  noticeBoolean == true || messageNotificationShow == true
                    ? 'menu-select'
                    : ''
                "
              />
            </div>
          </el-tooltip>
          <!-- 数字 -->
          <div
            v-if="informNumber > 0"
            class="right-box_radius_number"
            :class="informNumber > 99 ? 'right-box_radius_number_max' : ''"
          >{{ informNumber > 99 ? '99+' : informNumber }}</div>
        </div>
        <!-- 邀请 -->
        <el-tooltip
          content="邀请"
          :open-delay="300"
          placement="bottom"
          effect="light"
          v-if="!this.$isQw && !this.$isDD"
        >
          <div
            class="msg-box rel msg-rg-10 invite-wrap"
            :class="{ active: notifyUnreadCount }"
            @mouseenter="invitationBoolean = true"
            @mouseleave="invitationBoolean = false"
          >
            <Icon
              @click="invitationFunction"
              icon-class="icon-invitationselect"
              class="icon-invitationselect"
              :class="
                invitationBoolean || closeInviterCodeBoolean
                  ? 'intive-menu-select'
                  : ''
              "
            />
          </div>
        </el-tooltip>
        <!-- 到期升级 -->
        <div class="msg-box">
          <videoList />
        </div>
        <!-- 关联kr 有气泡-->
        <guide-popover
          title="升级"
          popKey="upgrade"
          :innerText="
            '企业试用还剩' + expireDays + '天到期，请及时处理以免到期后数据丢失'
          "
          placement="top"
          v-if="
            companyInfo.belonger == userInfo.id &&
            companyInfo.version == 0 &&
            companyInfo.expireDate &&
            expireDays > 0 &&
            isShowUpgrade &&
            commonFromType === 'saas'
          "
        >
          <span slot="guidepop">
            <el-tooltip
              content="到期升级"
              open-delay="300"
              placement="bottom"
              effect="light"
              v-delTabIndex
            >
              <div class="msg-box rel msg-rg-10">
                <span @click.stop="upgrade()" class="expiration-upgrade">升级</span>
              </div>
            </el-tooltip>
          </span>
        </guide-popover>
        <el-tooltip content="到期升级" open-delay="300" placement="bottom" effect="light" v-delTabIndex>
          <div class="msg-box rel msg-rg-10" v-if="companyInfo.version == 4">
            <span @click.stop="upgrade()" class="expiration-upgrade">升级</span>
          </div>
        </el-tooltip>
        <!-- 下载客户端 -->
        <el-popover
          placement="bottom-start"
          width="200"
          trigger="click"
          v-if="!isPcClientValue && !this.$isQw && !this.$isDD"
        >
          <div class="down-main">
            <img src="../../../../assets/imgs/header/down_app.png" class="down-icon" alt />
            <div class="download-row">体验更流畅</div>
            <div class="download-row">消息不遗漏</div>
            <div class="download-row">跨平台同步</div>
            <el-button
              @click="downloadMac()"
              class="download-btn"
              type="primary"
              v-if="validataOS() == 'Mac'"
            >下载Mac客户端</el-button>
            <el-button
              @click="downloadWindows()"
              class="download-btn"
              type="primary"
              v-if="validataOS() == 'Windows'"
            >下载Windows客户端</el-button>
          </div>
          <el-button slot="reference" class="down-btn" type="primary">
            <Icon icon-class="icon-kehuduanmorenzhuangtai" class="icon-kehuduanmorenzhuangtai" />
          </el-button>
        </el-popover>
        <!-- 头像 -->
        <div class="menu-wrap">
          <el-tooltip
            content="账号设置"
            :open-delay="300"
            placement="bottom"
            effect="light"
            popper-class="account-set-tooltip"
          >
            <person-menu-list v-if="this.$isQw || this.$isDD"></person-menu-list>
            <person-menu-list-saas v-else></person-menu-list-saas>
          </el-tooltip>
        </div>
      </div>
      <div v-if="targetDetailOpenState === '展开'">
        <!-- 新的 目标详情-->
        <okr-detail
          v-if="objectiveDetailVisible"
          v-model="objectiveDetailVisible"
          :obj-id="objId.id"
        />
      </div>
    </div>
    <notice-list
      v-if="messageNotificationShow"
      v-model="messageNotificationShow"
      @refreshNum="refreshNum"
    />
    <header-search
      v-if="headerSearchIcon"
      @closeHeaderSearch="closeHeaderSearch"
      @taskDetailVisible="headerTaskDetailVisible"
      @objectiveDetailVisible="headerObjectiveDetailVisible"
    />
    <!-- 二维码邀请 -->
    <invite-by-code @closeInviterCode="closeInviterCode" v-if="closeInviterCodeBoolean" />
    <!-- 任务详情 -->
    <task-detail
      v-if="taskdetailVisible && parseInt(notifyId) > 0"
      v-model="taskdetailVisible"
      :taskId="notifyId"
      :visible.sync="taskdetailVisible"
      :form-page="formPage"
      :view-type="formPage"
      :noticeType="noticeType"
    />
    <!-- 子任务详情 -->
    <sub-task-detail
      v-if="subdetailNoticeVisible"
      v-model="subdetailNoticeVisible"
      :taskId="subdetailVisibleId"
      :visible.sync="subdetailNoticeVisible"
      :sub-object-father-id="subObjectFatherId"
      :noticeType="noticeType"
    />
    <!-- 目标详情 -->
    <okr-detail
      v-if="homeDetailsState"
      v-model="homeDetailsState"
      :obj-id="detailsId"
      @refresh="getHomeDetailsClose"
    />
    <!-- 添加目标 -->
    <add-target
      v-if="targetAddShow"
      v-model="targetAddShow"
      :targetDetailsAlign="targetDetailsAlign"
      @gettargetAddClose="gettargetAddClose"
      @getTargetAddSubmit="getTargetAddSubmit"
    />
    <!-- 新功能上线 -->
    <activity v-if="activityVisible" v-model="activityVisible" :visible.sync="activityVisible" />
    <!-- 图片预览 -->
    <FilePreview
      v-if="isShowPreview"
      v-model="isShowPreview"
      :fileObj="fileObj"
      :isPreview="isPreview"
      :isDownload="isDownload"
    ></FilePreview>
    <InsertLink v-if="isShowInsertLink" v-model="isShowInsertLink"></InsertLink>
    <!-- 版本升级-->
    <customer-service v-if="customer_service_show" v-model="customer_service_show" />
    <!-- 源动力 计划 -->
    <!-- <applyFree v-if="applyFreeVisible" v-model="applyFreeVisible" /> -->
    <!-- 双十一-->
    <!-- <doubleEleven
      v-if="doubleElevenVisible"
      v-model="doubleElevenVisible"
      @close="openDoubleEleven(false)"
    />-->
    <!-- 元旦活动-->
    <festival v-if="festivalVisible" v-model="festivalVisible" @close="festivalState(false)" />
    <!-- 免费版本弹窗 -->
    <!-- <freeVersionDialog v-if="freeVersionVisible" v-model="freeVersionVisible" /> -->
    <!-- 训练营 -->

    <eleven
      v-if="doubleElevenVisible"
      v-model="doubleElevenVisible"
      v-show="!$store.state.common.apiRequestData.visible"
    />
    <aproveDetail
      v-if="approveDetailVisible"
      v-model="approveDetailVisible"
      :detailsId="aproveDetailId"
    />
    <!-- 任务审批提交弹窗 -->
    <taskApproveRemind
      v-if="$store.state.task.taskApproveRemindObj.visible"
      v-model="$store.state.task.taskApproveRemindObj.visible"
    />
  </div>
</template>
<script>
/* eslint-disable */
import { mapState } from 'vuex'
import personMenuList from './components/personMenuList.vue' // 头像菜单
import personMenuListSaas from './components/personMenuListSaas.vue' // Saas菜单

import headerSearch from '@/views/global/layout/header/components/headerSearch' // 头部搜索
import noticeList from '@/views/global/layout/header/components/noticeList' //通知列表
import activity from '@/views/global/layout/header/components/activity/index.vue' //落地活动 （十一活动）
import videoList from '@/views/global/layout/header/components/videoList' //落地活动
// import applyFree from '@/views/global/layout/header/components/applyFree/index'
import doubleEleven from '@/views/global/layout/header/components/activity/doubleEleven.vue' //落地活动 （十一活动）
import freeVersionDialog from '@/views/global/components/freeVersionDialog' //免费版本
import eleven from '@/views/global/layout/header/components/activity/eleven.vue' //落地活动 （十一活动）
import aproveDetail from '@/views/okr/dialog/approveDetail'
import taskApproveRemind from '@/views/global/layout/header/components/taskApproveRemind/index.vue' //落地活动 （十一活动）
// 钉钉用户授权
import { getENV, } from 'dingtalk-jsapi/lib/env';
import { compareVersion, } from 'dingtalk-jsapi/lib/sdk/sdkLib';
const { platform, version, appType, } = getENV();
import { openAuth } from 'dingtalk-design-libs/biz/openAuth'
import festival from '@/views/global/layout/header/components/activity/festival.vue' // 节日活动
export default {
  computed: {
    ...mapState('common', {
      apiRequestData: 'apiRequestData'
    }),
    isShowUpgrade() {
      if (
        this.apiRequestData &&
        this.apiRequestData.type !== 54003 &&
        this.apiRequestData.type !== 99
      ) {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    return {
      todoRedPoint: false,
      okrRedPoint: false,
      summaryCount: 0, // 总结未读数量
      searchObjectiveList: [], // 搜索结果对象列表
      searchTaskList: [], // 搜索结果任务列表
      searchContent: '', // 搜索内容
      taskId: '', // 任务ID
      objId: '', // 目标ID
      timer: '', // 定时器
      topMenuActive: '/', // 顶部菜单active
      objectiveDetailVisible: false, // 目标详情弹框显示
      addObjectiveVisible: false, // 添加目标弹框显示
      taskAddVisible: false, // 添加任务弹框显示
      taskDetailVisible: false, // 任务详情显示
      searchResultVisible: false, // 搜索结果显示
      notifyUnreadCount: '', // 未读通知数量
      switch_enterprises: false, // 切换企业
      feedBackState: false, // 意见反馈
      growDetailsState: false, // 显示成长列表
      messageNotificationShow: false, // 消息显示
      growMessageNumber: 0,
      informNumber: 0,
      jim: '', // 消息对象
      pathState: null,
      targetDetailOpenState: '',
      companyinfosName: '',
      noticeBoolean: false, // 消息通知
      helpBoolean: false, // 帮助
      headerSearchIcon: false,
      invitationBoolean: false, // 邀请
      contactsBoolean: false, // 通讯录
      calendarBoolean: false, // 日程
      searchBoolean: false, // 查找
      closeInviterCodeBoolean: false, // 邀请二维码是否显示
      targetAddShow: false, //添加目标显示 极光跳转新增
      targetDetailsAlign: {}, //目标详情对齐
      homeDetailsState: false,
      taskdetailVisible: false,
      formPage: 'pm',
      noticeType: 'notice',
      subdetailNoticeVisible: false,
      subdetailVisibleId: '',
      host: '',
      isPcClientValue: '',
      expireDays: '', // 剩余到期天数
      isShowUpgrade: true, // 升级气泡显隐
      activityVisible: false,
      isShowPreview: false, // 是否显示预览
      isPreview: true,
      fileObj: {
        fileName: '',
        fileUrl: '',
        fileType: '',
        fileTime: '',
        fileSize: '',
        createName: '',
        previewUrl: ''
      },
      isDownload: true, //是否可下载
      isShowInsertLink: false, // 是否显示插入链接
      createdState: false,
      customer_service_show: false,
      // applyFreeVisible: false, // 91活动申请表单
      doubleElevenVisible: false,
      doubleElevenDay: -1,
      // freeVersionVisible: true
      approveDetailVisible: false,
      aproveDetailId: '',
      approvalRedCount: 0,
      festivalVisible: false
    }
  },
  created() {
    // if ((this.$utils.getQueryString('corpId') || '') !== '') {
    //   this.$store.commit('user/setToken', '')
    // }
    if (this.$isQw) {
      this.wxRedict() // 微信消息处理
    }

    if (
      (this.$utils.getQueryString('corpId') || '') === this.commonCorpId &&
      this.$isDD
    ) {
      this.ddRedict() // 钉钉消息处理
    }
    this.$msg.init()
    const toDay = this.$day.getDate()
    const days = this.$day.diffDay(this.companyInfo.expireDate, toDay)
    this.expireDays = days
    // this.getGrowMessage()
    this.getCurrCycleInfoList()
    this.bus(this).$on('global:setNotifyMsgToReaded', () => {
      this.getCurrCycleInfoList()
    })
    // 获取任务字典并存储
    this.queryTaskDict()
    this.commonData()
    // 关闭所有弹框
    this.bus(this).$on('global:doClose', () => {
      if (this.$refs.accountSetting) {
        this.$refs.accountSetting.doClose()
      }
      if (this.$refs.accountHelp) {
        this.$refs.accountHelp.doClose()
      }
    })
    this.init()
    //获取域名
    this.host = `${window.location.protocol}${window.location.host}`
    // 在初始化极光时挂载客户端调整详情页的函数到window全局对象
    window.showMsgNotificationFromClient = this.showMsgNotificationFromClient
    // 日程接收拒绝挂到全局对象
    window.receiveSchedule = this.receiveSchedule
    window.rejectSchedule = this.rejectSchedule
    // 消息已读
    window.noticeRead = this.noticeRead
    this.isPcClientValue = isPcClient()

    // 监听红点-todo
    this.bus(this).$on('todoRedPoint', (item) => {
      this.todoRedPoint = item
    })
    // 监听红点-okr
    this.bus(this).$on('okrRedPoint', (item) => {
      this.okrRedPoint = item
    })
    // 监听总结红点
    this.bus(this).$on('summaryRedPoint', (item) => {
      this.summaryCount = item
    })
    // 监听CIM消息推送
    this.bus(this).$on('cimMsgReceive', (message) => {
      this.msgReceive(message)
    })
    // 项目文件预览
    this.bus(this).$on('global:PM:filePreview', (val) => {
      console.log('PM---', val)
      const item = {
        fileType: val.fileType,
        fileName: val.name,
        fileUrl: val.url,
        createDate: val.createDate,
        createName:
          val.createUser && val.createUser.name ? val.createUser.name : '',
        fileSize: val.fileSize
      }
      this.lookImg(item)
    })
    // 任务和okr动态 文件预览
    this.bus(this).$on('global:dynamic:filePreview', (val) => {
      console.log('dynamic---', val)
      const item = {
        fileType: val.mimeType,
        fileName: val.name,
        fileUrl: val.downloadUrl,
        fileSize: val.fileSize,
        createDate: val.createDate
      }
      this.lookImg(item)
    })
    // OKR 文件预览
    this.bus(this).$on('global:OKRFILE:filePreview', (val) => {
      console.log('OKRFILE---', val)
      const item = {
        fileType: val.fileType,
        fileName: val.fileName,
        fileUrl: val.fileUrl,
        fileSize: val.fileSize,
        createDate: val.createDate,
        createName: val.user && val.user.name ? val.user.name : '',
        status: val.status
      }
      this.lookImg(item, 'okrFile')
    })
    // 任务描述文件预览
    this.bus(this).$on('global:Editor:filePreview', (val, type, fileType) => {
      console.log('Editor---', val, type, fileType)
      if (type == 'img') {
        const item = {
          fileType: 'png',
          fileUrl: val,
          fileName: val
        }
        this.lookImg(item)
      } else if (type == 'file' && val) {
        val.split('?')
        console.log('file----', val, val.split('?'))
        let urlArr = val.split('?')
        var fileType = val.substring(val.lastIndexOf('.') + 1)
        const item = {
          fileType: fileType,
          fileUrl: urlArr.length > 0 ? urlArr[0] : '',
          fileName: urlArr.length > 0 ? urlArr[0] : ''
        }
        this.lookImg(item, 'editorFile')
      } else if (type == 'link' && val) {
        window.open(val, '_blank')
      }
    })
    // 任务添加任务详情文件预览
    this.bus(this).$on('global:TODO:filePreview', (val) => {
      console.log('TODO----', val)
      const item = {
        fileType: val.mimeType,
        fileUrl: val.downloadUrl,
        fileSize: val.fileSize,
        createDate: val.createDate,
        fileName: val.name
      }
      this.lookImg(item)
    })
    // 会议文件预览
    this.bus(this).$on('global:meeting:filePreview', (val) => {
      console.log('会议----', val)
      const item = {
        fileType: val.mimeType,
        fileUrl: val.downloadUrl,
        fileSize: val.fileSize,
        createDate: val.createDate,
        fileName: val.name
      }
      this.lookImg(item)
    })
    this.bus(this).$on('global:InsertLink', (val, type) => {
      this.isShowInsertLink = true
    })
    this.bus(this).$on('approvalRedCountRefresh', (item) => {
      this.getApprovalRedCount()
    })
    var localStateDate = localStorage.getItem('21LocalStateDate')
    var date = this.$utils.dateToYYYYMMDD()
    var festivalVisibleData = localStorage.getItem('festivalVisibleData') || 'false'
    if (festivalVisibleData === '') {
      this.festivalVisible = true
      localStorage.setItem('festivalVisibleData', true)
    } else if ((this.companyInfo.version == 0 || this.companyInfo.version == 3) && localStateDate !== date ) {
      this.doubleElevenVisible = true
      localStorage.setItem('21LocalStateDate', date)
    }
    // this.systemDateGet()
    // if (this.$isQw) {
    this.getApprovalRedCount()
    this.getApprovalCheck()
    // }
  },
  mounted() {
    var that = this
    this.companyinfosName = this.companyInfo.name || ''
    this.bus(this).$on('global:closeSwitchCompany', () => {
      const toDay = this.$day.getDate()
      const days = this.$day.diffDay(this.companyInfo.expireDate, toDay)
      this.expireDays = days
      this.getCurrCycleInfoList()
      this.queryTaskDict()
      this.commonData()
      localStorage.setItem('todoEditShortCutMenuList', JSON.stringify([]))
      localStorage.setItem('projectEditShortCutMenuList', JSON.stringify([]))
      localStorage.setItem('okrEditShortCutMenuList', JSON.stringify([]))
      localStorage.setItem('todoMenuIndexList', JSON.stringify({}))
      localStorage.setItem('projectMenuIndexList', JSON.stringify({}))
      localStorage.setItem('okrMenuIndexList', JSON.stringify({}))
      // if (this.$isQw) {
      this.getApprovalRedCount()
      this.getApprovalCheck()
      // }
    })
    // 打开父任务详情
    this.bus(this).$on('detailVisible', (id, noticeType) => {
      if (noticeType === 'notice') {
        this.taskdetailVisible = true
        this.notifyId = id
      }
    })
    // 监听由主任务详情到子任务详情
    this.bus(this).$on('global:todoSubTask', (item) => {
      if (item.noticeType === 'notice') {
        this.TaskTodoSubTask(item)
      }
    })
    // 监听由子任务详情到主任务详情
    this.bus(this).$on('global:taskMainDetail', (item) => {
      if (item.noticeType === 'notice') {
        if (this.subdetailNoticeVisible) {
          if (this.subObjectFatherId === -1 || item === -1) {
            this.subdetailNoticeVisible = false
            this.taskdetailVisible = false
            this.tabClick(this.status)
          } else {
            this.TaskTodomainTask(item)
          }
        }
      }
    })
    // 子任务关闭任务刷新
    this.bus(this).$on('global:subContenttaskRefresh', () => {
      this.subdetailNoticeVisible = false
    })
    // 监听系统分类变化
    this.bus(this).$on('classifyDetailChange', () => {
      this.queryTaskDict()
    })
    window['showMsgNotification'] = (objList) => {
      const obj = JSON.parse(objList)
      const title = that.$utils.tagFilter(obj.title)
      // const content = that.$utils.tagFilter(obj.content)
      that.showMsgClick(obj, obj.extras, 2)
    }
    window.addEventListener('popstate', function (e) {
      that.isShowPreview = false
    })
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.doubleElevenVisible === false && this.festivalVisible === false) {
          this.upgradeVersion() // 版本升级弹窗
        }
      }, 500)
    })
    if (this.$isDD) {
      var compareVersionData = compareVersion(version, '6.3.35')
      var openAuthObj = JSON.parse(localStorage.getItem('openAuthObj') || '{}')
      if (compareVersionData && this.userInfo.authStatus !== 1 && ((openAuthObj.userId === this.userInfo.id && openAuthObj.authStatus == 0)  || (openAuthObj.userId !== this.userInfo.id))) {
        this.ddOpenAuth()
      }
    }
    
  },

  computed: {},
  watch: {
    $route() {
      // 初始化
      this.init()
    }
  },
  components: {
    personMenuList,
    personMenuListSaas,
    headerSearch,
    noticeList,
    activity,
    videoList,
    // applyFree,
    doubleEleven,
    freeVersionDialog,
    eleven,
    aproveDetail,
    taskApproveRemind,
    festival
  },
  methods: {
    getKpiV2State() {
      var state = false  
      var kpiV2CompanyId = ['1165952161026109440', '1410445786643988480', '1410437831567638528']
      if (kpiV2CompanyId.includes(this.companyInfo.id) || !(window.location.href.includes('app.okrt.com') || window.location.href.includes('dingtalkcloud'))){
        state = true
      }
      return state
    },
    ddOpenAuth() {
      var clientId = 'suitevptcggdozxenmvzq'
      if (window.location.host.includes('test-dd')){
        clientId = 'suitefgahln16o68do9id'
      } else if (window.location.host.includes('dingtalkcloud')){
        clientId = 'suitetilaoit5h7hngyme'
      }
      openAuth({
        clientId: clientId, // 应用ID(唯一标识)
        corpId: this.commonCorpId, // 当前组织的corpId
        rpcScope: 'Contact.User.Read',
        fieldScope: 'Contact.User.mobile',
        type: 0 // 0 标识授权个人信息；1 标识授权组织信息
      }).then((res) =>{
        console.log('openAuth', res)
        var data = {
          corpId: this.commonCorpId,
          userId: this.userInfo.id,
          authStatus: res.status === 'ok' ? 1 : 0
        }
        localStorage.setItem('openAuthObj', JSON.stringify(data))
        if (res.status === 'ok') {
          this.ddAuthStatus(res.result.authCode)
        }
      })
    },
    ddAuthStatus(authCode){
      let params = {
        authCode
      }
      this.$apis
        .DDAUTHSTATUS(params)
        .then((rest) => {
        })
    },
    wxRedict() {
      const busicId = this.$utils.getQueryStringHash('busicId') || ''
      const wxRedictData = this.$store.state.user.wxRedictData
      console.log(
        'wxRedictData',
        wxRedictData,
        'location',
        window.location.href
      )
      if (busicId !== '' || wxRedictData !== '') {
        var companyId = this.$utils.getQueryStringHash('companyId') || ''
        if (busicId !== '') {
          // 地址有传参 地址优先取参
          var locationHash = location.hash.indexOf('?')
          var queryUrl = location.hash.substring(locationHash + 1)
          this.$store.commit('user/setWxRedictData', queryUrl)
          if (
            companyId &&
            this.companyInfo &&
            this.companyInfo.id &&
            companyId != this.companyInfo.id
          ) {
            // alert('重定向了')
            this.$utils.redirectUrl()
          }
        }
        const isQyweixin = this.$utils.isQyweixin(1)
        if (isQyweixin === 'app') {
          // 跳转微信app
          this.$store.commit('user/setWxRedictData', '')
          window.location.href = `https://${
            window.location.host
          }/mobile/#/?token=${this.token}&${
            busicId !== '' ? queryUrl : wxRedictData
          }`
        } else {
          if (
            wxRedictData !== '' ||
            (busicId !== '' &&
              companyId &&
              this.companyInfo &&
              this.companyInfo.id &&
              companyId == this.companyInfo.id)
          ) {
            if (busicId !== '') {
              this.showMsgClick(
                {
                  objId: this.$utils.getQueryStringHash('busicId')
                },
                {
                  busicType: parseInt(
                    this.$utils.getQueryStringHash('busicType')
                  ),
                  targetType: parseInt(
                    this.$utils.getQueryStringHash('targetType')
                  ),
                  busicId: this.$utils.getQueryStringHash('busicId'),
                  objId: this.$utils.getQueryStringHash('busicId')
                }
              )
            } else {
              this.showMsgClick(
                {
                  objId: this.$utils.getQueryStringHash(
                    'busicId',
                    1,
                    wxRedictData
                  )
                },
                {
                  busicType: parseInt(
                    this.$utils.getQueryStringHash('busicType', 1, wxRedictData)
                  ),
                  targetType: parseInt(
                    this.$utils.getQueryStringHash(
                      'targetType',
                      1,
                      wxRedictData
                    )
                  ),
                  busicId: this.$utils.getQueryStringHash(
                    'busicId',
                    1,
                    wxRedictData
                  ),
                  objId: this.$utils.getQueryStringHash(
                    'busicId',
                    1,
                    wxRedictData
                  )
                }
              )
            }
            this.$store.commit('user/setWxRedictData', '')
          }
        }
      } else {
        this.$store.commit('user/setWxRedictData', '')
      }
    },
    getQueryString(name, type, query) {
      var locationHash = ''
      if (type === 1) {
        locationHash = query
      } else {
        var w = location.hash.indexOf('?')
        locationHash = location.hash.substring(w + 1)
      }
      const vars = locationHash.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] == name) {
          return pair[1]
        }
      }
      return null
    },
    ddRedict() {
      const busicId = this.$utils.getQueryString('busicId') || ''
      const ddRedictData = this.$store.state.user.ddRedictData
      if (this.$isDD) {
        if (busicId !== '' || ddRedictData !== '') {
          // 地址有传参 地址优先取参
          var locationHash = window.location.href.indexOf('?')
          var queryUrl = window.location.href.substring(locationHash + 1)
          if (this.$utils.getChromeType() === 'app') {
            // 跳转钉钉app
            this.$store.commit('user/setDdRedictData', '')
            window.location.href = `https://${
              window.location.host
            }/mobile/#/?fromType=dingding&corpId=${this.$utils.getQueryString(
              'corpId'
            )}&${busicId !== '' ? queryUrl : ddRedictData}`
          } else {
            this.showMsgClick(
              {
                objId: this.getQueryString(
                  'busicId',
                  1,
                  queryUrl || ddRedictData
                )
              },
              {
                busicType: parseInt(
                  this.getQueryString('busicType', 1, queryUrl || ddRedictData)
                ),
                targetType: parseInt(
                  this.getQueryString('targetType', 1, queryUrl || ddRedictData)
                ),
                busicId: this.getQueryString(
                  'busicId',
                  1,
                  queryUrl || ddRedictData
                ),
                objId: this.getQueryString(
                  'busicId',
                  1,
                  queryUrl || ddRedictData
                )
              }
            )
            // }
            this.$store.commit('user/setDdRedictData', '')
            this.$nextTick(() => {
              this.createdState = true
            })
          }
        } else {
          this.$store.commit('user/setDdRedictData', '')
          this.$nextTick(() => {
            this.createdState = true
          })
        }
      } else {
        this.$nextTick(() => {
          this.createdState = true
        })
      }
    },
    // openActivity() {
    //   this.applyFreeVisible = true
    // },
    lookImg(item, type) {
      if (type && type == 'okrFile') {
        if (item.status === 0) {
          this.isDownload = true
        } else {
          this.isDownload = false
        }
      }

      if (
        item.fileType === 'png' ||
        item.fileType === 'bmp' ||
        item.fileType === 'jpg' ||
        item.fileType === 'jpeg' ||
        item.fileType === 'gif' ||
        item.fileType === 'svg' ||
        item.fileType === 'PNG' ||
        item.fileType === 'JPG' ||
        item.fileType === 'JPEG' ||
        item.fileType === 'GIT' ||
        item.fileType === 'SVG' ||
        item.fileType === 'mp3' ||
        item.fileType === 'mp4'
      ) {
        this.fileObj.fileName = item.fileName
        this.fileObj.fileUrl = item.fileUrl
        this.fileObj.fileType = item.fileType
        this.fileObj.fileTime = item.createDate
        this.fileObj.fileSize = item.fileSize
        this.fileObj.createName = item.createName
        this.isShowPreview = true
        this.isPreview = true
      } else {
        this.$apis.FILEPREVIEW({ fileName: item.fileUrl }).then((res) => {
          console.log('预览', res.data)
          if (res.status === 200) {
            this.fileObj.fileName = item.fileName
            this.fileObj.fileUrl = item.fileUrl
            this.fileObj.previewUrl = res.data
            this.fileObj.fileType = item.fileType
            this.fileObj.fileTime = item.createDate
            this.fileObj.fileSize = item.fileSize
            this.fileObj.createName = item.createName
            this.isPreview = true
            this.isShowPreview = true
          } else {
            // 暂不支持，逐渐开放
            this.$message({
              type: 'warn',
              msg: '该文件类型，暂不支持预览！'
            })
            this.fileObj.fileName = item.fileName
            this.fileObj.fileUrl = item.fileUrl
            this.fileObj.fileType = item.fileType
            this.fileObj.fileTime = item.createDate
            this.fileObj.fileSize = item.fileSize
            this.fileObj.createName = item.createName
            this.isPreview = false
            this.isShowPreview = true
          }
        })
      }
    },
    // 查询任务字典
    queryTaskDict() {
      this.$apis.TASKPARAMDICT().then((res) => {
        if (res.status === 200) {
          this.$store.commit('task/taskTodoOrKrDict', res.data)
        }
      })
    },
    // 公共数据
    commonData() {
      this.$apis.COMMONDATA().then((res) => {
        if (res.status === 200) {
          this.$store.commit('common/saveCompanyCalendar', res.data.calendar)
        }
      })
    },
    openDoubleEleven(state) {
      this.doubleElevenVisible = state
    },
    festivalState(state) {
      this.festivalVisible = state
    },
    // 活动判断
    systemDateGet() {
      this.$apis
        .SYSTEMDATE()
        .then((rest) => {
          const version = this.userInfo.version // 0 免费 1:企业 2:旗舰
          const activityLocalStorage =
            JSON.parse(localStorage.getItem('doubleElevenLocalStorage2')) || ''
          const endDate = '2023/05/04 00:00:01' //结束时间
          const beforeDate = '2023/04/28 23:59:59' //开始时间
          const currentDate = rest.data.dateString
          const endDateTime = new Date(endDate)
          const currentDateTime = new Date(currentDate.replace(/\-/g, '/'))
          // const currentDateTime = new Date()
          const beforeDateTime = new Date(beforeDate)
          var day = this.$day.diffDay(endDateTime, currentDateTime)
          if (endDateTime >= currentDateTime) {
            this.doubleElevenDay = day
          } else {
            this.doubleElevenDay = -1
          }
          if (
            currentDateTime.getTime() >= beforeDateTime.getTime() &&
            endDateTime.getTime() >= currentDateTime.getTime() &&
            activityLocalStorage === '' &&
            this.$store.state.common.apiRequestData.visible !== true
          ) {
            localStorage.setItem('doubleElevenLocalStorage2', true)
            this.doubleElevenVisible = true
          } else {
            this.doubleElevenVisible = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    upgradeVersion() {
      const currentVersion = '3.8.3'
      const upgradeVersionLocalStorage =
        localStorage.getItem('upgradeVersion') || ''
      if (
        upgradeVersionLocalStorage === '' ||
        (upgradeVersionLocalStorage &&
          JSON.parse(upgradeVersionLocalStorage).version != currentVersion &&
          this.commonFromType === 'saas')
      ) {
        localStorage.setItem(
          'upgradeVersion',
          JSON.stringify({
            state: true,
            version: currentVersion
          })
        )
        this.activityVisible = true
        // if (this.companyInfo.version && this.companyInfo.version == 0) {
        //   this.activityVisible = false
        // } else {
        //   this.activityVisible = true
        // }
      } else {
        this.activityVisible = false
      }
      if (this.activityVisible === false) {
        if ((this.companyInfo.version == 0 || this.companyInfo.version == 3) && localStorage.getItem('21LocalState') !== 'true') {
          this.doubleElevenVisible = true
          localStorage.setItem('21LocalState', true)
        }
      }
    },
    //到期升级
    upgrade() {
      // console.log(11, this.$day)
      // const toDay = this.$day.getDate()
      // const days = this.$day.diffDay(this.companyInfo.expireDate, toDay)
      // console.log(22, toDay, days)
      // this.$store.commit('common/apiRequestData', {
      //   visible: true,
      //   type: 99,
      //   days: days
      // })
      // this.expireDays = days
      // console.log(2323, this.$store.state.common.apiRequestData)
      if (this.commonFromType === 'dingding') {
        window.open(
          `https://page.dingtalk.com/wow/dingtalk/act/serviceconversation?ddtab=true&wh_biz=tm&showmenu=false&goodsCode=DT_GOODS_881655784491241&corpId=${this.commonCorpId}&token=199f876c080bd26313560b8515f10454`,
          '_blank'
        )
      } else {
        this.customer_service_show = true
      }
    },
    // 头部搜索
    closeHeaderSearch() {
      this.headerSearchIcon = false
    },
    headerTaskDetailVisible(obj) {
      this.headerSearchIcon = false
      this.getTaskDetail(obj.taskId)
    },
    headerObjectiveDetailVisible(obj) {
      this.searchResultVisible = obj.searchResultVisible
      this.objId = obj.item
      this.objectiveDetailVisible = obj.objectiveDetailVisible
      this.targetDetailOpenState = obj.targetDetailOpenState
      this.headerSearchIcon = false
    },
    //下载Windows客户端
    downloadWindows() {
      window.open(
        'https://app.okrt.com/ymb-client/win/Okrt-latest.zip',
        '_self'
      )
    },
    //下载Mac客户端
    downloadMac() {
      window.open(
        'https://app.okrt.com/ymb-client/mac/Okrt-latest.dmg',
        '_self'
      )
    },
    validataOS() {
      if (navigator.userAgent.indexOf('Window') > 0) {
        return 'Windows'
      } else if (navigator.userAgent.indexOf('Mac OS X') > 0) {
        return 'Mac'
      } else if (navigator.userAgent.indexOf('Linux') > 0) {
        return 'Linux'
      } else {
        return 'NUll'
      }
    },
    // 关闭邀请二维验证码
    closeInviterCode() {
      this.closeInviterCodeBoolean = false
    },
    invitationFunction() {
      this.$mixpanelUtil.track('View Invite members')
      this.closeInviterCodeBoolean = !this.closeInviterCodeBoolean
      this.invitationBoolean = !this.invitationBoolean
    },
    // 获取成长过程接口
    getGrowMessage() {
      let beans = {}
      this.$apis
        .HEADERUSERPROGRESSLIST(beans)
        .then((rest) => {
          this.growDetailsList = rest.data
          let growMessageNumbers = 0
          for (let i = 0; i < rest.data.length; i++) {
            growMessageNumbers += rest.data[i].progressRate
          }
          this.growMessageNumber = growMessageNumbers
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 消息实时监听
    msgReceive(message) {
      const obj = message
      const extraStr = message.extra.replace(/\"/g, '"')
      const objStr = extraStr.split('{')[2].split('}')[0]
      let notifyTargetInfo = `{${objStr}}`
      const extras = JSON.parse(notifyTargetInfo)
      obj.title = this.$utils.tagFilter(this.$utils.changeToHtml(message.title))
      obj.content = this.$utils.tagFilter(
        this.$utils.changeToHtml(message.content)
      )
      obj.extras = JSON.parse(JSON.stringify(extras))
      if (
        message.content &&
        message.receiver &&
        this.userInfo.id === message.receiver
      ) {
        this.getCurrCycleInfoList()
        this.showMsgNotification(obj.title, obj.content, obj, obj.extras)
      }
    },
    showMsgNotification(title, msg, obj, extras) {
      var that = this
      var Notification =
        window.Notification ||
        window.mozNotification ||
        window.webkitNotification
      //优先判断是否客户端
      if (isPcClient()) {
        // 是否启用了通知
        if (isEnablePCSysNotice) {
          console.log('向客户端发送消息')
          that.showMsgClick(obj, extras, 1)
        }
        return true
      }
      if (Notification) {
        // 支持桌面通知
        if (Notification.permission == 'granted') {
          var instance = new Notification(title, {
            body: msg,
            icon: 'https://www.okrt.com/favicon.ico'
          })

          instance.onclick = function () {
            that.noticeRead(extras.notifyId)
            that.showMsgClick(obj, extras, 2)
            instance.close()
          }
        } else {
          // 由于客户端的内核默认是禁止通知的，故在此处判断客户端自身是否启用了通知
          // if (isEnablePCSysNotice) {
          //   console.log('向客户端发送消息')
          //   that.showMsgClick(obj, extras, 1)
          //   return true
          // }
          // 第一次询问或已经禁止通知(如果用户之前已经禁止显示通知，那么浏览器不会再次询问用户的意见，
          Notification.requestPermission(function (status) {
            if (status === 'granted') {
              // 用户允许
              var instance = new Notification(title, {
                body: msg,
                icon: 'https://www.okrt.com/favicon.ico'
              })

              instance.onclick = function () {
                that.showMsgClick(obj, extras, 2)
                instance.close()
              }
            } else {
              // 用户禁止
              console.log('用户禁止消息')
              return false
            }
          })
        }
      } else {
        // 不支持(IE等)
        var index = 0
        clearInterval(timer)
        timer = setInterval(function () {
          if (index % 2) {
            this.$notify.info({
              title: '消息通知',
              message: title
            })
          } else {
            this.$notify.info({
              title: '消息通知',
              message: title
            })
          }
          index++

          if (index > 20) {
            clearInterval(timer)
          }
        }, 500)
      }
    },
    // 接收从客户端转过来的消息通知跳转到指定详情页
    showMsgNotificationFromClient(objList) {
      var that = this
      const obj = JSON.parse(objList)
      that.showMsgClick(obj, obj.extras, 2)
    },
    noticeRead(id) {
      // 变为已读
      this.$apis
        .HEADERSETNOTIFYMSGTOREADED({ id: id })
        .then((rest) => {
          this.getCurrCycleInfoList()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    refreshNum() {
      this.getCurrCycleInfoList()
    },
    // 获取全部的目标通知
    getCurrCycleInfoList() {
      this.$apis
        .HEADERGETNOREADCOUNT({})
        .then((rest) => {
          this.$nextTick(() => {
            this.informNumber = rest.data
            try {
              if (typeof eval(setClientNoReadMsgCount) == 'function') {
                setClientNoReadMsgCount(rest.data)
              }
            } catch (e) {
              console.log(`setClientNoReadMsgCount未加载`)
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取审批红点数量
    getApprovalRedCount() {
      this.$apis
        .APPROVALREDCOUNT({})
        .then((rest) => {
          this.$nextTick(() => {
            if (rest) {
              this.approvalRedCount =
                Number(rest.data.approvalUserCount) +
                Number(rest.data.launchUserCount)
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取审批设置状态
    getApprovalCheck() {
      var params = {
        businessType: 1,
        businessId: '',
        businessExtId: ''
      }
      this.$apis
        .APPROVALCHECK(params)
        .then((res) => {
          if (res.data) {
            this.$store.commit('user/setApprovalCheckData', res.data)
          }
        })
        .catch(() => {})
    },
    // 显示消息弹框
    getMessageNotificationShow() {
      this.messageNotificationShow = true
      this.getCurrCycleInfoList()
    },
    approveListShow() {
      this.$router.push({ path: '/approveList' })
    },
    // 目标详情点击关闭调整
    getTargetDetailsClose() {
      this.objectiveDetailVisible = false
      setTimeout(() => {
        this.targetDetailOpenState = ''
      }, 500)
    },
    // 点击显示成长列表
    getGrowDetailsClick() {
      this.growDetailsState = true
      if (this.$refs.accountSetting) {
        this.$refs.accountSetting.doClose()
      }
      if (this.$refs.accountHelp) {
        this.$refs.accountHelp.doClose()
      }
    },
    // 关闭成长列表
    getGrowDetailsClose() {
      this.growDetailsState = false
    },
    // 初始化
    init() {
      const toDay = this.$day.getDate()
      const days = this.$day.diffDay(this.companyInfo.expireDate, toDay)
      this.expireDays = days
      this.upgradeText =
        '企业试用还剩' +
        this.expireDays +
        '天到期，请及时处理以免到期后数据丢失'
      const path = this.$route.path
      if (path === '/' || path.includes('objective')) {
        this.topMenuActive = '/'
      } else {
        this.topMenuActive = path.split('/')[1]
      }
    },
    // 查询目标或任务
    searchObjectiveOrTask() {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        // 搜索框无内容
        if (!this.searchContent) {
          this.searchObjectiveList = []
          this.searchTaskList = []
          return
        }
        if (this.searchContent.replace(/\s*/g, '').length > 200) {
          const searchContent_ = this.searchContent
          this.$message({
            msg: '目标或任务最多可以输入 200 个字符哦!',
            type: 'warn'
          })
          this.searchContent = searchContent_.substring(0, 200)
        } else {
          this.getSearchList()
        }
      }, 300)
    },
    // 获取搜索结果列表
    getSearchList() {
      this.$apis
        .HEADEROBJLISTSEARCH({
          contentSearch: this.searchContent, // 目标的标题
          limit: 100,
          pageNum: 1
        })
        .then((rest) => {
          this.searchObjectiveList = rest.data.rows
        })
        .catch((err) => {
          console.log(err)
        })
      this.$apis
        .HEADERTASKLISTSEARCH({
          title: this.searchContent, // 目标的标题
          limit: 100,
          page: 1
        })
        .then((rest) => {
          this.searchTaskList = rest.data.rows
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 查看未读通知数量
    async getNotifyUnreadCount() {
      this.notifyUnreadCount = await this.$getData('notify/noReadCount')
    },
    // 切换企业
    switchEmpty() {
      this.switch_enterprises = true
      if (this.$refs.accountSetting) {
        this.$refs.accountSetting.doClose()
      }
    },
    closeSwitchCompany() {
      this.switch_enterprises = false
    },
    // 意见反馈
    feedBack() {
      this.feedBackState = true
      if (this.$refs.accountSetting) {
        this.$refs.accountSetting.doClose()
      }
    },
    // 关闭反馈
    closeFreeBack() {
      this.feedBackState = false
    },
    // 顶部菜单切换
    topMenuTab(val) {
      let path
      if (val === 'workbench') {
        path = `/workbench${
          this.commonCorpId !== '' ? `?type=1&corpId=${this.commonCorpId}` : ''
        }`
      } else if (val === 'notify') {
        path = `/${val}`
      } else if (val === 'setting') {
        path = `/${val}/information`
      } else if (val === 'admin') {
        path = `/${val}/control`
      }
      // else if (val === 'okr') {
      //   window.open('http://doc.okrt.com/1957.html', '_blank')
      //   return
      // } else
      else if (val === 'setOkr') {
        window.open(
          'https://www.yuque.com/docs/share/ef8207b8-23e5-4f48-aeea-694205633518?#',
          '_blank'
        )
        return
      } else if (val === 'help') {
        window.open(
          'https://www.yuque.com/books/share/b35efa67-f8c2-4142-a769-b4ecd11217ee?#',
          '_blank'
        )
        return
      } else if (val === 'helpCenter') {
        window.open('https://www.okrt.com/wiki/', '_blank')
        return
      } else if (val === 'todo') {
        path = `/${val}`
      } else if (val === 'pm' || val === 'meeting') {
        path = `/${val}`
      } else if (val === 'conclusion') {
        path = `/${val}`
      } else if (val === 'analyse') {
        path = `/${val}`
      } else if (val === 'kpi' || val === 'kpiV2') {
        path = `/${val}`
      } else if (val === 'okr') {
        path = '/okr'
        this.$emit('global:getCloseHomeDetails')
      } else if (val == 'classroom') {
        path = `/${val}`
      } else {
        path = `/${val}/404`
      }
      this.objectiveDetailVisible = false
      setTimeout(() => {
        this.targetDetailOpenState = ''
      }, 500)
      this.topMenuActive = val
      this.$router.push(path)
    },
    // 顶部菜单样式
    topMenuClass(val) {
      if (this.topMenuActive === val) {
        return 'active'
      }
    },
    // 添加目标或任务
    addCommand(command) {
      if (command === 'objective') {
        this.addObjectiveVisible = true
      } else {
        this.taskAddVisible = true
      }
    },
    // 搜索框失去焦点
    searchBlur() {
      setTimeout(() => {
        this.searchResultVisible = false
      }, 200)
    },
    // 展示目标详情
    showObjectiveDetail(item) {
      this.targetDetailOpenState = '展开'
      this.searchResultVisible = true
      this.objId = item
      this.objectiveDetailVisible = true
    },
    // 展示任务详情
    showTaskDetail(item) {
      this.searchResultVisible = true
      this.taskId = item.id
      this.taskDetailVisible = true
    },
    // 退出登录  跳转到登录页面
    exitBtn() {
      // 数据埋点c
      this.$mixpanelUtil.reset_()
      this.$mixpanelUtil.init()
      this.$store.commit('user/setToken', '')
      this.$store.commit('user/setUserInfo', '')
      session.removeItem('periodId')
      this.$router.push({ path: '/login' })
      this.$store.commit('common/apiRequestData', {
        visible: false,
        type: ''
      })
    },

    headerSearchFunction() {
      this.headerSearchIcon = true
    },
    afterEnter() {
      this.helpBoolean = true
    },
    afterLeave() {
      this.helpBoolean = false
    },
    // 跳转通讯录
    contactsFunction() {
      this.$router.push({ path: '/members' })
    },
    // 跳转日程
    calendarFunction() {
      this.$router.push({ path: '/calendar' })
    },
    //极光跳转新增
    showMsgClick(obj, item, type) {
      // type 1 发送客户端通知 2:跳转
      if (item.busicType === 0) {
        // 目标 okr
        if (type === 1) {
          obj.url = ''
          sendMessage(JSON.stringify(obj))
        } else {
          if (item.targetType === 1 || item.targetType === 23) {
            //对齐
            this.getOkrDetails(item)
          } else if (item.targetType === 22) {
            //对齐
            this.getKeyOkrDetails(item)
          } else {
            this.detailsId = item.objId
            this.homeDetailsState = true
          }
        }
      }
      if (item.busicType === 1) {
        // 周期
        if (item.targetType === 2) {
          var path = '/okr'
          if (type === 1) {
            obj.url = ''
            sendMessage(JSON.stringify(obj))
          } else {
            this.$router.push(path)
            this.$emit('global:getCloseHomeDetails')
          }
        }
      }
      if (item.busicType === 2) {
        // 任务
        if (type === 1) {
          obj.url = ''
          sendMessage(JSON.stringify(obj))
        } else {
          this.getTaskDetail(item.busicId)
        }
      }
      if (item.busicType === 3) {
        // 小结详情
        if (type === 1) {
          obj.url = `${this.host}/conclusion`
          sendMessage(JSON.stringify(obj))
        } else {
          this.$router.push('/conclusion')
        }
      }
      if (item.busicType === 4) {
        // 项目
        if (type === 1) {
          obj.url = ``
          sendMessage(JSON.stringify(obj))
        } else {
          this.getProjectDescData(item.busicId)
        }
      }
      if (item.busicType === 5) {
        // 绩效
        var path = ''
        if (item.targetType === 8) {
          // 发起考核人员 我的考核
          path = '/kpi'
        } else if (item.targetType === 9) {
          //邀请处理
          path = '/kpi/myHandle'
        } else if (item.targetType === 11) {
          // 发起考核人员 绩效公示
          path = '/kpi/publicCheck'
        } else {
          // 绩效详情
          path = '/kpi'
        }
        if (type === 1) {
          obj.url = `${this.host}${path}`
          sendMessage(JSON.stringify(obj))
        } else {
          this.$router.push(path)
        }
      }
      if (item.busicType === 6) {
        // 日程
        if (type === 1) {
          obj.url = ``
          sendMessage(JSON.stringify(obj))
        } else {
          this.$router.push({
            path: '/calendar',
            query: { id: item.busicId }
          })
        }
      }
      if (item.busicType === 7) {
        // 会议
        if (type === 1) {
          obj.url = ``
          sendMessage(JSON.stringify(obj))
        } else {
          if (item.targetType == 14) {
            this.$router.push({
              path: '/meeting/roundTableMeeting',
              query: { infoId: item.busicId }
            })
          } else if (item.targetType == 15) {
            this.$router.push({
              path: '/meeting/commonViewMeeting',
              query: { infoId: item.busicId }
            })
          } else if (item.targetType == 16) {
            this.$router.push({
              path: '/meeting/progressMeeting',
              query: { infoId: item.busicId }
            })
          } else if (item.targetType == 17) {
            this.$router.push({
              path: '/meeting/resumptionMeeting',
              query: { infoId: item.busicId }
            })
          } else if (item.targetType == 18) {
            this.$router.push('/meeting')
          } else {
            this.$router.push('/meeting')
          }
        }
      }
      if (item.busicType === 9 || item.busicType === 8) {
        // 训练营
        // if (item.targetType == 202) {
        //   var query = {
        //     courseId: ''
        //   }
        //   this.$router.push({ path: '/classroom/homework', query: query })
        // } else {
        //   this.$router.push('/classroom')
        // }
        if (type === 1) {
          obj.url = '/classroom'
          sendMessage(JSON.stringify(obj))
        } else {
          this.$router.push({ path: '/classroom' })
        }
      }
      if (item.targetType == 20) {
        if (type === 1) {
          obj.url = '/workbench'
          sendMessage(JSON.stringify(obj))
        } else {
          this.$router.push({ path: '/workbench' })
        }
      }
      if (item.busicType === 11) {
        if (item.targetType == 28) {
          this.getTaskDetail(item.busicId)
        } else {
          this.aproveDetailId = item.busicId
          this.approveDetailVisible = true
        }
      }
      if (item.busicType === 12) {
        if (item.targetType === 29 || item.targetType === 30) {
          var loadingParam = {
            performanceId: item.targetType === 29 ? item.busicId : '',
            performanceTodoId: item.targetType === 30 ? item.busicId : '',
            kpiType: item.targetType === 30 ? 0 : '-1'
          }
          this.$router.push({
            path: `/kpiV2/myWait/kpiDetail`,
            query: loadingParam
          })
        }
      }
    },
    //邀请对齐目标详情
    getOkrDetails(item) {
      let dataBeans = {
        businessId: item.busicId,
        type: item.targetType === 23 ? 1 : 0
      }
      this.$apis
        .HEADEROBJALIGNINFO(dataBeans)
        .then((rest) => {
          if (rest.status == 200) {
            let dateTime = new Date(rest.data.createDate)
            let time =
              dateTime.getMonth() +
              1 +
              '月' +
              dateTime.getDate() +
              '日 ' +
              (dateTime.getHours() < 10
                ? '0' + dateTime.getHours()
                : dateTime.getHours()) +
              ':' +
              (dateTime.getMinutes() < 10
                ? '0' + dateTime.getMinutes()
                : dateTime.getMinutes())
            rest.data.time = time
            this.targetDetailsIdz = item.busicId
            rest.data.content = item.content
            rest.data.objContent = this.$utils.changeToHtml(
              rest.data.objContent
            )
            this.InvitationBeans = rest.data
            this.targetDetailsAlign = this.InvitationBeans
            if (rest.data.isAlign === 1) {
              this.$message.warn('您已经添加对齐了哦！')
            } else {
              this.targetAddShow = true
            }
          } else {
            this.$message.warn(rest.message)
          }
        })
        .catch((err) => {
          this.$message.error(rest.message)
        })
    },
    //关键结果获取目标详情
    getKeyOkrDetails(item) {
      let dataBeans = {
        keyId: item.busicId
      }
      this.$apis
        .KEYOKRALIGNOBJDETAIL(item.busicId, dataBeans)
        .then((rest) => {
          if (rest.status == 200) {
            this.detailsId = rest.data.id
            this.homeDetailsState = true
          } else {
            this.$message.warn(rest.message)
          }
        })
        .catch((err) => {
          this.$message.error(rest.message)
        })
    },
    // 获取任务详情
    getTaskDetail(id) {
      this.$apis.TASKDETAILNEW({}, id).then((res) => {
        if (res.status === 200) {
          if (parseInt(res.data.fatherTid) > 0) {
            //子任务
            this.TaskTodoSubTask(
              {
                id,
                fatherTid: -1
              },
              1
            )
          } else {
            //父任务
            this.taskdetailVisible = true
            this.notifyId = id
          }
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 获取项目概述
    getProjectDescData(id) {
      let url_ = `/api/project/${this.versionNumber}/project/${id}`
      let obj_ = {
        id
      }
      this.$apis.HEADERPROJECTDESC(id, obj_).then((res) => {
        if (res.status === 200) {
          this.$store.commit('pm/tabSelectId', 1)
          this.$store.commit('pm/selectProjectId', id)
          var selectlist = {
            id,
            indexf: 0,
            indexS: 0,
            list: res.data
          }
          this.$store.commit('pm/selectProjectData', selectlist)
          this.$router.push({
            path: '/pm/pmDetail',
            query: {
              id
            }
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 目标关闭回调
    gettargetAddClose() {
      this.targetDetailsAlign = {}
      this.targetAddShow = false
      this.$emit('global:refreshHeaderList')
      // 如果在OKR模块，OKR列表刷新
      this.bus.$emit('noticeAddTarget')
    },
    // 添加目标内容提交刷新
    getTargetAddSubmit(data) {
      this.gettargetAddClose()
      this.getOkrDetails(this.messageNotificationData)
    },
    // 添加目标内容提交刷新
    getTargetAddSubmit(data) {
      this.gettargetAddClose()
      this.getOkrDetails(this.messageNotificationData)
    },
    getHomeDetailsClose(flag) {
      this.homeDetailsState = false
    },
    // 打开子任务抽屉
    TaskTodoSubTask(item, type) {
      this.subdetailVisibleId = item.id
      this.subObjectFatherId = item.fatherTid
      this.subdetailNoticeVisible = true
      setTimeout(function () {
        this.taskdetailVisible = false // 任务详情显示
        if (type === 1) {
          this.subObjectFatherId = -1
        }
      }, 200)
    },
    // 主任务
    TaskTodomainTask(item) {
      this.notifyId = item
      this.taskdetailVisible = true // 任务详情显示
      setTimeout(function () {
        this.subdetailNoticeVisible = false // 任务详情显示
      }, 200)
      this.$emit('global:taskDetailRefresh')
    },
    receiveSchedule(id) {
      this.agreeSchedule(id, 1)
    },
    rejectSchedule(id) {
      this.agreeSchedule(id, 2)
    },
    // 响应日程
    async agreeSchedule(id, status) {
      // 1同意 2拒绝
      let obj = {
        id,
        status
      }
      await this.$apis
        .HEADERSCHEDULEDECISION(obj)
        .then((res) => {
          if (res.status === 200) {
            this.$message({
              msg: status === 1 ? '已接受日程' : '已拒绝日程',
              type: 'success'
            })
            // 如果在日程模块，刷新
            this.bus.$emit('noticeScheduleRefresh')
          } else if (res.status === 500) {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/variable.scss'; // 定义的变量
.kpi-v2 {
  max-width: 200px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    color: #00ba88;
    padding: 0px 4px;
    border-radius: 2px;
    background: #f2fffb;
    font-size: 10px;
    margin-left: 4px;
    height: 20px;
    line-height: 20px;
  }
}
.banner-img {
  img {
    height: 30px;
    cursor: pointer;
  }
}
.expiration-upgrade {
  display: inline-block;
  width: 60px;
  height: 24px;
  line-height: 22px;
  border-radius: 16px;
  border: 1px #3068ff solid;
  color: #3068ff;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  font-weight: normal;
}
::v-deep .down-btn {
  background-color: #fff !important;
  // margin: 0px 8px;
}
.icon-kehuduanmorenzhuangtai {
  font-size: 20px;
  color: #141428;
  &:hover {
    color: #005eff;
  }
}
::v-deep {
  .el-badge__content {
    top: 15px !important;
    right: 9px;
    height: 16px;
    line-height: 14px;
    padding: 0 4px;
    background-color: #ed2e7e !important;
  }
  .el-badge__content.is-dot {
    top: 15px;
    right: 8px;
    width: 10px;
    height: 10px;
  }
}
::v-deep .invite-wrap {
  cursor: pointer;
  .icon-invitationselect {
    width: 22px;
    height: 22px;
    color: #005eff;
    border: 1px solid #005eff;
    border-radius: 50%;
    font-size: 24px;
  }
  .intive-menu-select {
    background: #005eff;
    color: #fff;
  }
  &:hover {
    .icon-invitationselect {
      background: #005eff;
      color: #fff;
    }
    background: none;
  }
}
.menu-wrap {
  margin: 0px 30px 0px 16px;
}
.down-main {
  padding: 16px 10px 4px;
  position: relative;
  .download-row {
    font-size: 14px;
    color: #14142b;
    line-height: 40px;
    height: 35px;
    text-align: center;
  }
  .down-icon {
    position: absolute;
    right: 10px;
    top: 4px;
    width: 35px;
    height: 34px;
  }
}
.el-button.download-btn {
  margin: 20px auto 10px;
  width: 100%;
  color: #fff;
  background: #3068ff;
  border-color: #3068ff;
  border-radius: 17px;
  padding: 0;
  height: 40px;
  font-size: 14px;
}
.el-button.download-btn:hover,
.el-button.download-btn:active {
  color: #fff;
  background: #005eff;
}
.el-button.down-btn {
  padding: 0 !important;
  border: none !important;
  color: #14142b;
  font-size: 24px !important;
  width: 28px;
  height: 28px;
  text-align: center;
  .svg-icon {
    display: block;
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }
  .svg-icon-hover {
    display: none;
    width: 20px;
    height: 20px;
  }
}
.el-button.down-btn:hover,
.el-button.down-btn:active,
.el-button.down-btn:focus {
  padding: 0 !important;
  color: #005eff;
  background: #eef6fd !important;
  border-radius: 4px;
  width: 28px !important;
  height: 28px !important;
  text-align: center !important;
  .svg-icon {
    display: none;
    width: 20px;
    height: 20px;
  }
  .svg-icon-hover {
    display: block;
    width: 20px;
    height: 20px;
  }
}
.nav-list-div {
  display: flex;
  flex-direction: row;
  min-width: 670px;
  overflow-x: auto;
  align-items: center;
}
.top-box {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
  min-width: 1200px;
  height: 52px;
  padding-left: 8px;
  position: relative;
  z-index: 99;
  justify-content: space-between;
  background: #ffffff;
  .svg-icon {
    height: 24px;
    width: 24px;
  }
  .logo-img {
    width: 136px;
    cursor: pointer;
  }
  .logo-img-box {
    // margin-right: 10px;
  }
  .center-box {
    justify-content: center;
    .hd-add {
      width: 28px;
      height: 28px;
      color: #fff;
    }
  }
  .classify-box {
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    // max-width: 230px;
    max-width: 150px;
    .item {
      cursor: pointer;
      padding: 5px 0;
      &:hover {
        color: #0d84eb;
      }
    }
    .active {
      border-bottom: 2px solid #0d84eb;
      color: #0d84eb;
    }
  }
  .right-box {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 32px;
    .right-box_radius {
      width: 7px;
      height: 7px;
      position: absolute;
      border-radius: 100% 100%;
      background: red;
      right: 5px;
      top: 2px;
    }
    .right-box_radius_number {
      width: 16px;
      height: 16px;
      position: absolute;
      border-radius: 100% 100%;
      background: #ed2e7e;
      right: -5px;
      top: -4px;
      color: #fff;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
    .right-box_radius_number_max {
      width: 20px;
      height: 20px;
      position: absolute;
      border-radius: 100% 100%;
      background: #ed2e7e;
      right: -5px;
      top: -4px;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
    }
    .time {
      color: #eb0d0d;
      font-size: 24px;
    }
    .avatar-img {
      border-radius: 50%;
      height: 28px;
      width: 28px;
    }
    .experience {
      margin-right: 5%;
    }
    img {
      cursor: pointer;
    }
    .msg-box:hover,
    .menu-select {
      background: #eef6fd;
      border-radius: 4px;
      color: #3068ff;
    }
    .msg-box {
      padding: 4px;
      margin: 0 8px;
      font-size: 20px;
      font-weight: 700;
      cursor: pointer;
      &.active {
        &:after {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          top: -4px;
          right: -2px;
          background: #eb0d0d;
        }
      }
      &:hover {
        color: #3068ff;
      }
    }
    .msg-rg-10 {
      .hd-msg {
        width: 18px;
        height: 18px;
      }
    }
    .contact-box {
      .hd-msg {
        width: 20px !important;
        height: 20px !important;
      }
    }
    .split-line {
      height: 12px;
      width: 1px;
      background: #d9dbe9;
    }
    .nav_header_icon2 {
      width: 30px;
      height: 30px;
      padding: 4px;
    }
    .nav_header_icon2:hover {
      background: #eef6fd;
      border-radius: 4px;
    }
    .hd-set {
      width: 26px;
      height: 26px;
    }
    .hd-help {
      width: 24px;
      height: 24px;
    }
    .svg-icon {
      color: #999999;
      cursor: pointer;
    }
    .active {
      color: #0d84eb;
    }
  }
  .search-box {
    border: 1px solid #dedede;
    background: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    width: 260px;
    height: 36px;
    .search-icon {
      height: 14px;
      width: 14px;
      margin-right: -10px;
      z-index: 10;
    }
    .search-input {
      border: none;
      width: 90%;
      font-size: 14px;
    }
    .search-result-list {
      left: 0;
      top: 40px;
      padding: 10px;
      background: #fff;
      border: 1px solid #dedede;
      width: 100%;
      border-radius: 4px;
      z-index: 9999;
      max-height: 350px;
      // @include scroll-beautify;
      .search-item {
        line-height: 40px;
        padding: 0 5px;
        &:hover {
          background: #f0f0f0;
        }
      }
    }
  }
}
</style>

<style lang="less">
.double-eleven {
  position: relative;
  .text-wrap {
    position: absolute;
    font-size: 12px;
    font-weight: 800;
    color: #f6f7ff;
    line-height: 28px;
    top: 0px;
    right: 14px;
    span {
      color: #ffcb6a;
    }
  }
}
.home-icon-title {
  margin: 0px;
}
.activity-div {
  height: 28px;
  margin-top: 12px;
  margin-left: 20px;
  cursor: pointer;
  img {
    height: 28px;
  }
}
.flex-a-c-center_icon-meeting,
.flex-a-c-center_icon-classroom {
  position: relative;
  .sign-span-bus {
    position: absolute;
    top: 18px;
    left: 40px;
    font-size: 12px;
    display: inline-block;
    width: 40px;
    text-align: center;
    height: 20px;
    line-height: 18px;
    opacity: 1;
    background: #f2fffb;
    border: 1px solid #00ba88;
    color: rgba(0, 186, 136, 1);
    border-radius: 8px;
  }
  .classroom-icon {
    // position: absolute;
    // right: 0px;
    // top: 16px;
    margin-left: 4px;
    font-size: 20px;
  }
}
.flex-a-c-center_icon-classroom {
  display: flex;
  .sign-span-bus {
    left: 80px;
    width: 30px;
  }
}

.header_module .el-input--small .el-input__inner {
  height: 30px !important;
}
.top-box {
  .flex-a-c-center_icon_workbench {
    height: 32px;
    padding: 0px 9px;
    background: #f7f7fc;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    min-width: 90px;
    .icon {
      font-size: 20px !important;
      font-weight: 700 !important;
      color: #000;
    }
    .home-icon {
      width: 16px;
      height: 32px;
      font-weight: 700;
      font-size: 30px !important;
      color: #000;
    }
    .home-icon-title {
      text-align: center;
      font-size: 16px;
      color: #000;
      font-weight: 700;
      line-height: 32px;
      flex-shrink: 0;
      margin: 0px 0px 0px 4px;
    }
  }
  .flex-a-c-center_workbench_select {
    height: 32px;
    text-align: center;
    cursor: pointer;
    background: #f7f7fc;
    border-radius: 5px;
    .home-icon {
      width: 16px;
      height: 32px;
      font-weight: 700;
      font-size: 30px !important;
      color: #005eff !important;
    }
    .home-icon-title {
      text-align: center;
      font-size: 16px;
      color: #005eff;
      font-weight: 700;
      line-height: 32px;
      margin-left: 4px;
    }
    .icon {
      color: #005eff !important;
    }
  }
  .line_coum {
    border: 0;
    margin-left: 16px;
  }
  .line_coum::after {
    content: '';
    display: block;
    width: 1px;
    height: 12px;
    background: #dedede;
  }
  .flex-a-c-center_icon2 {
    // width: 60px;
    height: 52px;
    padding: 0px 8px;
    max-width: 100px;
    flex-shrink: 0;
    // .text-n(1);
    cursor: pointer;
    .home-icon-title {
      line-height: 52px;
      text-align: center;
      font-size: 16px;
      color: #000;
      position: relative;
    }
  }
  .flex-a-c-center_icon2_style {
    .home-icon-title {
      font-weight: 700;
    }
    .home-icon-title:after {
      content: '';
      display: flex;
      justify-content: center;
      width: 100%;
      height: 3px;
      background: #3a70ff;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      margin: 0px auto;
      text-align: center;
      border-radius: 3px 3px 0px 0px;
    }
  }
}

.popover-header {
  padding: 16px 0px !important;
  margin-top: 20px !important;
  z-index: 99999999 !important;
  ul.popover-ul {
    padding: 10px 0;
    li {
      border-bottom: 1px solid #e0e3ec;
      color: #333;
      font-size: 14px;
      line-height: 24px;
      padding: 0 24px;
      cursor: pointer;
      p {
        color: #999;
        font-size: 14px;
        line-height: 26px;
      }
      &.header-exit {
        border: 0;
        line-height: 36px;
      }
      &.header-exit:hover {
        background: rgba(108, 169, 255, 1);
        color: #fff;
      }
      &.header-change:hover {
        background: rgba(108, 169, 255, 1);
        color: #fff;
      }
      ul.header-ul-inner {
        li {
          border: 0;
          line-height: 36px;
          padding: 0 24px;
          cursor: pointer;
        }
        li:hover {
          background: rgba(108, 169, 255, 1);
          color: #fff;
        }
      }
      &.header-change:hover {
        p {
          color: #fff;
        }
      }
    }
    .header-ul-center {
      cursor: none;
      padding: 0;
    }
  }
}
.header_module_select {
  width: 100%;
  height: 28px;
  cursor: pointer;
}
.header_module_select:hover {
  background: rgba(108, 169, 255, 1);
}
.header_module_select .header_module_select_li {
  float: left;
  height: 100%;
  font-size: 14px;
  font-family: 'OPPOSans-R,OPPOSans';
  font-weight: normal;
  color: rgba(51, 51, 51, 1);
  margin-left: 31px;
  line-height: 28px;
}
.header_module_select:hover .header_module_select_li {
  color: rgba(255, 255, 255, 1);
}
.header_module .el-input--small .el-input__inner {
  // background: #f4f7fa;
  height: 30px;
}
.header_module .right-box .el-button {
  border: none;
  outline: none;
  background: #ffffff;
}
.header_module .right-box .el-button:hover {
  background: #ffffff;
}
.top-box .header_module_progress {
  margin-top: 11px;
}
.header_module_progress .el-progress-bar__outer {
  height: 3px !important;
  margin-top: 8px;
  margin-right: 6px;
  margin-left: 18px;
}
@media (max-width: 1024px) {
  .header_module .flex-a-c-center_icon-woring {
    width: 60px !important;
  }
}
@media (max-width: 1440px) {
  .header_module .flex-a-c-center_icon_workbench {
    // width: 50px;
    height: 32px;
  }
  .header_module .flex-a-c-center_workbench_select {
    height: 32px;
  }
}
</style>
<style lang="scss">
.el-popover {
  border-radius: 16px !important;
  padding: 12px !important;
}
.account-set-tooltip {
  width: 70px;
}
</style>
