var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "okr-file-div", attrs: { id: "okrFileDiv" } },
    [
      _c(
        "div",
        {
          staticClass: "table-div",
          class: _vm.isDialog == true ? "table-height-bg" : "table-height-sm",
        },
        [
          _c(
            "div",
            {
              staticClass: "batch-operation-div",
              style:
                _vm.isDialog == true
                  ? "top:26px !important"
                  : "top:26px !important",
            },
            [
              _vm.multipleSelection.length > 0
                ? _c("div", { staticClass: "operation-tips" }, [
                    _vm._v(
                      " 已选择" + _vm._s(_vm.multipleSelection.length) + "项目 "
                    ),
                  ])
                : _vm._e(),
              _vm.multipleSelection.length <= 0 && _vm.tableData.length > 0
                ? _c(
                    "div",
                    { staticClass: "operation-tips operation-tips-all" },
                    [_vm._v(" 全选 ")]
                  )
                : _vm._e(),
              this.multipleSelection.length > 0
                ? _c(
                    "div",
                    { staticClass: "operation-button" },
                    [
                      !_vm.$utils.isWxMac()
                        ? _c(
                            "el-button",
                            {
                              staticClass: "batch-download-button",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.batchDownload()
                                },
                              },
                            },
                            [
                              _c("Icon", {
                                staticClass: "file-svg",
                                attrs: { "icon-class": "icon-xiazai1" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.disabled,
                              expression: "!disabled",
                            },
                          ],
                          staticClass: "batch-delete-button",
                          attrs: { type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.batchDelete()
                            },
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "file-svg",
                            attrs: { "icon-class": "icon-shanchu1" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "el-upload",
            {
              ref: "Fileupload",
              staticClass: "upload-demo",
              attrs: {
                "show-file-list": false,
                action: "",
                "on-change": _vm.onchange,
                multiple: "",
                "http-request": _vm.uploadFile,
              },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isDialog && (!_vm.disabled || _vm.isSharer),
                      expression: "!isDialog && (!disabled || isSharer)",
                    },
                  ],
                  staticClass: "batch-upload-button",
                  attrs: { type: "primary" },
                },
                [
                  _c("Icon", {
                    staticClass: "file-svg",
                    attrs: {
                      "icon-class": "icon-task_cooperation_add_yes_not_icon",
                    },
                    on: { click: _vm.clickUpload },
                  }),
                  _vm._v("上传文件 "),
                ],
                1
              ),
            ],
            1
          ),
          _vm.tableData.length > 0
            ? _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticClass: "okr-file-table",
                  staticStyle: { width: "99.9%" },
                  attrs: {
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    height: "100%",
                    "row-class-name": _vm.tableRowClassName,
                    "row-style": _vm.setRowStyle,
                  },
                  on: {
                    "selection-change": _vm.handleSelectionChange,
                    "cell-mouse-enter": _vm.cellHover,
                    "cell-mouse-leave": _vm.cellOut,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { fixed: "left", type: "selection", width: "38" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "",
                      width: "180",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "file-list",
                                  on: {
                                    click: function ($event) {
                                      return _vm.lookImg(scope.row)
                                    },
                                  },
                                },
                                [
                                  scope.row.fileType == "docx" ||
                                  scope.row.fileType == "doc" ||
                                  scope.row.fileType == "DOCX" ||
                                  scope.row.fileType == "DOC"
                                    ? _c("Icon", {
                                        staticClass: "file-svg",
                                        attrs: { "icon-class": "icon-DOCX" },
                                      })
                                    : scope.row.fileType == "mp3" ||
                                      scope.row.fileType == "MP3"
                                    ? _c("Icon", {
                                        staticClass: "file-svg",
                                        attrs: { "icon-class": "icon-MP3" },
                                      })
                                    : scope.row.fileType == "mp4" ||
                                      scope.row.fileType == "MP4"
                                    ? _c("Icon", {
                                        staticClass: "file-svg",
                                        attrs: { "icon-class": "icon-MP4" },
                                      })
                                    : scope.row.fileType == "pdf" ||
                                      scope.row.fileType == "PDF"
                                    ? _c("Icon", {
                                        staticClass: "file-svg",
                                        attrs: { "icon-class": "icon-PDF" },
                                      })
                                    : scope.row.fileType == "ppt" ||
                                      scope.row.fileType == "PPT"
                                    ? _c("Icon", {
                                        staticClass: "file-svg",
                                        attrs: { "icon-class": "icon-PPT" },
                                      })
                                    : scope.row.fileType == "txt" ||
                                      scope.row.fileType == "TXT"
                                    ? _c("Icon", {
                                        staticClass: "file-svg",
                                        attrs: { "icon-class": "icon-filetxt" },
                                      })
                                    : scope.row.fileType == "XLS" ||
                                      scope.row.fileType == "xls" ||
                                      scope.row.fileType == "XLSX" ||
                                      scope.row.fileType == "xlsX" ||
                                      scope.row.fileType == "xlsx"
                                    ? _c("Icon", {
                                        staticClass: "file-svg",
                                        attrs: { "icon-class": "icon-XLS" },
                                      })
                                    : _c("Icon", {
                                        staticClass: "file-svg",
                                        attrs: {
                                          "icon-class": "icon-fileCommon",
                                        },
                                      }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        flex: "1",
                                        "min-width": "0",
                                        overflow: "hidden",
                                        "white-space": "nowrap",
                                        "text-overflow": "ellipsis",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(scope.row.fileName) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3246943655
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "",
                      prop: "",
                      width: "80",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                [
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: scope.row.user.name
                                        ? scope.row.user.name
                                        : scope.row.user.name,
                                      "name-data": {
                                        name: scope.row.user.name,
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3661713260
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "",
                      prop: "",
                      width: "165",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [_vm._v(_vm._s(scope.row.createDate))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3836017825
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    flex: "1",
                                    "min-width": "0",
                                    overflow: "hidden",
                                    "white-space": "nowrap",
                                    "text-overflow": "ellipsis",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$utils.formatFileSize(
                                          scope.row.fileSize
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1530950446
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "", width: "90" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.id == _vm.activeRowId
                                ? _c(
                                    "div",
                                    { staticClass: "operate-row" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: scope.row.status == 0,
                                              expression:
                                                "scope.row.status == 0",
                                            },
                                          ],
                                          staticClass: "download-button",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fileDownload(
                                                scope.row,
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("Icon", {
                                            staticClass: "okr-operate",
                                            attrs: {
                                              "icon-class": "icon-xiazai1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      !_vm.disabled || _vm.isSharer
                                        ? _c(
                                            "el-popover",
                                            {
                                              ref: `operatePopover${scope.row.id}`,
                                              attrs: {
                                                placement: "bottom",
                                                title: "",
                                                width: "180",
                                                trigger: "click",
                                              },
                                              on: {
                                                show: function ($event) {
                                                  return _vm.popoverShow(
                                                    scope.row
                                                  )
                                                },
                                                hide: function ($event) {
                                                  return _vm.popoverHide()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "operation-div",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "operation-row",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.fileIsTop(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row
                                                              .topStatus == 1
                                                              ? "取消置顶文件"
                                                              : "置顶文件"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  (_vm.isSharer &&
                                                    _vm.userInfo.id ==
                                                      scope.row.user.id) ||
                                                  !_vm.disabled
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "operation-row",
                                                        },
                                                        [
                                                          _vm._v(" 开启下载 "),
                                                          _c("el-switch", {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "52px",
                                                            },
                                                            attrs: {
                                                              "active-value": 0,
                                                              "inactive-value": 1,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeIsDownload(
                                                                  scope.row,
                                                                  scope.row
                                                                    .status
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                scope.row
                                                                  .status,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    "status",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "scope.row.status",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  (_vm.isSharer &&
                                                    _vm.userInfo.id ==
                                                      scope.row.user.id) ||
                                                  !_vm.disabled
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "operation-row delete-file",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteFile(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" 删除文件 ")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "more-button",
                                                  attrs: {
                                                    slot: "reference",
                                                    type: "text",
                                                  },
                                                  slot: "reference",
                                                },
                                                [
                                                  _c("Icon", {
                                                    staticClass: "okr-operate",
                                                    attrs: {
                                                      "icon-class":
                                                        "icon-more-row",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3979709396
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.tableData.length <= 0
            ? _c("div", { staticClass: "no-data-div" }, [_c("no-data")], 1)
            : _vm._e(),
        ],
        1
      ),
      _vm.loading
        ? _c("process-dialog", {
            attrs: {
              type: "upload",
              "dialog-txt": _vm.loadingTxt,
              rate: _vm.percentage,
              "loading-param": _vm.loadingParam,
            },
            on: { close: _vm.closePage },
            model: {
              value: _vm.loading,
              callback: function ($$v) {
                _vm.loading = $$v
              },
              expression: "loading",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }