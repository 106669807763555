var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.type === "align"
      ? _c(
          "div",
          { staticClass: "align-title" },
          [
            _c("wx-open-data", {
              attrs: {
                id: _vm.objData.userName,
                nameData: { name: _vm.objData.userName },
              },
            }),
            _vm._v(
              " 邀请你对齐 " +
                _vm._s(_vm.alignType === "O" ? "目标" : "关键结果") +
                " "
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "objective-show",
        class:
          _vm.type === "align" && _vm.step !== 3 ? "align-objective-show" : "",
      },
      [
        (_vm.alignState && !_vm.alignHide) || _vm.step === 3
          ? _c("div", { staticClass: "okr-top-align" }, [
              _c("div", { staticClass: "up-line" }, [
                _c(
                  "div",
                  { staticClass: "align-icon-up" },
                  [
                    _c("Icon", {
                      staticClass: "line-icon",
                      attrs: { "icon-class": "icon-okrline-up" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "down-left-line" }),
              ]),
              _vm.alignState && !_vm.alignHide
                ? _c("div", { staticClass: "align-content-wrap" }, [
                    _vm.fatherType === "O" || _vm.fatherType === "KR"
                      ? _c(
                          "div",
                          {
                            staticClass: "align-okr",
                            class: _vm.mouseenterState ? "hover-align-okr" : "",
                          },
                          [
                            _c("img", {
                              staticClass: "align-avatar border-radius-20",
                              attrs: {
                                src: _vm.fatherNameObjList[0].list[0].chargeUser
                                  .avatar,
                              },
                            }),
                            _c(
                              "div",
                              {
                                class: `father-type father-type-${_vm.fatherType}`,
                              },
                              [_vm._v(_vm._s(_vm.fatherType))]
                            ),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: _vm.$utils.tagFilter(
                                    _vm.fatherType === "KR"
                                      ? _vm.fatherNameObjList[0].list[0]
                                          .childObjectivesAssociationList[0]
                                          .title
                                      : _vm.fatherNameObjList[0].list[0].content
                                  ),
                                  "open-delay": 300,
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "align-content",
                                  class: `align-content-${_vm.fatherType}-${
                                    _vm.fatherNameObjList[0].list[0]
                                      .isVisible === 1
                                  }`,
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.fatherType === "KR"
                                        ? _vm.fatherNameObjList[0].list[0]
                                            .childObjectivesAssociationList[0]
                                            .title
                                        : _vm.fatherNameObjList[0].list[0]
                                            .content
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  placement: "top",
                                  "open-delay": 300,
                                  content: "点击取消对齐",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "del-icon",
                                    on: {
                                      mouseenter: function ($event) {
                                        _vm.mouseenterState = true
                                      },
                                      mouseleave: function ($event) {
                                        _vm.mouseenterState = false
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont ymbguanbianniu",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteTargetAligningSubmit(
                                            _vm.fatherNameObjList[0].list[0]
                                              .childObjectivesAssociationList[0]
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c("privateOkr", {
                              attrs: {
                                createUser:
                                  _vm.userInfo.id ===
                                  _vm.fatherNameObjList[0].userId,
                                visible:
                                  _vm.fatherNameObjList[0].list[0].isVisible ===
                                  1,
                                content:
                                  _vm.fatherNameObjList[0].list[0].isVisible ===
                                  1
                                    ? "对方已设置为不可见"
                                    : "",
                                type: _vm.fatherType === "KR" ? 2 : 1,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.fatherType === "list"
                      ? _c(
                          "div",
                          { staticClass: "father-align-list-wrap" },
                          _vm._l(_vm.fatherNameObjList, function (item) {
                            return _c(
                              "div",
                              { key: item.userId, staticClass: "align-list" },
                              [
                                _c(
                                  "align-member-card",
                                  {
                                    attrs: {
                                      "is-okr": true,
                                      userId: item.userId,
                                      deleteAlign: true,
                                      disabled: true,
                                      targetAligningList: item.list,
                                    },
                                    on: {
                                      toOKRList: function ($event) {
                                        return _vm.getTopMembersFatherList(item)
                                      },
                                      okrDetail:
                                        _vm.getTopMembersAlignmentSelect,
                                      deleteAlign:
                                        _vm.deleteTargetAligningSubmit,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "align-detail" }, [
                                      _c(
                                        "div",
                                        { staticClass: "advar" },
                                        [
                                          _c("el-image", {
                                            staticClass: "img border-radius-20",
                                            attrs: {
                                              src: item.list[0].userAvatar,
                                              fit: "cover",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "name" },
                                        [
                                          _c("wx-open-data", {
                                            attrs: {
                                              id: item.list[0].userName,
                                              nameData: {
                                                name: item.list[0].userName,
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      item.num > 1
                                        ? _c("div", { staticClass: "num" }, [
                                            _vm._v(_vm._s(item.num)),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "kr-add align-add" },
                      [
                        _c(
                          "target-aligning-new",
                          {
                            ref: "targetAligning",
                            attrs: {
                              disabled: !_vm.objData.publicState,
                              "target-aligning-list": _vm.targetAligningList,
                              "target-aligning-show": _vm.targetAligningShow,
                              type: "add",
                            },
                            on: {
                              getTargetAligningClose:
                                _vm.getTargetAligningClose,
                              getTargetAligningSubmit:
                                _vm.getTargetAligningSubmit,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                attrs: { slot: "guidepop" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.addAlign.apply(null, arguments)
                                  },
                                },
                                slot: "guidepop",
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content:
                                        "可对齐其他人的目标，通常会对齐上级的目标，有助于团队目标一致，行动更聚焦",
                                      "open-delay": 300,
                                      placement: "bottom",
                                    },
                                  },
                                  [_c("span", [_vm._v("+ 对齐目标")])]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _c("div", { staticClass: "align-content-wrap" }, [
                    _c(
                      "div",
                      { staticClass: "align-okr" },
                      [
                        _c("img", {
                          staticClass: "align-avatar border-radius-20",
                          attrs: {
                            src:
                              _vm.alignObjData.chargeUser &&
                              _vm.alignObjData.chargeUser.avatar,
                          },
                        }),
                        _c(
                          "div",
                          {
                            class: `father-type father-type-${_vm.alignObjData.fatherType}`,
                          },
                          [_vm._v(_vm._s(_vm.alignObjData.fatherType))]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: _vm.$utils.tagFilter(
                                _vm.alignObjData.fatherType === "KR"
                                  ? _vm.alignObjData.title
                                  : _vm.alignObjData.content
                              ),
                              "open-delay": 300,
                              placement: "bottom",
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "align-content",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.alignObjData.fatherType === "KR"
                                    ? _vm.alignObjData.title
                                    : _vm.alignObjData.content
                                ),
                              },
                            }),
                          ]
                        ),
                        _c("privateOkr", {
                          attrs: {
                            createUser:
                              _vm.userInfo.id === _vm.alignObjData.createUserid,
                            visible: _vm.alignObjData.typeIsVisible === 1,
                            content:
                              _vm.alignObjData.typeIsVisible === 1
                                ? "对方已设置为不可见"
                                : "",
                            type: _vm.alignObjData.fatherType === "KR" ? 2 : 1,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "okr-title", class: `okr-title-${_vm.alignType}` },
          [
            _c("div", { staticClass: "title-content" }, [
              _vm.type === "add"
                ? _c("img", {
                    staticClass: "okr-title-icon",
                    attrs: { src: require("@/assets/imgs/okr/objective.png") },
                  })
                : _c("img", {
                    staticClass: "okr-title-icon avatar border-radius-20",
                    attrs: {
                      src:
                        _vm.objData.userAvatar || _vm.objData.chargeUser.avatar,
                    },
                  }),
              _c(
                "div",
                { staticClass: "okr-content" },
                [
                  _c("AdviceInput", {
                    attrs: {
                      html: _vm.objData.content,
                      type: 1,
                      "ignore-rule-id-list": _vm.objData.ignoreRuleIdList,
                      "edit-state": false,
                      "advice-disable": _vm.type !== "add",
                      "kr-length": _vm.objData.keyresultsList.length,
                    },
                    on: { addIgnore: _vm.addIgnoreRule },
                  }),
                  _c("privateOkr", {
                    attrs: {
                      createUser: _vm.userInfo.id === _vm.objData.createUserid,
                      visible: _vm.objData.isVisible === 1,
                      content:
                        _vm.objData.isVisible === 1 ? "对方已设置为不可见" : "",
                      type: 1,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm.alignState
              ? _c(
                  "div",
                  [
                    _c("confidence-select", {
                      attrs: { "can-edit": true, type: 2 },
                      model: {
                        value: _vm.objData.confidence,
                        callback: function ($$v) {
                          _vm.$set(_vm.objData, "confidence", $$v)
                        },
                        expression: "objData.confidence",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.step == 3
              ? _c(
                  "div",
                  [
                    _c("permission", {
                      attrs: { item: _vm.objData.objectivesPower },
                      on: { confirm: _vm.permissionConfirm },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { staticClass: "kr-title-list" },
          [
            _c(
              "draggable",
              {
                attrs: {
                  handle: ".key_result_dragger_icon",
                  disabled: !(_vm.draggableStatus && _vm.isChangeUser),
                },
                on: { update: _vm.drop },
                model: {
                  value: _vm.objData.keyresultsList,
                  callback: function ($$v) {
                    _vm.$set(_vm.objData, "keyresultsList", $$v)
                  },
                  expression: "objData.keyresultsList",
                },
              },
              _vm._l(_vm.objData.keyresultsList, function (keyResult, index) {
                return _c(
                  "div",
                  {
                    key: keyResult.index,
                    staticClass: "kr-item",
                    class: `kr-item-${keyResult.alignState}`,
                  },
                  [
                    _vm.type !== "align"
                      ? _c(
                          "div",
                          { staticClass: "up-line" },
                          [
                            _c("div", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !(
                                      index ===
                                        _vm.objData.keyresultsList.length - 1 &&
                                      (!_vm.editState || _vm.addKeyState)
                                    ) && index !== 9,
                                  expression:
                                    "\n            !(\n              index === objData.keyresultsList.length - 1 &&\n              (!editState || addKeyState)\n            ) && index !== 9\n          ",
                                },
                              ],
                              staticClass: "down-left-line",
                            }),
                            _c("Icon", {
                              staticClass: "line-icon",
                              attrs: { "icon-class": "icon-okrline-down" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "kr-title-box" },
                      [
                        _vm.draggableStatus &&
                        _vm.isChangeUser &&
                        _vm.type !== "align"
                          ? _c("Icon", {
                              staticClass: "key_result_dragger_icon",
                              attrs: { "icon-class": "icon-targetkrdragger" },
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "kr-index" }, [
                          _vm._v("KR" + _vm._s(index + 1)),
                        ]),
                        _vm.editKrIndex !== index
                          ? _c(
                              "div",
                              { staticClass: "kr-title" },
                              [
                                _c("AdviceInput", {
                                  attrs: {
                                    html: keyResult.title,
                                    type: 2,
                                    "ignore-rule-id-list":
                                      keyResult.ignoreRuleIdList,
                                    "edit-state": false,
                                    "advice-disable": _vm.type !== "add",
                                  },
                                  on: {
                                    addIgnore: function ($event) {
                                      return _vm.addkrIgnoreRule($event, index)
                                    },
                                    clickOn: function ($event) {
                                      return _vm.editKr(index)
                                    },
                                  },
                                }),
                                _c("privateOkr", {
                                  attrs: {
                                    createUser:
                                      _vm.userInfo.id ===
                                      _vm.objData.createUserid,
                                    visible: keyResult.isVisible === 1,
                                    content:
                                      keyResult.isVisible === 1
                                        ? "对方已设置为不可见"
                                        : "",
                                    type: 2,
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "kr-title-input" },
                              [
                                _c("key-result-add", {
                                  attrs: {
                                    "is-edit": true,
                                    "edit-info": keyResult,
                                    "no-request": true,
                                  },
                                  on: {
                                    saveContent: function ($event) {
                                      return _vm.saveContent($event, index)
                                    },
                                    cancel: _vm.cancel,
                                  },
                                }),
                              ],
                              1
                            ),
                        _vm.type !== "align"
                          ? _c(
                              "div",
                              { staticClass: "kr-weight" },
                              [
                                _vm.editWeightIndex !== index
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "点击调整权重",
                                          "open-delay": 300,
                                          placement: "bottom",
                                          disabled:
                                            _vm.step !== 3 ||
                                            index ===
                                              _vm.objData.keyresultsList
                                                .length -
                                                1,
                                        },
                                      },
                                      [
                                        _vm.editWeightIndex !== index
                                          ? _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editWeight(index)
                                                  },
                                                },
                                              },
                                              [
                                                _c("Icon", {
                                                  staticClass: "icon-weight",
                                                  attrs: {
                                                    "icon-class": "icon-weight",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "weight-text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(keyResult.weight)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _c("el-input-number", {
                                      ref: "inputNumberRef",
                                      refInFor: true,
                                      staticClass: "form-number",
                                      attrs: {
                                        precision: 0,
                                        controls: false,
                                        min: 0,
                                        max: 100,
                                      },
                                      on: {
                                        blur: function ($event) {
                                          _vm.editWeightIndex = null
                                        },
                                        change: function ($event) {
                                          return _vm.changeKrWeight(
                                            $event,
                                            index
                                          )
                                        },
                                      },
                                      model: {
                                        value: keyResult.weight,
                                        callback: function ($$v) {
                                          _vm.$set(keyResult, "weight", $$v)
                                        },
                                        expression: "keyResult.weight",
                                      },
                                    }),
                                _vm._v("% "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.step == 3
                          ? _c(
                              "div",
                              { staticClass: "permission" },
                              [
                                _c("permission", {
                                  attrs: {
                                    item: keyResult.objectivesPower,
                                    type: 2,
                                    index: index + 1,
                                  },
                                  on: { confirm: _vm.permissionConfirm },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.step === 2 && _vm.type === "add"
                          ? _c("div", { staticClass: "close-box" }, [
                              _c("i", {
                                staticClass: "iconfont ymbguanbianniu",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteKr(index)
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
            !_vm.addKeyState &&
            _vm.editState &&
            _vm.objData.keyresultsList.length < 10
              ? _c("div", { staticClass: "kr-item" }, [
                  _c(
                    "div",
                    { staticClass: "up-line" },
                    [
                      _c("div", { staticClass: "down-line-icon" }),
                      _c("Icon", {
                        staticClass: "line-icon",
                        attrs: { "icon-class": "icon-okrline-down" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "kr-title-box add-result" }, [
                    _c("div", { staticClass: "kr-index" }, [
                      _vm._v(
                        "KR" + _vm._s(_vm.objData.keyresultsList.length + 1)
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "kr-add",
                        on: { click: _vm.addKeyResult },
                      },
                      [_vm._v("+ 添加关键结果")]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.addKeyState
              ? _c(
                  "div",
                  { staticClass: "key-result-input" },
                  [
                    _c("key-result-add", {
                      attrs: { "no-request": true },
                      on: {
                        saveContent: function ($event) {
                          return _vm.saveContent($event, "add")
                        },
                        cancel: _vm.cancel,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }