// 日历
// 统一管理所有的基础请求
import apis from '../apis_moudles/index'
import req from '../request'
// 获取项目工作流列表
const PROJECTPROCESSLIST = (params) => {
  return req.get(apis.projectProcessList, {
    params
  })
}
// 修改项目流程
const PROJECTUPDATEPROCESS = (params) => {
  return req.put(apis.projectUpdateProcess, params)
}
// 获取项目概述
const GETPRODESC = (id, params) => {
  return req.get(`${apis.getProDesc}${id}`, {
    params
  })
}
// 查询推荐的项目列表
const PROJECTRECOMMENDLIST = (params) => {
  return req.get(apis.projectRecommendList, {
    params
  })
}
// 项目列表
const PROJECTLIST = (params) => {
  return req.get(`${apis.projectList}`, {
    params
  })
}
// 项目模版列表
const PROTEMLIST = (params) => {
  return req.get(`${apis.proTemList}`, {
    params
  })
}
// 项目负责人修改
const PROTASKCHARGEUSERUPDATE = (params) => {
  return req.post(`${apis.proTaskChargeUserUpdate}`, params)
}
// 项目状态修改
const PROTASKSTATUSUPDATE = (params) => {
  return req.post(`${apis.proTaskStatusUpdate}`, params)
}
// 项目任务排序
const PROTASKFIELDUPDATE = (id, params) => {
  return req.put(`${apis.proTaskFieldUpdate}${id}`, params)
}
// 获取个人任务列信息
const PROTASKFIELDLIST = (params) => {
  return req.get(`${apis.proTaskFieldList}`, {
    params
  })
}
// 获取表格任务列表
const PROTASKTABLELIST = (url, params) => {
  return req.post(`${url}`, params)
}
// 项目任务删除
const PROTASKDEL = (params) => {
  return req.post(`${apis.proTaskDel}`, params)
}
// 单个增加项目人员
const PROADDPEOPLEONE = (params) => {
  return req.post(`${apis.proAddPeopleOne}`, params)
}
// 项目人员删除
const PROPEOPLEDEL = (id, params) => {
  return req.delete(`${apis.proPeopleDel}${id}`, {
    params
  })
}
// 修改项目概述
const PRODESCEDIT = (id, params) => {
  return req.put(`${apis.proDescEdit}${id}`, params)
}
// 新增流程
const PROPROCESSADD = (params) => {
  return req.post(`${apis.proProcessAdd}`, params)
}
// 编辑流程
const PROPROCESSEDIT = (id, params) => {
  return req.put(`${apis.proProcessEdit}${id}`, params)
}
// 删除流程
const PROPROCESSDEL = (id, params) => {
  return req.delete(`${apis.proProcessDel}${id}`, {
    params
  })
}
// 项目动态
const PROPCOMMENTLIST = (params) => {
  return req.get(`${apis.proCommentList}`, {
    params
  })
}
// 项目删除
const PRODEL = (id, params) => {
  return req.delete(`${apis.proDel}${id}`, {
    params
  })
}
// 项目归档
const PROCLOSE = (id, params) => {
  return req.put(`${apis.proClose}${id}`, params)
}
// 项目恢复
const PRORECOVERY = (id, params) => {
  return req.put(`${apis.proRecovery}${id}`, params)
}
// 编辑项目名称
const PRONAMEEDIT = (id, params) => {
  return req.put(`${apis.proNameEdit}${id}`, params)
}
// 新增项目
const PROADD = (params) => {
  return req.post(`${apis.proAdd}`, params)
}
// 获取选择模版详情数据
const PROTEMDETAIL = (id, params) => {
  return req.get(`${apis.proTemDetail}${id}`, {
    params
  })
}
// 获取选择模版详情数据
const PROTEMTYPE = (params) => {
  return req.get(`${apis.proTemType}`, {
    params
  })
}
// 模版新增项目
const PROADDBYTEM = (id, params) => {
  return req.post(`${apis.proTemDetail}${id}`, params)
}
// 多选添加项目人员
const PROADDPEOPLENUMBER = (params) => {
  return req.post(`${apis.proAddPeopleNumber}`, params)
}
// 获取项目人员
const PROPEOPLELIST = (params) => {
  return req.get(`${apis.proPeopleList}`, {
    params
  })
}
// 获取今日任务完成数
const PROTASKTODAYFINISNCOUNT = (params) => {
  return req.get(`${apis.proTaskTodayFinishCount}`, {
    params
  })
}
// 查询当前项目关联的KR列表
const PROATTACHKRLIST = (params) => {
  return req.get(`${apis.proAttachKrList}`, {
    params
  })
}
// 复制项目
const PROCOPY = (params) => {
  return req.post(`${apis.proCopy}`, params)
}
// 我的项目列表
const GETMYPROJECT = (params) => {
  return req.get(`${apis.getMyProject}`, {
    params
  })
}
// 企业项目列表
const GETCOMPANYPROJECT = (params) => {
  return req.get(`${apis.getCompanyProject}`, {
    params
  })
}
// 归档项目列表
const GETCLOSEPROJECT = (params) => {
  return req.get(`${apis.getCloseProject}`, {
    params
  })
}
// 项目收藏
const ROJECTSTAR = (params) => {
  return req.put(`${apis.projectStar}`, params)
}
// 项目资源
const GETROJECTFILELIST = (params) => {
  return req.get(`${apis.getProjectFileList}`, {
    params
  })
}
// 项目状态
const EDITROJECTSTATUS = (params) => {
  return req.put(`${apis.editProjectStatus}`, params)
}
// 项目到期日期
const EDITROJECTENDDATE = (params) => {
  return req.put(`${apis.editProjectEndDate}`, params)
}
// 我浏览的项目
const GETBROWSERPROJECT = (params) => {
  return req.get(`${apis.getBrowseProject}`, {
    params
  })
}
// 项目退出
const PROJECTQUIT = (id, params) => {
  return req.put(`${apis.projectQuit}${id}`, params)
}
// 项目退出
const SETPROJECTADMIN = (params) => {
  return req.put(`${apis.setProjectAdmin}`, params)
}
// 添加项目资源
const ADDPROFILE = (params) => {
  return req.post(`${apis.getProjectFileList}`, params)
}
// 删除项目资源
const DELETEPROFILE = (id, params) => {
  return req.delete(`${apis.getProjectFileList}/${id}`, {
    params
  })
}
// 获取项目统计列表
const GETPROANALYSELIST = (params) => {
  return req.get(`${apis.getProAnalyseList}`, {
    params
  })
}
// 获取单个统计概览信息
const GETPROANALYSEVIEW = (params) => {
  return req.get(`${apis.getProAnalyseView}`, {
    params
  })
}
// 获取单个报表详情
const GETPROANALYSEINFO = (params) => {
  return req.post(`${apis.getProAnalyseInfo}`, params)
}
// 获取单个报表详情分页
const GETPROANALYSEINFOPAGE = (params) => {
  return req.post(`${apis.getProAnalyseInfo}/page/`, params)
}
// 获取项目标签列表
const GETPROJECTLABEL = (params) => {
  return req.get(`${apis.getProjectLable}`, {
    params
  })
}
// 获取单个报表详情分页数据
const GETPRODETAILPAGE = (params) => {
  return req.post(`${apis.getProDetailPage}`, params)
}
// 甘特图任务列表
const GANTTTASKLIST = (id, params) => {
  return req.post(`${apis.ganttTaskList}${id}`, params)
}
// 设置依赖类型
const GANTTLINKTYPESET = (id, params) => {
  return req.put(`${apis.ganttLinkTypeSet}${id}`, params)
}
// 甘特图添加依赖
const GANTTLINKADD = (id, params) => {
  return req.put(`${apis.ganttLinkAdd}${id}`, params)
}
// 甘特图删除依赖
const GANTTLINKDEL = (preId, afterId, params) => {
  return req.delete(
    `${apis.ganttLinkDel}${preId}/relieve/relation/${afterId}`,
    params
  )
}
// 甘特图任务拖拽
const GANTTTASKDRAG = (id, params) => {
  return req.put(`${apis.ganttTaskDrag}${id}`, params)
}
// 项目数量统计
const PMPRONUM = (params) => {
  return req.get(`${apis.pmProNum}`, {
    params
  })
}
// 添加项目分类
const PMADDCATEGORIES = (params) => {
  return req.post(`${apis.pmAddCategories}`, params)
}
// 编辑项目分类
const PMEDITCATEGORIES = (params) => {
  return req.put(`${apis.pmAddCategories}`, params)
}
// 项目分类列表
const PMCATEGORIESLIST = (params) => {
  return req.get(`${apis.pmCategoriesList}`, {
    params
  })
}
// 删除项目分类
const PMDELETECATEGORIES = (id, params) => {
  return req.delete(`${apis.pmAddCategories}/${id}`, params)
}
// 项目分类关联项目列表
const PMRELATIONPROJECTLIST = (id, params) => {
  return req.get(`${apis.pmRelationProjectList}${id}`, {
    params
  })
}
// 根据分类id获取分类下面所有项目id列表
const PROJECTIDLISTBYCATEGORIESID = (id, params) => {
  return req.get(`${apis.projectIdListByCategoriesId}${id}`, {
    params
  })
}
// 添加项目分类
const ADDPROJECTCATEGORIESRELATION = (params) => {
  return req.post(`${apis.addProjectCategoriesRelation}`, params)
}
// 星标项目列表
const GETSTARPROJECT = (params) => {
  return req.get(`${apis.getStarProject}`, {
    params
  })
}
// 项目分类下项目列表
const GETCLASSPROJECT = (params) => {
  return req.get(`${apis.getClassProject}`, {
    params
  })
}
// 项目置顶
const ROJECTTOP = (params) => {
  return req.put(`${apis.projectTop}`, params)
}
// 查询里程碑
const PROJECTMILEPOSTLIST = (params) => {
  return req.get(`${apis.projectMilepostList}`, {
    params
  })
}
// 添加里程碑
const PROJECTMILEPOSTSAVE = (params) => {
  return req.post(`${apis.projectMilepostSave}`, params)
}
// 修改里程碑
const PROJECTMILEPOSTUPDATE = (params) => {
  return req.post(`${apis.projectMilepostUpdate}`, params)
}
// 分页查询里程碑动态列表
const MILEPOSTDYNAMICPAGELIST = (params) => {
  return req.get(`${apis.milepostDynamicPageList}`, {
    params
  })
}
// 添加里程碑评论
const MILEPOSTDYNAMICSAVE = (params) => {
  return req.post(`${apis.milepostDynamicSave}`, params)
}
// 根据评论id,删除里程碑评论
const MILEPOSTDYNAMICDELETE = (params) => {
  return req.post(`${apis.milepostDynamicDelete}`, params)
}
// 删除里程碑
const MILEPOSTDELETE = (params) => {
  return req.post(`${apis.milepostDelete}`, params)
}
// 项目任务搜索
const PROJECTTASKSEARCH = (params) => {
  return req.get(`${apis.projectTaskSearch}`, {
    params
  })
}
// 项目搜索
const PROJECTSEARCHSHORTCUT = (params) => {
  return req.get(`${apis.projectSearchShortCut}`, {
    params
  })
}
// 项目精简信息
const PROJECTSIMPLIFYINFO = (projectId, params) => {
  return req.get(`${apis.projectSimplifyInfo}${projectId}`, {
    params
  })
}
// 时间轴导出表格
const PMGANTTDOWNLOAD = (id, params) => {
  return req.post(`${apis.pmGanttDownload}${id}`, params, {
    responseType: 'arraybuffer'
  })
}
// 项目移交所有者
const PMTRANSFER = (params) => {
  return req.post(`${apis.pmTransfer}`, params)
}
// 项目导入任务
const PMIMPORTTASK = (url, params) => {
  return req.post(`${url}`, params, {
    timeout: 120000,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 项目导出任务
const PMEXPORTTASK = (params) => {
  return req.post(`${apis.pmExportTask}`, params)
}
// 项目任务导出任务列表
const PMEXPORTTASKLIST = (params) => {
  return req.get(`${apis.pmExportTaskList}`, {
    params
  })
}
// 项目任务审批列表
const PMAPPROVALPAGELIST = (params) => {
  return req.get(`${apis.pmApprovalPageList}`, {
    params
  })
}
// 项目任务-提交人撤销/提交流程
const PMPROCESSDATASUBMITT = (params) => {
  return req.post(`${apis.pmProcessDataSubmitt}`, params)
}
// 项目任务催办
const PMTASKAPPROVALDATAREMIND = (params) => {
  return req.post(`${apis.pmTaskApprovalDataRemind}`, params)
}
// 变更父任务
const PMTASKUPDATETASKFATHER = (params) => {
  return req.post(`${apis.pmTaskUpdateTaskFather}`, params)
}
// 复制项目流程中的任务
const PMPROJECTPROCESSCOPYTASK = (params) => {
  return req.post(`${apis.pmProjectProcessCopyTask}`, params)
}
// 导出基础请求函数
export default [
  {
  PMPROJECTPROCESSCOPYTASK
},
{
  PMTASKUPDATETASKFATHER
},
{
  PMTASKAPPROVALDATAREMIND
},
{
  PMPROCESSDATASUBMITT
},
{
  PMAPPROVALPAGELIST
},
{
  PMEXPORTTASKLIST
},
{
  PMEXPORTTASK
},
{
  PMIMPORTTASK
},
{
  PMTRANSFER
},
{
  PMGANTTDOWNLOAD
},
{
  PROJECTSIMPLIFYINFO
},
{
  PROJECTSEARCHSHORTCUT
},
{
  PROJECTTASKSEARCH
},
{
  MILEPOSTDELETE
},
{
  MILEPOSTDYNAMICDELETE
},
{
  MILEPOSTDYNAMICSAVE
},
{
  MILEPOSTDYNAMICPAGELIST
},
{
  PROJECTMILEPOSTUPDATE
},
{
  PROJECTMILEPOSTSAVE
},
{
  PROJECTMILEPOSTLIST
},
// {
//   CHANGEPROJECTLIST
// },
{
  ROJECTTOP
},
{
  GETCLASSPROJECT
},
{
  GETSTARPROJECT
},
{
  ADDPROJECTCATEGORIESRELATION
},
{
  PROJECTIDLISTBYCATEGORIESID
},
{
  PMRELATIONPROJECTLIST
},
{
  PMDELETECATEGORIES
},
{
  PMCATEGORIESLIST
},
{
  PMEDITCATEGORIES
},
{
  PMADDCATEGORIES
},
{
  PMPRONUM
},
{
  GANTTTASKDRAG
},
{
  GANTTLINKTYPESET
},
{
  GANTTLINKADD
},
{
  GANTTLINKDEL
},
{
  GANTTTASKLIST
},
{
  GETPRODETAILPAGE
},
{
  GETPROJECTLABEL
},
{
  GETPROANALYSEINFOPAGE
},
{
  GETPROANALYSEINFO
},
{
  GETPROANALYSEVIEW
},
{
  GETPROANALYSELIST
},
{
  DELETEPROFILE
},
{
  ADDPROFILE
},
{
  SETPROJECTADMIN
},
{
  PROJECTQUIT
},
{
  GETBROWSERPROJECT
},
{
  EDITROJECTENDDATE
},
{
  EDITROJECTSTATUS
},
{
  GETROJECTFILELIST
},
{
  ROJECTSTAR
},
{
  GETCLOSEPROJECT
},
{
  GETCOMPANYPROJECT
},
{
  GETMYPROJECT
},
{
  PROTASKTODAYFINISNCOUNT
},
{
  PROPEOPLELIST
},
{
  PROADDPEOPLENUMBER
},
{
  PROADDBYTEM
},
{
  PROTEMTYPE
},
{
  PROTEMDETAIL
},
{
  PROADD
},
{
  PRONAMEEDIT
},
{
  PRORECOVERY
},
{
  PROCLOSE
},
{
  PRODEL
},
{
  PROPCOMMENTLIST
},
{
  PROPROCESSDEL
},
{
  PROPROCESSEDIT
},
{
  PROPROCESSADD
},
{
  PROJECTPROCESSLIST
},
{
  PROJECTUPDATEPROCESS
},
{
  GETPRODESC
},
{
  PROJECTRECOMMENDLIST
},
{
  PROJECTLIST
},
{
  PROTEMLIST
},
{
  PROTASKCHARGEUSERUPDATE
},
{
  PROTASKSTATUSUPDATE
},
{
  PROTASKFIELDUPDATE
},
{
  PROTASKFIELDLIST
},
{
  PROTASKTABLELIST
},
{
  PROTASKDEL
},
{
  PROADDPEOPLEONE
},
{
  PROPEOPLEDEL
},
{
  PRODESCEDIT
},
{
  PROATTACHKRLIST
},
{
  PROCOPY
}
]
