// 根据环境变量确认baseurl
const isQw = window.location.host.indexOf('qywx') !== -1
const isQwPre = window.location.host.indexOf('qw.okrt.com') !== -1
let baseUrl = ''
if (isQw || isQwPre) {
  // 企业微信环境
  switch (process.env.NODE_ENV) {
    case 'dev':
      baseUrl = `https://qywx-dev.yuanmubiao.com` // 开发环境
      break
    case 'envtest':
      baseUrl = `https://qywx-test.yuanmubiao.com` // 测试环境
      break
    case 'production':
      // baseUrl = `https://qywx-dev.yuanmubiao.com` // 开发环境
      // baseUrl = `https://qywx-test.yuanmubiao.com` // 测试环境
      // baseUrl = `https://qywx-rel.yuanmubiao.com` // 预发布环境
      baseUrl = `https://qw.okrt.com` // 正式环境
      break
  }
} else {
  // SAAS和钉钉环境
  switch (process.env.NODE_ENV) {
    case 'dev':
      if (window.location.host.indexOf('dd') === -1) {
        baseUrl = `${window.location.protocol}//${window.location.host}/localproxy`
      } else {
        baseUrl = `${window.location.protocol}//${window.location.host}`
      }
      break
    case 'envtest':
      if (window.location.host.indexOf('dd') === -1) {
        baseUrl = `https://test.okrt.com`
        // baseUrl = 'http://dev.okrt.com' // 开发
      } else {
        baseUrl = `https://test-dd.okrt.com`
      }
      break
    case 'production':
      if (
        window.location.host.indexOf('dingtalkcloud') === -1 &&
        window.location.href.indexOf('dd.okrt.com') === -1
      ) {
        baseUrl = `https://app.okrt.com` // 正式
        // baseUrl = `https://test.okrt.com` // 测试
      } else {
        // baseUrl = `https://dd.okrt.com`
        baseUrl = `https://app114353.eapps.dingtalkcloud.com`
      }
      break
  }
}

export default baseUrl
