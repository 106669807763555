<template>
  <el-dialog
    title="转化为子任务"
    :visible.sync="isShow"
    :before-close="close"
    append-to-body
    :width="'760px'"
    @open="init"
  >
    <div class="search-wrap" slot="title">
      <div class="select-left" v-if="fromType === 'pmDetail'">
        <select-head fromType="copyTask" @change="refresh" />
      </div>
      <div class="search-right">
        复制到：
        <!-- <el-input
          placeholder="请输入任务标题 搜索任务"
          prefix-icon="el-icon-search"
          v-model="searchName"
          @keyup.enter.native="searchEnter"
        />-->
        <el-select
          v-model="projectId"
          placeholder="请选择项目"
          style="width: 160px;margin-right:16px;"
          @change="getProjectProcessList"
        >
          <el-option
            v-for="item in allProjectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <el-select v-model="processId" placeholder="请选择工作流" style="width: 160px">
          <el-option
            v-for="item in projectProcessList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
    <div :class="`main-wrap ${fromType === 'pmDetail'}`">
      <!-- v-vueloading="loading" -->
      <div class="task-list-wrap" v-scroll-bottom="loadMoreList" v-if="fromType === 'pmDetail'">
        <task-item
          v-for="(item, index) in taskList"
          :key="item.id + item.checked"
          :index="index"
          :item="item"
          @selectClick="clickChange"
        />
        <div v-if="taskList.length === 0 && !loading" class="no-content">
          <noData />
        </div>
      </div>
      <div class="bottom-step-buttons">
        <button @click="close" class="left-button">取消</button>
        <button @click="save">完成</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import taskItem from '@/views/conclusion/components/ConclusionDetail/taskItem.vue'
import selectHead from '@/views/pm/components/common/selectHead' // 右侧筛选
export default {
  components: { taskItem, selectHead },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    projectId: {
      default: ''
    },
    fromType: {
      default: 'pmDetail'
    },
    currentTaskList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      isShow: this.visible,
      searchName: '',
      loading: true,
      allProjectList: [],
      projectProcessList: [],
      processId: '',
      taskList: [],
      filterData: {
        status: '',
        keyword: '',
        pageNum: 1,
        pageSize: 20,
        dateSort: 6
      },
      getDataState: true,
      cooperateVuexList: ''
    }
  },
  computed: {},
  watch: {
    visible(val) {
      this.isShow = val
      this.loading = true
      if (!val) {
        this.currentTaskList = []
        this.taskList = []
      }
    }
  },
  created() {
  },
  mounted() {},
  methods: {
    save() {
      if (this.currentTaskList.length === 0) {
        this.$message.warn('请选择需要复制的任务')
        return
      } else if (!this.projectId) {
        this.$message.warn('请选择项目')
        return
      } else if (!this.processId) {
        this.$message.warn('请选择项目工作流')
        return
      }
      var taskIdList = []
      this.currentTaskList.forEach((item) => {
        taskIdList.push(item.id)
      })
      let obj_ = {
        taskIdList,
        projectId: this.projectId,
        processId: this.processId
      }
      this.$apis.PMPROJECTPROCESSCOPYTASK(obj_).then((res) => {
        if (res.status === 200) {
          this.$message.success('复制成功')
          const cooperateVuexList = this.$store.state.pm.cooperateVuexList
          if (this.projectId === cooperateVuexList.selectProjectData.list.id) { // 复制到当前项目再去刷新
            if (
              cooperateVuexList.tabSelectId === 1
            ) {
              // 列表刷新
              console.log('getProjectTaskList222')
              this.bus(this).$emit('getProjectTaskList') // 列表刷线
            }
            if (
              cooperateVuexList.tabSelectId === 2
            ) {
              var processIndex = 0
              this.projectProcessList.forEach((item, index) => {
                if (item.id === this.processId) {
                  processIndex = index
                }
              })
              // 看板刷新
              this.bus.$emit('global:refreshBoardColWorkList', {
                processId: this.processId,
                indexCol: processIndex
              })
            }
          }
          if (window.location.href.indexOf('/pmDetail') === -1) {
            this.bus(this).$emit('taskNeedRefresh')
          }
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
        this.close()
      })
    },
    // 获取项目工作流列表
    async getProjectProcessList() {
      if (this.projectId) {
        this.processId = ''
        let obj_ = {
          projectId: this.projectId
        }
        await this.$apis.PROJECTPROCESSLIST(obj_).then((res) => {
          if (res.status === 200) {
            this.projectProcessList = res.data
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
      }
    },
    init() {
      this.cooperateVuexList = JSON.parse(JSON.stringify(this.$store.state.pm.cooperateVuexList))
      this.queryProjectList()
      this.getProjectProcessList()
      if (this.fromType === 'pmDetail') {
        this.filterData.pageNum = 1
        this.getProjectTaskList(this.filterData.pageNum)
      }
    },
    refresh() {
      this.filterData.pageNum = 1
      this.getProjectTaskList(this.filterData.pageNum)
    },
    close() {
      this.visible = false
      this.$emit('change', false)
    },
    searchEnter() {
    },
    // 获取项目列表
    async queryProjectList() {
      this.$apis
        .KRATTACHALLPROJECTS()
        .then((res) => {
          if (res.status === 200) {
            this.allProjectList = res.data.filter((item) => {
              return item.status === 0 // 状态（0 正常 1 归档）
            })
            this.allProjectList = JSON.parse(JSON.stringify(this.allProjectList))
          } else {
            this.$message({
              msg: '网络错误,稍后重试!',
              type: 'error'
            })
          }
        })
        .catch(() => {
        })
    },
    loadMoreList() {
      if (this.taskList.length < this.taskTotal && !this.loading) {
        this.loading = true
        this.getProjectTaskList(this.filterData.pageNum)
      }
    },
    // 获取任务列表
    async getProjectTaskList(pageNum) {
      if ((this.cooperateVuexList.selectProjectId || '') > 0) {
        this.getDataState = false
        if (pageNum === 1) {
          this.pageNum = 1
        }
        let url_ = `/api/project/${this.versionNumber}/project/task/listE?projectId=${this.cooperateVuexList.selectProjectId}&pageNum=${pageNum}&pageSize=20`
        let dialogData = this.$store.state.pm.copyTaskSelectDialogList
        //角色
        let roleType = ''
        if (dialogData.roleOptionValue.length > 0) {
          dialogData.roleOptionValue.forEach((item, index) => {
            const { id, name, isShow, value } = item
            roleType += item + ','
          })
          roleType = roleType.substring(0, roleType.lastIndexOf(','))
        } else {
          roleType = null
        }
        //状态
        let status = ''
        if (dialogData.checkListValue.length > 0) {
          dialogData.checkListValue.forEach((item, index) => {
            status += item + ','
          })
          status = status.substring(0, status.lastIndexOf(','))
        } else {
          status = null
        }
        //完成情况
        let finishType = ''
        if (dialogData.completeValue === '') {
          finishType = null
        } else {
          finishType = parseInt(dialogData.completeValue)
        }
        //优先级
        let priority = ''
        if (dialogData.firstOptionValue.length > 0) {
          dialogData.firstOptionValue.forEach((item, index) => {
            priority += item + ','
          })
          priority = priority.substring(0, priority.lastIndexOf(','))
        } else {
          priority = null
        }
        let workOptionValue = 1
        // let workOptionValue = null
        // if (dialogData.workOptionValue === '仅父任务') {
        //   workOptionValue = 1
        // } else if (dialogData.workOptionValue === '仅子任务') {
        //   workOptionValue = 2
        // }
        var sort = ''
        if (this.sortItem && this.sort >= 0) {
          sort = `${this.sortItem.name}${this.sort === 0 ? 'Asc' : 'Desc'}`
        }
        let obj_ = {
          level: workOptionValue, //0 全部任务 1 仅父任务 2 仅子任务
          hide: dialogData.hide, //1隐藏已完成
          dateSort:
            dialogData.createTimerOptionValue === 0
              ? null
              : dialogData.createTimerOptionValue, //1 按照开始时间最晚 2 按照开始时间最近 3 按照截止时间最近 4按更新时间最晚 5按更新时间最近 6按创建日期最晚 7按创建日期最近
          roleType,
          startDate:
            dialogData.startTimeArithmeticOperator === 0
              ? null
              : dialogData.startTimeArithmeticOperator, //null 未设置 1 今天 2 今天以前 3 过去三天 4 过去15天 5过去30天 6明天 7未来3天 8 未来7天 9本周 10下周 11本月 12 上月
          endDate:
            dialogData.endTimeArithmeticOperator === 0
              ? null
              : dialogData.endTimeArithmeticOperator, //null 未设置 1 今天 2 今天以前 3 过去三天 4 过去15天 5过去30天 6明天 7未来3天 8 未来7天 9本周 10下周 11本月 12 上月
          status, //状态 按照英文','拼接起来 多选 1待处理 2进行中 3已完成
          finishType, //完成情况 1 未完成 2 已完成 3 逾期
          priority, //优先级 按照英文','拼接起来 多选 1 非常紧急 2 紧急 3普通
          sort
        }
        await this.$apis.PROTASKTABLELIST(url_, obj_).then((res) => {
          if (res.status === 200) {
            this.currentTaskList.forEach((selectItem) => {
              res.data.rows.forEach((item) => {
                if (item.id === selectItem.id) {
                  item.checked = true
                }
              })
            })
            if (this.filterData.pageNum === 1) {
              this.taskList = []
            }
            this.taskList = JSON.parse(JSON.stringify(this.taskList.concat(res.data.rows || [])))
            this.taskTotal = res.data.total || 0
            this.filterData.pageNum = ++this.filterData.pageNum
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
          this.loading = false
          this.getDataState = true
        })
      }
    },
    // 点击任务
    clickChange(index, item) {
      if (item.checked === true) {
        this.taskList[index].checked = false
        this.currentTaskList.forEach((list, listIndex) => {
          if (item.id === list.id) {
            this.currentTaskList.splice(listIndex, 1)
          }
        })
      } else {
        this.taskList[index].checked = true
        this.currentTaskList.push(item)
      }
      this.currentTaskList = this.$utils.distincts(this.currentTaskList)
      this.taskList[index] = JSON.parse(JSON.stringify(item))
      this.taskList = JSON.parse(JSON.stringify(this.taskList))
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
.bottom-step-buttons {
  margin-top: 32px;
}
.no-content {
  height: 100%;
}
::v-deep {
  .el-select.time-select {
    width: 145px !important;
  }
  .el-dialog__header {
    padding: 32px 24px 0 24px;
  }
  .table-top,
  .select-list,
  .el-select__caret {
    height: 40px !important;
    line-height: 40px !important;
  }

  .select-list {
    margin: 0px 16px 0px 0px !important;
    .el-input__inner,
    .select-btn {
      border: 1px solid #e7e8ef;
      padding: 0px 15px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
    }
    .select-btn {
      display: flex;
      align-items: center;
      padding: 0px 4px 0px 15px;
    }
  }
  .el-dialog__body {
    padding: 8px 24px 32px 24px;
  }
  .el-dialog__headerbtn {
    top: 35px !important;
  }
}
.search-wrap {
  color: #606266;
  .flex-l();
  .title {
    font-weight: bold;
    color: #14142b;
    font-size: 24px;
    line-height: 32px;
  }
  ::v-deep {
    .el-input--prefix {
      width: 220px;
    }
  }
}
.main-wrap {
  display: flex;
  flex-direction: column;
  .task-list-wrap {
    flex: 1;
    overflow-y: auto;
  }
}
.main-wrap.true {
  height: calc(70vh - 60px);
}
</style>

