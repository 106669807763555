<template>
  <div>
    <!-- 邀请对齐目标 -->
    <div class="align-title" v-if="type === 'align'">
      <wx-open-data :id="objData.userName" :nameData="{name:objData.userName}" />
      邀请你对齐 {{alignType === 'O' ? '目标' : '关键结果'}}
    </div>
    <div
      class="objective-show"
      :class="type === 'align' && step !== 3 ? 'align-objective-show' : ''"
    >
      <div v-if="(alignState && !alignHide) || (step === 3)" class="okr-top-align">
        <div class="up-line">
          <div class="align-icon-up">
            <Icon icon-class="icon-okrline-up" class="line-icon" />
          </div>
          <div class="down-left-line"></div>
        </div>
        <div class="align-content-wrap" v-if="alignState && !alignHide">
          <div
            v-if="fatherType === 'O' || fatherType === 'KR'"
            class="align-okr"
            :class="mouseenterState ? 'hover-align-okr' : ''"
          >
            <img
              class="align-avatar border-radius-20"
              :src="fatherNameObjList[0].list[0].chargeUser.avatar"
            />
            <div :class="`father-type father-type-${fatherType}`">{{fatherType}}</div>
            <el-tooltip
              :content="$utils.tagFilter(fatherType === 'KR' ? fatherNameObjList[0].list[0].childObjectivesAssociationList[0].title : fatherNameObjList[0].list[0].content)"
              :open-delay="300"
              placement="bottom"
            >
              <div
                class="align-content"
                :class="`align-content-${fatherType}-${fatherNameObjList[0].list[0].isVisible === 1}`"
                v-html="fatherType === 'KR' ? fatherNameObjList[0].list[0].childObjectivesAssociationList[0].title : fatherNameObjList[0].list[0].content"
              ></div>
            </el-tooltip>
            <el-tooltip placement="top" :open-delay="300" content="点击取消对齐">
              <div
                class="del-icon"
                @mouseenter="mouseenterState = true"
                @mouseleave="mouseenterState = false"
              >
                <i
                  class="iconfont ymbguanbianniu"
                  @click.stop="deleteTargetAligningSubmit(fatherNameObjList[0].list[0].childObjectivesAssociationList[0])"
                ></i>
              </div>
            </el-tooltip>
            <privateOkr
              :createUser="userInfo.id === fatherNameObjList[0].userId"
              :visible="fatherNameObjList[0].list[0].isVisible === 1"
              :content="fatherNameObjList[0].list[0].isVisible === 1 ? '对方已设置为不可见' : ''"
              :type="fatherType === 'KR' ? 2 : 1"
            />
          </div>
          <div class="father-align-list-wrap" v-if="fatherType === 'list'">
            <div class="align-list" v-for="item in fatherNameObjList" :key="item.userId">
              <align-member-card
                :is-okr="true"
                :userId="item.userId"
                :deleteAlign="true"
                :disabled="true"
                @toOKRList="getTopMembersFatherList(item)"
                @okrDetail="getTopMembersAlignmentSelect"
                @deleteAlign="deleteTargetAligningSubmit"
                :targetAligningList="item.list"
              >
                <div class="align-detail">
                  <div class="advar">
                    <el-image
                      :src="item.list[0].userAvatar"
                      fit="cover"
                      class="img border-radius-20"
                    />
                  </div>
                  <div class="name">
                    <wx-open-data
                      :id="item.list[0].userName"
                      :nameData="{name:item.list[0].userName}"
                    />
                  </div>
                  <div class="num" v-if="item.num>1">{{item.num}}</div>
                </div>
              </align-member-card>
            </div>
          </div>
          <!-- 多对齐修改 -->
          <!-- v-if="fatherType === ''" -->
          <div class="kr-add align-add">
            <target-aligning-new
              ref="targetAligning"
              :disabled="!objData.publicState"
              :target-aligning-list="targetAligningList"
              :target-aligning-show="targetAligningShow"
              type="add"
              @getTargetAligningClose="getTargetAligningClose"
              @getTargetAligningSubmit="getTargetAligningSubmit"
            >
              <div slot="guidepop" @click.stop="addAlign">
                <el-tooltip
                  content="可对齐其他人的目标，通常会对齐上级的目标，有助于团队目标一致，行动更聚焦"
                  :open-delay="300"
                  placement="bottom"
                >
                  <span>+ 对齐目标</span>
                </el-tooltip>
              </div>
            </target-aligning-new>
          </div>
        </div>
        <div class="align-content-wrap" v-else>
          <div class="align-okr">
            <img
              class="align-avatar border-radius-20"
              :src="alignObjData.chargeUser && alignObjData.chargeUser.avatar"
            />
            <div
              :class="`father-type father-type-${alignObjData.fatherType}`"
            >{{alignObjData.fatherType}}</div>
            <el-tooltip
              :content="$utils.tagFilter(alignObjData.fatherType === 'KR' ? alignObjData.title : alignObjData.content)"
              :open-delay="300"
              placement="bottom"
            >
              <div
                class="align-content"
                v-html="alignObjData.fatherType === 'KR' ? alignObjData.title : alignObjData.content"
              ></div>
            </el-tooltip>
            <privateOkr
              :createUser="userInfo.id === alignObjData.createUserid"
              :visible="alignObjData.typeIsVisible === 1"
              :content="alignObjData.typeIsVisible === 1 ? '对方已设置为不可见' : ''"
              :type="alignObjData.fatherType === 'KR' ? 2 : 1"
            />
          </div>
        </div>
      </div>
      <div class="okr-title" :class="`okr-title-${alignType}`">
        <div class="title-content">
          <img v-if="type === 'add'" class="okr-title-icon" src="@/assets/imgs/okr/objective.png" />
          <img
            v-else
            class="okr-title-icon avatar border-radius-20"
            :src="objData.userAvatar || objData.chargeUser.avatar"
          />
          <div class="okr-content">
            <AdviceInput
              :html="objData.content"
              :type="1"
              :ignore-rule-id-list="objData.ignoreRuleIdList"
              :edit-state="false"
              :advice-disable="type !== 'add'"
              :kr-length="objData.keyresultsList.length"
              @addIgnore="addIgnoreRule"
            />
            <privateOkr
              :createUser="userInfo.id === objData.createUserid"
              :visible="objData.isVisible === 1"
              :content="objData.isVisible === 1 ? '对方已设置为不可见' : ''"
              :type="1"
            />
          </div>
        </div>
        <div v-if="alignState">
          <confidence-select v-model="objData.confidence" :can-edit="true" :type="2" />
        </div>
        <div v-if="step == 3">
          <permission :item="objData.objectivesPower" @confirm="permissionConfirm" />
        </div>
      </div>
      <div class="kr-title-list">
        <draggable
          v-model="objData.keyresultsList"
          handle=".key_result_dragger_icon"
          :disabled="!(draggableStatus && isChangeUser)"
          @update="drop"
        >
          <div
            v-for="(keyResult, index) in objData.keyresultsList"
            :key="keyResult.index"
            class="kr-item"
            :class="`kr-item-${keyResult.alignState}`"
          >
            <div class="up-line" v-if="type !== 'align'">
              <div
                v-show="
              !(
                index === objData.keyresultsList.length - 1 &&
                (!editState || addKeyState)
              ) && index !== 9
            "
                class="down-left-line"
              ></div>
              <Icon icon-class="icon-okrline-down" class="line-icon" />
            </div>
            <div class="kr-title-box">
              <Icon
                v-if="draggableStatus && isChangeUser&&type !== 'align'"
                icon-class="icon-targetkrdragger"
                class="key_result_dragger_icon"
              />
              <div class="kr-index">KR{{ index + 1 }}</div>
              <div v-if="editKrIndex !== index" class="kr-title">
                <AdviceInput
                  :html="keyResult.title"
                  :type="2"
                  :ignore-rule-id-list="keyResult.ignoreRuleIdList"
                  :edit-state="false"
                  :advice-disable="type !== 'add'"
                  @addIgnore="addkrIgnoreRule($event, index)"
                  @clickOn="editKr(index)"
                />
                <privateOkr
                  :createUser="userInfo.id === objData.createUserid"
                  :visible="keyResult.isVisible === 1"
                  :content="keyResult.isVisible === 1 ? '对方已设置为不可见' : ''"
                  :type="2"
                />
              </div>
              <div v-else class="kr-title-input">
                <key-result-add
                  :is-edit="true"
                  :edit-info="keyResult"
                  :no-request="true"
                  @saveContent="saveContent($event, index)"
                  @cancel="cancel"
                />
              </div>
              <div class="kr-weight" v-if="type !== 'align'">
                <el-tooltip
                  v-if="editWeightIndex !== index"
                  content="点击调整权重"
                  :open-delay="300"
                  placement="bottom"
                  :disabled="
                step !== 3 || index === objData.keyresultsList.length - 1
              "
                >
                  <span v-if="editWeightIndex !== index" @click="editWeight(index)">
                    <Icon icon-class="icon-weight" class="icon-weight" />
                    <span class="weight-text">{{ keyResult.weight }}</span>
                  </span>
                </el-tooltip>
                <el-input-number
                  v-else
                  ref="inputNumberRef"
                  v-model="keyResult.weight"
                  :precision="0"
                  class="form-number"
                  :controls="false"
                  :min="0"
                  :max="100"
                  @blur="editWeightIndex = null"
                  @change="changeKrWeight($event, index)"
                />%
              </div>
              <div class="permission" v-if="step == 3">
                <permission
                  :item="keyResult.objectivesPower"
                  :type="2"
                  :index="index + 1"
                  @confirm="permissionConfirm"
                />
              </div>
              <div v-if="step === 2 && type === 'add'" class="close-box">
                <i class="iconfont ymbguanbianniu" @click="deleteKr(index)"></i>
              </div>
            </div>
          </div>
        </draggable>
        <div v-if="!addKeyState && editState && objData.keyresultsList.length < 10" class="kr-item">
          <div class="up-line">
            <div class="down-line-icon"></div>
            <Icon icon-class="icon-okrline-down" class="line-icon" />
          </div>
          <div class="kr-title-box add-result">
            <div class="kr-index">KR{{ objData.keyresultsList.length + 1 }}</div>
            <div class="kr-add" @click="addKeyResult">+ 添加关键结果</div>
          </div>
        </div>

        <div v-if="addKeyState" class="key-result-input">
          <key-result-add
            :no-request="true"
            @saveContent="saveContent($event, 'add')"
            @cancel="cancel"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import targetAligningNew from './targetAligningNew/targetAligningNew'
import confidenceSelect from './singleOkrItem/confidenceSelect.vue'
import keyResultAdd from './singleOkrItem/keyResultsAdd.vue'
import draggable from 'vuedraggable'
import AdviceInput from './AdviceInput'
import permission from '@/views/okr/dialog/okrPermission/permission.vue'
import privateOkr from '@/views/okr/components/PrivateOkr'
export default {
  components: {
    targetAligningNew,
    confidenceSelect,
    keyResultAdd,
    draggable,
    AdviceInput,
    permission,
    privateOkr
  },
  props: {
    objData: {
      type: Object,
      default: () => {
        return {
          content: ''
        }
      }
    },
    alignObjData: {
      type: Object,
      default: () => {
        return {
          content: ''
        }
      }
    },
    type: {
      // add新增 align对齐的目标
      type: String,
      default: 'add'
    },
    editState: {
      // 是否可编辑
      type: Boolean
    },
    alignState: {
      // 是否可对齐
      type: Boolean
    },
    alignHide: {
      // 对齐是否隐藏 true 隐藏  false 显示
      type: Boolean
    },
    step: {
      type: Number
    },
    alignType: {
      default: 'O'
    }
  },
  watch: {
    step: function (val) {
      if (this.step === 3) {
        this.getPermissionList()
      }
    }
  },
  data() {
    return {
      targetAligningShow: false,
      addKeyState: false,
      editKrIndex: '', // 正在编辑的kr
      editWeightIndex: '', // 正在编辑的权重
      targetAligningList: [], // 对齐目标列表
      mouseenterState: false,
      draggableStatus: true, // 是否能够拖拽
      permissionList: [],
      fatherType: '',
      fatherNameObjList: []
    }
  },
  computed: {
    isChangeUser() {
      if (this.type === 'add') {
        return true
      } else {
        return this.userInfo.id === this.objData.chargeUserId
      }
    }
  },
  methods: {
    // 添加kr忽略
    addkrIgnoreRule(id, kr) {
      this.$emit('ignorekr', id, kr)
    },
    drop() {},
    addAlign() {
      if (!this.objData.publicState) {
        this.$message({
          msg: '目标已设为私密，不能添加对齐目标哦',
          type: 'warn'
        })
      } else {
        this.targetAligningShow = true
      }
    },
    // 弹框对齐显示内容
    getTargetAligningClose() {
      this.mouseenterState = false
      this.targetAligningShow = false
      // 多对齐修改
      this.$refs.targetAligning.getTargetAligningClose()
    },
    // 删除对齐
    deleteTargetAligningSubmit(data, state) {
      this.targetAligningList.forEach((objItem, objIndex) => {
        objItem.childObjectivesAssociationList.forEach((selectItem, selectItemIndex) => {
          if (selectItem.businessId === data.businessId) {
            objItem.childObjectivesAssociationList.splice(selectItemIndex, 1)
          }
        })
        if ((objItem.childObjectivesAssociationList || []).length === 0) {
          this.targetAligningList.splice(objIndex, 1)
        }
      })
      this.getTargetAligningSubmit(this.targetAligningList)
    },
    // 对齐返回内容显示
    getTargetAligningSubmit(data) {
      this.targetAligningList = data
      this.getFatherNameObjList(data)
      this.$emit('changeAlignData', data)
      this.getTargetAligningClose()
    },
    getFatherNameObjList(data) {
      var fatherType = 'O'
      var fatherAlignTotal = 0
      var fatherObjectivesAssociationObjectivesList = data
      var fatherNameObjList = [] // 存放目标数据
      var fatherNameList = {} // 存放用户目标数据是否创建
      if ((fatherObjectivesAssociationObjectivesList || []).length === 0) {
        this.fatherType = ''
      } else {
        fatherObjectivesAssociationObjectivesList.forEach((oItem) => {
          fatherAlignTotal += (oItem.childObjectivesAssociationList || []).length
          if (oItem.childObjectivesAssociationList[0].businessType === 1) {
            fatherType = 'KR'
          }
          if (fatherNameList[oItem.chargeUserId]) { // 已创建
            fatherNameObjList.forEach((objItem) => {
              if (objItem.userId === oItem.chargeUserId) {
                objItem.num = (objItem.num += (oItem.childObjectivesAssociationList || []).length)
                objItem.list.push(oItem)
              }
            })
          } else {
            fatherNameList[oItem.chargeUserId] = oItem.chargeUserId
            fatherNameObjList.push({
              userId: oItem.chargeUserId,
              userName: oItem.userName,
              num: (oItem.childObjectivesAssociationList || []).length,
              list: [oItem]
            })
          }
        })
        if (fatherAlignTotal > 1) {
          fatherType = 'list'
        }
        this.fatherType = fatherType
        this.fatherNameObjList = fatherNameObjList
        console.log('fatherNameObjList', this.fatherNameObjList)
      }
    },
    // 保存关键结果
    saveContent(data, index) {
      if (data.obj.html.trim() === '') {
        this.$message({
          msg: '关键结果不能为空！',
          type: 'warn'
        })
        return
      }
      this.$emit('keyResultChange', data, index)
      this.editKrIndex = ''
      this.addKeyState = false
    },
    cancel() {
      this.editKrIndex = ''
      this.addKeyState = false
    },
    deleteKr(index) {
      this.$emit('deleteKr', index)
    },
    changeKrWeight(data, index) {
      this.$emit('changeKrWeight', data, index)
    },
    editKr(index) {
      const sel = this.$utils.getSelectedClick()
      if (sel) {
        return
      }
      if (this.editState) {
        this.editKrIndex = index
      }
    },
    editWeight(index) {
      if (this.step === 3) {
        if (index === this.objData.keyresultsList.length - 1) {
          this.$message({
            msg: '最后一个关键结果需要调整前面的进行编辑',
            type: 'warn'
          })
          return
        }
        this.editWeightIndex = index
        this.$refs.inputNumberRef.blur()
      }
    },
    addKeyResult() {
      this.addKeyState = true
    },
    addIgnoreRule(id) {
      this.$emit('addIgnoreRule', id)
    },
    getPermissionList() {
      var list = []
      list.push(this.objData.objectivesPower)
      this.objData.keyresultsList.forEach((item) => {
        list.push(item.objectivesPower)
      })
      this.permissionList = list
    },
    permissionConfirm(data) {
      this.permissionList[data.index].lookRulesType = data.lookRulesType
      this.permissionList[data.index].objectivesPowerCustomizeList =
        data.objectivesPowerCustomizeList
      if (data.businessType === '1') {
        // okr
        this.objData.objectivesPower.lookRulesType = data.lookRulesType
        this.objData.objectivesPower.objectivesPowerCustomizeList =
          data.objectivesPowerCustomizeList
      } else {
        this.objData.objectivesPower.keyresultsList[
          data.index
        ].objectivesPower.lookRulesType = data.lookRulesType
        this.objData.keyresultsList[
          data.index
        ].objectivesPower.objectivesPowerCustomizeList =
          data.objectivesPowerCustomizeList
      }
    }
  }
}
</script>
<style lang="less" scoped>
.align-content-O-true {
  min-width: 300px;
}
.align-content-KR-true {
  min-width: 300px;
}
.flur-wrap {
  min-width: 200px;
}
.okr-top-align {
  .flur-wrap-2 {
    left: 80px;
    right: 20px;
    width: auto;
  }
  .flur-wrap-1 {
    left: 70px;
    right: 20px;
    width: auto;
  }
}
.father-align-list-wrap {
  .flex-l();
  flex-wrap: wrap;
  .align-list {
    padding: 4px;
    border-radius: 40px;
    margin: 0px;
    &:hover {
      background: #f5f6fa;
    }
  }
  .align-list,
  .align-detail {
    .flex-l();
    margin-right: 8px;
    cursor: pointer;
    .advar {
      width: 32px;
      height: 32px;
    }
    .img {
      width: 32px;
      height: 32px;
      border-radius: 16px;
    }
    .name {
      color: #6e7491;
      max-width: 60px;
      .text-n(1);
      margin-left: 4px;
    }
    .num {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 12px;
      background: #f5f6fa;
      margin-left: 4px;
      font-weight: bold;
      color: #444558;
    }
  }
}
.align-title {
  font-size: 16px;
  font-weight: bold;
  color: #14142b;
  line-height: 16px;
  margin-bottom: 16px;
}
::v-deep .permission-select {
  max-height: 130px !important;
  overflow-y: auto;
}
.objective-show {
  .okr-top-align {
    display: flex;
    font-size: 16px;
    .align-content-wrap {
      .flex-l();
    }
    .up-line {
      margin-top: 20px;
      flex-direction: column;
      .down-left-line {
        flex: 1;
        margin-top: -3px;
      }
    }
    .align-okr {
      display: flex;
      align-items: center;
      padding: 4px;
      border-radius: 40px;
      position: relative;
      border: 1px solid #fff !important;
      margin-right: 8px;
      .align-avatar {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 8px;
      }
      .father-type {
        min-width: 24px;
        height: 24px;
        line-height: 24px;
        font-weight: 500;
        color: #fff;
        background: #548af7;
        text-align: center;
        border-radius: 12px;
        font-size: 12px;
        margin-right: 8px;
      }
      .father-type-KR {
        padding: 0px 8px;
        color: #548af7;
        background: #e7effe;
      }
      .align-name {
        font-size: 14px;
        margin-right: 16px;
        max-width: 68px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:before {
          content: '';
          display: block;
        }
      }
      .align-content {
        max-width: 590px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:before {
          content: '';
          display: block;
        }
      }
      &:hover {
        background: #f5f6fa;
        .del-icon {
          cursor: pointer;
          display: block;
        }
      }
      .del-icon {
        display: none;
        position: absolute;
        top: -6px;
        right: -3px;
        font-size: 18px;
        border-radius: 100% 100%;
        background: #fff;
        color: #a0a3bd;
        &:hover {
          color: #ff9900;
        }
      }
    }
    .hover-align-okr {
      border: 1px solid #ff9900 !important;
      background: #fff9ef !important;
    }
  }
  .okr-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #14142b;
    font-size: 16px;
    font-weight: 700;
    padding: 4px 8px 4px 0;
    .title-content {
      display: flex;
      line-height: 20px;
      align-items: center;
      flex: 1;
      min-width: 0;
      .okr-content {
        position: relative;
        padding: 6px 0px;
      }
    }
    .okr-title-icon {
      width: 36px;
      flex-shrink: 0;
      margin-right: 16px;
    }
    .avatar {
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
    }
    .confidence_select {
      margin: 0px 8px 0px 0px;
    }
  }
  .kr-add {
    color: #ff9900;
    cursor: pointer;
    line-height: 24px;
    padding: 4px 0 4px 8px;
    min-width: 90px;
    &:hover {
      color: #ffd290;
    }
  }
  .kr-title-list {
    font-size: 14px;
    .kr-item {
      display: flex;
      color: #444558;
      .kr-index {
        flex-shrink: 0;
        color: #a0a3bd;
        font-weight: 700;
        width: 46px;
        line-height: 32px;
        padding-left: 8px;
      }
      .kr-title-box {
        min-height: 34px;
        display: flex;
        width: 750px;
        justify-content: space-between;
        padding: 4px 8px 4px 0;
        margin-top: 4px;
        align-items: flex-start;
        .key_result_dragger_icon {
          margin-left: 6px;
          visibility: hidden;
          font-size: 20px;
          color: #a0a3bd;
          height: 32px;
        }
        i {
          line-height: 28px;
          color: #a0a3bd;
          display: none;
          cursor: pointer;
          font-size: 16px;
        }
        &:hover {
          background: #f5f6fa;
          border-radius: 8px;
          .key_result_dragger_icon {
            visibility: initial;
          }
          i {
            display: block;
            &:hover {
              color: #005eff;
            }
          }
        }
      }
      .add-result {
        justify-content: flex-start !important;
        padding-left: 26px;
      }
      .kr-title {
        flex: 1;
        line-height: 24px;
        padding: 4px 0;
        position: relative;
        .flur-wrap {
          right: 16px;
          left: 0px;
          width: auto;
        }
        // margin-top: 12px;
      }
      .kr-title-input {
        flex: 1;
        padding: 4px 0;
      }
      .kr-weight {
        height: 30px;
        line-height: 30px;
        margin-right: 8px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .icon-weight {
            margin-right: 8px;
            color: #a0a3bd;
          }
          .weight-text {
            white-space: nowrap;
          }
        }
      }
      .permission {
        height: 30px;
        line-height: 30px;
      }

      ::v-deep .kr-weight {
        .el-input.is-active .el-input__inner,
        .el-input__inner:focus {
          border-color: #3a78f6 !important;
          box-shadow: 0px -3px 10px 0px rgba(50, 150, 250, 0.14) inset;
        }
        .el-input__inner {
          font-size: 14px;
          width: 42px;
          height: 36px;
          line-height: 36px;
          padding: 0 4px !important;
          border-radius: 8px;
          border-color: #f5f6fa;
        }
      }
      .close-box {
        width: 24px;
      }
    }
    .key-result-input {
      width: 750px;
      margin: 16px 0 0 48px;
    }
  }
  .up-line {
    display: flex;
    align-items: flex-start;
    .line-icon {
      font-size: 35px;
      color: #d9dbe9;
      margin-right: 8px;
    }
    .down-left-line {
      height: 100%;
      width: 1px;
      background: #d9dbe9;
    }
    margin-left: 23px;
    .align-content-wrap {
      .flex-l();
      flex: 1;
      min-width: 0;
    }
  }
  .down-line {
    i {
      font-size: 25px;
    }
    width: 25px;
  }
}
.align-objective-show {
  border: 1px solid #f5f6fa;
  padding: 16px;
  border-radius: 16px;
  .kr-title-list {
    margin-left: 60px;
  }
  .okr-title-KR {
    opacity: 0.54;
  }
  .kr-item-true {
    opacity: 1 !important;
  }
  .okr-title-icon {
    margin-left: 8px;
  }
  .kr-title-list {
    .kr-item {
      opacity: 0.54;
      .kr-index {
        font-weight: 500;
        color: #3a70ff;
        height: 24px;
        line-height: 24px;
        background: rgba(58, 120, 246, 0.08);
        border-radius: 12px;
        padding: 0px;
        text-align: center;
        margin-right: 14px;
      }
      .kr-title-box {
        align-items: center;
        &:hover {
          background: #fff;
        }
      }
    }
  }
}
</style>
