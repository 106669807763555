const versionNumber = 'v1' // 版本
const versionNumber2 = 'v2' // 版本
// 会议
export default [
  {
  // 会议列表
  meetingList: `/api/meeting/${versionNumber}/meeting`
},
{
  // 会议开启跳过
  meetingSkipClose: `/api/meeting/${versionNumber}/meeting/info/`
},
{
  // 会议设置
  meetingSetting: `/api/meeting/${versionNumber}/meeting/info/remind`
},
{
  // 会议变更参会人员
  meetingUserUpdate: `/api/meeting/${versionNumber}/meeting/user/`
},
{
  // 会议周期列表
  meetingCycleList: `/api/ranloWeb/${versionNumber}/setcycle/meeting/list/info/list`
},
{
  // 获取会议信息
  meetingInfo: `/api/meeting/${versionNumber}/meeting/info`
},
{
  // 获取会议概述/附件
  meetingContent: `/api/meeting/${versionNumber}/meeting/info/content`
},
{
  // 获取综合目标列表 (当前)
  targetData: `/api/meeting/${versionNumber}/meeting/info/objectives/list/comprehensive`
},
{
  // 获取综合目标列表 (历史)
  targetDataHistory: `/api/meeting/${versionNumber}/meeting/info/objectives/list/history/comprehensive`
},
{
  // 获取个人目标列表 (当前)
  userTargetData: `/api/meeting/${versionNumber}/meeting/info/objectives/list/member`
},
{
  // 获取个人目标列表
  userTargetDataHistory: `/api/meeting/${versionNumber}/meeting/info/objectives/list/history/member`
},
{
  // 获取目标概览数据
  targetChartData: `/api/analysis/${versionNumber}/report/meetingInfo/objectives/department`
},
{
  // 获取任务概览数据
  taskChartData: `/api/analysis/${versionNumber}/report/meetingInfo/task/department`
},
{
  // 任务燃尽图
  taskEchart: `/api/analysis/${versionNumber}/report/meetingInfo/task/burnout`
},
{
  // 上期进度与本期进度对比
  progressEchart: `/api/analysis/${versionNumber}/report/meetingInfo/progress/contrast`
},
{
  // 获取综合数据概览
  allTargetData: `/api/analysis/${versionNumber}/report/meetingInfo/replay/overall`
},
{
  // 获取目标进度趋势图
  targetTrendChartData: `/api/analysis/${versionNumber}/report/meetingInfo/replay/targetTrend`
},
{
  // 获取总结详情
  summaryDetails: `/api/ranloWeb/${versionNumber}/summary/`
},
{
  // 获取任务列表
  taskList: `/api/ranloTask/${versionNumber}/task/getTaskListByKeyId`
},
{
  // 获取已读人员列表
  readUserList: `/api/meeting/${versionNumber}/meeting/info/attendstatus/list`
},
{
  // 获取问题详情
  problemInfo: `/api/meeting/${versionNumber}/meeting/info/question/info`
},
{
  // 获取问题建议
  problemAdviseList: `/api/meeting/${versionNumber}/meeting/info/question/comment/list`
},

{
  // 提交问题建议
  submitProblemAdvise: `/api/meeting/${versionNumber}/meeting/info/question/comment/`
},
{
  // 会议问题采纳建议
  adoptAdvise: `/api/meeting/${versionNumber}/meeting/info/question/comment/adopt`
},
{
  // 提交会议自述
  submitSelfAccount: `/api/meeting/${versionNumber}/meeting/info/content/`
},
{
  // 添加会议问题
  addQuestion: `/api/meeting/${versionNumber}/meeting/info/question/`
},
{
  // 会议问题列表（上期）
  oldProblemList: `/api/meeting/${versionNumber}/meeting/info/question/last`
},
{
  // 会议问题列表（本期）
  newProblemList: `/api/meeting/${versionNumber}/meeting/info/question/current`
},
{
  // 获取问题列表（未解决）
  unsolvedList: `/api/meeting/${versionNumber}/meeting/info/question/unsolved`
},
{
  // 保存会议纪要
  saveRecord: `/api/meeting/${versionNumber}/meeting/info/record/save`
},
{
  // 提交会议纪要
  submitRecord: `/api/meeting/${versionNumber}/meeting/info/record/commit`
},
{
  // 获取会议纪要详情
  minutesDetails: `/api/meeting/${versionNumber}/meeting/info/record/info`
},
{
  // 结束会议
  closeMeeting: `/api/meeting/${versionNumber}/meeting/info/close`
},
{
  // 会议详情
  meetingInfo: `/api/meeting/${versionNumber}/meeting/info`
},
{
  // 获取会议纪要列表
  minutesList: `/api/meeting/${versionNumber}/meeting/info/record/list`
},
{
  // 会议概述
  meetingInfoContent: `/api/meeting/${versionNumber}/meeting/info/content`
},
{
  // 获取周期内okr列表
  cycleOkrList: `/api/ranloWeb/${versionNumber}/objectives/meeting/cycle/`
},
{
  // 添加目标提交
  meetingAddTarget: `/api/meeting/${versionNumber}/meeting/info/objectives/save`
},
{
  // 获取okr主目标列表
  meetingMainOkrList: `/api/meeting/${versionNumber}/meeting/info/group/okr/main`
},
{
  // 获取上级目标列表
  meetingLeaderOkrList: `/api/meeting/${versionNumber}/meeting/info/group/okr/leader`
},
{
  // 分组查询目标列表
  groupOkrList: `/api/meeting/${versionNumber}/meeting/info/group/okr/group/obj`
},
{
  // 分组查询目标kr列表
  groupOkrKrList: `/api/meeting/${versionNumber}/meeting/info/group/okr/group/kr`
},
{
  // 删除目标
  meetingOkrDel: `/api/meeting/${versionNumber}/meeting/info/objectives/delete`
},
{
  // 开始关键结果讨论
  openDiscussion: `/api/meeting/${versionNumber}/meeting/info/discussion/`
},
{
  // 结束会议
  meetingClose: `/api/meeting/${versionNumber}/meeting/info/close`
},
{
  // 同步至OKR
  synchronizeOkr: `/api/meeting/${versionNumber}/meeting/info/objectives/synchronize/okr`
},
{
  // 设置分组
  groupSet: `/api/meeting/${versionNumber}/meeting/attendgroup/set`
},
{
  // 修改目标提交
  meetingOkrUpdate: `/api/meeting/${versionNumber}/meeting/info/objectives/update`
},
{
  // 采纳目标
  meetingOkrAdopt: `/api/meeting/${versionNumber}/meeting/info/objectives/adoption`
},
{
  // 催一下
  meetingOkrUrge: `/api/meeting/${versionNumber}/meeting/attendgroup/urge`
},
{
  // 编辑关键结果
  meetingKeyresultUpdate: `/api/meeting/${versionNumber}/meeting/info/keyresults/update`
},
{
  // 删除关键结果
  meetingKeyresultDelete: `/api/meeting/${versionNumber}/meeting/info/keyresults/delete/`
},
{
  // 采纳关键结果
  meetingKeyresultAdopt: `/api/meeting/${versionNumber}/meeting/info/keyresults/adoption`
},
{
  // 添加关键结果
  meetingKeyresultAdd: `/api/meeting/${versionNumber}/meeting/info/keyresults/save`
},
{
  // 修改量化条件
  meetingKeyresultQuantification: `/api/ranloWeb/${versionNumber}/keyresults/update/quantification`
},
{
  // 修改信心值
  meetingConfidence: `/api/meeting/${versionNumber}/meeting/info/objectives/update/confidence`
},
{
  // 修改权重
  meetingKeyresultWeight: `/api/meeting/${versionNumber}/meeting/info/keyresults/update/weight`
},
{
  // 关键结果排序
  meetingKeyresultSort: `/api/meeting/${versionNumber}/meeting/info/keyresults/update/sort`
},
{
  // 达成共识
  meetingOkrConsensus: `/api/meeting/${versionNumber}/meeting/info/objectives/update/consensus`
},
{
  // 忽略目标
  meetingOkrHide: `/api/meeting/${versionNumber}/meeting/info/objectives/hide`
},
{
  // 获取okr子目标列表
  meetingOkrFather: `/api/ranloWeb/${versionNumber}/meeting/info/objectives/father/`
},
{
  // 获取导入的总结列表
  selSummaryList: `/api/meeting/${versionNumber}/meeting/info/summary/page/list`
},
{
  // 导入总结
  saveSummaryList: `/api/meeting/${versionNumber}/meeting/info/summary/save`
},
{
  // 获取总结列表
  summaryList: `/api/ranloWeb/${versionNumber}/summarize/meeting/page/list/user`
},
{
  // 获取已导入列表
  summarySelList: `/api/meeting/${versionNumber}/meeting/info/summary/list/summary/id`
},
{
  // 删除导入总结
  delSummaryList: `/api/meeting/${versionNumber}/meeting/info/summary/`
},
{
  // 删除文件
  fileDelete: `/api/meeting/${versionNumber}/meeting/info/document/`
},
{
  meetingOkrFather: `/api/meeting/${versionNumber}/meeting/info/objectives/father/`
},
{
  // 获取进推进会列表
  meetingProcessList: `/api/meeting/${versionNumber}/meeting/info/list`
},
{
  // 获取我的问题列表
  meetingMyQuestionList: `/api/meeting/${versionNumber}/meeting/info/question/list`
},
{
  // 回复评论
  progressNewReplayComment: `/api/meeting/${versionNumber}/meeting/info/user/content/comment/answer`
},
{
  // 添加评论
  progressNewAddComment: `/api/meeting/${versionNumber}/meeting/info/user/content/comment/save`
},
{
  // 添加工作说明/问题
  progressNewAddExplainSolution: `/api/meeting/${versionNumber}/meeting/info/user/content/replenish`
},
{
  // 隐藏/展现 字段
  progressNewChangePermission: `/api/meeting/${versionNumber}/meeting/info/user/content/permission`
},
{
  // 添加会议人员会议记录数据
  progressNewSaveUserContent: `/api/meeting/${versionNumber}/meeting/info/user/content/save`
},
{
  // 查询推进会的综合目标列表
  progressNewGetComprehensive: `/api/meeting/${versionNumber2}/meeting/info/objectives/list/comprehensive`
},
{
  // 查询会议人员记录内容
  progressNewGetUserContent: `/api/meeting/${versionNumber}/meeting/info/user/content/content`
},
{
  // 分页查询评论列表
  progressNewGetCommentContent: `/api/meeting/${versionNumber}/meeting/info/user/content/comment/page/list`
},
{
  // 分页查询评论列表
  progressNewDeleteComment: `/api/meeting/${versionNumber}/meeting/info/user/content/comment/delete/`
},
{
  // 删除问题解决方案
  progressNewDeleteSolution: `/api/meeting/${versionNumber}/meeting/info/user/content/solution/`
},
{
  // 删除工作说明/问题
  progressNewDeleteExplainSolution: `/api/meeting/${versionNumber}/meeting/info/user/content/replenish/delete`
},
{
  // 添加问题解决方案
  progressNewAddSolution: `/api/meeting/${versionNumber}/meeting/info/user/content/solution`
},
{
  // 修改会议记录模式
  progressNewEditType: `/api/meeting/${versionNumber}/meeting/info/user/content/type`
},
{
  // 修改进度会主目标id
  progressNewEditHostObj: `/api/meeting/${versionNumber}/meeting/info/host/obj`
}
]
