const userPopState = JSON.parse(localStorage.getItem('userPopState')) // 引导气泡
const unlockPopoverVisible = JSON.parse(
  localStorage.getItem('unlockPopoverVisible')
) // 引导气泡
const state = {
  // 周期信息
  cycle: {
    quarterId: localStorage.getItem('quarterId'), // 大周期id
    periodId: localStorage.getItem('periodId') // 小周期id
  },
  // 异常页
  apiRequestData: {
    visible: false,
    type: 'activity',
    companyName: '',
    list: {
      expireDate: '',
      grantCount: ''
    }
  },
  userPopState: userPopState || {
    align: false, // 对齐目标
    checkNext: false, // 查看下一级
    comment: false, // 动态和评论
    checkTarget: false, // 查看下级目标
    example: false, // 示例
    confidence: false, // 信心值
    task: false, // KR任务
    keyResult: false, // 关联KR
    edit: false, // 编辑视图
    addTarget: false, // 插入目标
    changeView: false, // 视图切换 暂无
    editList: false, // 编辑列表字段 暂无
    addWorking: false, // 添加工作流 暂无
    newData: false, // 新建数据
    upgrade: false // 到期升级
  },
  // 存储未提交的任务信息
  taskAddObject: {
    default: '0',
    taskCustom: {}
  }, // 用户添加任务信息暂存
  companyCalendarInfo: '',
  currentVersion: '3.7',
  okrCycleList: {},
  scrollBgVisible: false,
  commonFromType: 'saas',
  commonCorpId: '',
  progressCommentClick: '',
  videoPopoverVisible: false,
  unlockPopoverVisible: unlockPopoverVisible || true,
  kpiCycleTypeList: {},
  userStateList: {
    0: '待激活',
    1: '正常',
    2: '停用',
    4: '离职'
  }
}
const actions = {}
const mutations = {
  // 存储钉钉CorpId
  setCommonCorpId(state, info) {
    state.commonCorpId = info
  },
  // 存储钉钉sass
  setCommonFromType(state, info) {
    state.commonFromType = info
  },
  // 派发大小周期
  setCycleInfo(state, cycleinfo) {
    localStorage.setItem('quarterId', cycleinfo.quarterId)
    localStorage.setItem('periodId', cycleinfo.periodId)
    state.cycle.quarterId = cycleinfo.quarterId
    state.cycle.periodId = cycleinfo.periodId
  },
  // 派发大周期
  setQuarterId(state, quarterId) {
    localStorage.setItem('quarterId', quarterId)
    state.cycle.quarterId = quarterId
  },
  // 派发小周期
  setPeriodId(state, periodId) {
    localStorage.setItem('periodId', periodId)
    state.cycle.periodId = periodId
  },
  // api异常
  apiRequestData(state, val) {
    state.apiRequestData = val
  },
  // 已显示过气泡
  changeUserPopState(state, popKey) {
    if (popKey) {
      // 用户信息
      state.userPopState[popKey] = true
      localStorage.setItem('userPopState', JSON.stringify(state.userPopState))
    }
  },
  // 未显示气泡（有需要升级的每次登录都显示气泡）
  showUserPopState(state, popKey) {
    state.userPopState[popKey] = false
    localStorage.setItem('userPopState', JSON.stringify(state.userPopState))
  },
  // 用户添加任务信息暂存
  saveTaskAddObject(state, obj) {
    state.taskAddObject = obj
  },
  // 企业日历信息
  saveCompanyCalendar(state, obj) {
    state.companyCalendarInfo = obj
  },
  // 保存大小周期
  setOkrCycleList(state, obj) {
    state.okrCycleList = obj
  },
  // 是否有背景禁止操作
  setScrollBgVisible(state, obj) {
    state.scrollBgVisible = obj
    var objDom = document.getElementsByClassName('global-bg-style')
    if (!obj && objDom.length > 0) {
      objDom[0].style.zIndex = '0'
    }
  },
  // 保存推进会评价点击数据
  setProgressCommentClick(state, data) {
    state.progressCommentClick = data
  },
  // 是否有背景禁止操作
  setVideoPopoverVisible(state, obj) {
    state.videoPopoverVisible = obj
  },
  // 是否展示训练营邀请弹框
  setUnlockPopoverVisible(state, obj) {
    state.unlockPopoverVisible = obj
    localStorage.setItem(
      'unlockPopoverVisible',
      JSON.stringify(state.unlockPopoverVisible)
    )
  },
  // 绩效周期
  setKpiCycleTypeList(state, obj) {
    state.kpiCycleTypeList = obj
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
