// 时间筛选  自定义显示样式 文案   任务时间显示
// params => '2019-04-02 17:11:55' OR 1554257391000 (毫秒) 只需调用此函数 page:1 列表任务

function formatRecentTime(params, types_, page) {
  let date = ''
  if ((params || '') === '') {
    return ''
  }
  if (isNaN(params)) {
    // 判断是否是时间戳
    date = params.toString()
  } else {
    date = timeFormat(params)
  }
  date = date.split('-').join('/')
  var getDay = new Date().getDay() // 0 周日
  if (getDay === 0) {
    getDay = 7
  }
  var getDayWeek = as(new Date(date).getDay(), date, 1) // 获取周几
  var beforeWeek = -getDay
  beforeWeek = beforeWeek - 6
  var nextWeek = 7 - getDay
  nextWeek += 7
  // 时间戳
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)
  const hour = date.substring(11, 13)
  const minute = date.substring(14, 16)
  if (types_ === 'detail') {
    return `${year}年${month}月${day}日 ${hour}:${minute}`
  } else if (currentDay(date, 4) > -3 && currentDay(date, 4) < 3) {
    // 前后3天内
    var today = '今天'
    if (currentDay(date, 4) > 1) {
      today = '后天'
    } else if (currentDay(date, 4) > 0) {
      today = '明天'
    } else if (currentDay(date, 4) > -1) {
      today = '今天'
    } else if (currentDay(date, 4) > -2) {
      today = '昨天'
    } else if (currentDay(date, 4) > -3) {
      today = '前天'
    }
    return `${today} ${hour}:${minute}`
  } else if (
    currentDay(date, 4) >= beforeWeek &&
    currentDay(date, 4) <= nextWeek
  ) {
    // 前后一周内
    var week = ''
    console.log(
      '前后一周内',
      getDayWeek,
      currentDay(date, 4),
      beforeWeek,
      nextWeek
    )
    if (currentDay(date, 4) >= beforeWeek && currentDay(date, 4) < -getDay) {
      week = '上'
    } else if (
      currentDay(date, 4) <= nextWeek &&
      currentDay(date, 4) > 7 - getDay
    ) {
      week = '下'
    }
    return `${week}${getDayWeek} ${hour}:${minute}`
  } else if (parseInt(year) !== new Date().getFullYear()) {
    // 不是今年
    return `${year}年${month}月${day}日`
  } else {
    return `${month}月${day}日 ${hour}:${minute}`
  }
}
// 全局时间规则
function globalTime(params) {
  let date = ''
  if ((params || '') === '') {
    return ''
  }
  if (isNaN(params)) {
    // 判断是否是时间戳
    date = params.toString()
  } else {
    date = timeFormat(params)
  }
  date = date.split('-').join('/')
  // 时间戳
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)
  const hour = date.substring(11, 13)
  const minute = date.substring(14, 16)
  if (currentDay(date, 1) >= -10) {
    // 10秒内
    return '刚刚'
  } else if (currentDay(date, 1) > -60) {
    // 60秒前（不包含60）
    return `${Math.abs(Math.round(currentDay(date, 1)))}秒前`
  } else if (currentDay(date, 2) > -60) {
    // 60分钟前（不包含60）
    return `${Math.abs(Math.round(currentDay(date, 2)))}分钟前`
  } else if (currentDay(date, 3) > -3) {
    // 3小时前（不包含3）
    return `${Math.abs(Math.round(currentDay(date, 3)))}小时前`
  } else if (currentDay(date, 4) > -3) {
    // 3天内
    var today = '今天'
    if (currentDay(date, 4) > -1) {
      today = '今天'
    } else if (currentDay(date, 4) > -2) {
      today = '昨天'
    } else if (currentDay(date, 4) > -3) {
      today = '前天'
    }
    return `${today} ${hour}:${minute}`
  } else if (parseInt(year) !== new Date().getFullYear()) {
    // 不是今年
    return `${year}年${month}月${day}日 ${hour}:${minute}`
  } else {
    return `${month}月${day}日 ${hour}:${minute}`
  }
}

function showDates(startDate, endDate) {
  const nowTime = new Date()
  const startDateTime = new Date(startDate)
  const endDateTime = new Date(endDate)
  let date = ''
  if (isNaN(startDate)) {
    // 判断是否是时间戳
    date = startDate.toString()
  } else {
    date = timeFormat(startDate)
  }
  // 时间戳
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)
  const hour = date.substring(11, 13)
  const today = getDateStr(0) // 今天
  const todayStr = today.split('-')
  let todaysBooleans = false
  todayStr[1] = todayStr[1].length === 1 ? '0' + todayStr[1] : todayStr[1]
  todayStr[2] = todayStr[2].length === 1 ? '0' + todayStr[2] : todayStr[2]
  if (
    year === todayStr[0] &&
    month === todayStr[1] &&
    day === todayStr[2] &&
    new Date() - new Date(startDate) < 10000 &&
    hour === new Date().getHours()
  ) {
    todaysBooleans = true
  } else if (
    year === todayStr[0] &&
    month === todayStr[1] &&
    day === todayStr[2] &&
    new Date() - new Date(startDate) < 60000 &&
    hour === new Date().getHours()
  ) {
    todaysBooleans = true
  } else if (
    year === todayStr[0] &&
    month === todayStr[1] &&
    day === todayStr[2] &&
    new Date() - new Date(startDate) < 3600 * 1000 &&
    hour === new Date().getHours()
  ) {
    todaysBooleans = true
  } else if (
    year === todayStr[0] &&
    month === todayStr[1] &&
    day === todayStr[2] &&
    Math.abs(new Date() - new Date(startDate)) <= 3 * 3600 * 1000
  ) {
    todaysBooleans = true
  }
  if (startDate && endDate) {
    if (nowTime <= startDateTime) {
      return 1
    } else if (
      nowTime > startDateTime &&
      nowTime < endDateTime &&
      !todaysBooleans
    ) {
      return 3
    } else if (
      nowTime > startDateTime &&
      nowTime < endDateTime &&
      todaysBooleans
    ) {
      return 1
    } else if (nowTime >= endDateTime) {
      return 2
    }
  } else if (startDate && !endDate) {
    return 1
  } else if (!startDate && endDate) {
    return 2
  }
}
// 时间戳转化成时间格式
function as(dy, date, type) {
  let handleDate = new Date()
  if (date) {
    handleDate = new Date(date.split('-').join('/'))
  }
  if (type === 1) {
    return ['周日', '周一', '周二', '周三', '周四', '周五', '周六'][dy]
  } else {
    return ['周日', '周一', '周二', '周三', '周四', '周五', '周六'][
      new Date(handleDate.getFullYear(), handleDate.getMonth(), dy).getDay()
    ]
  }
}
// 时间戳转化成时间格式
function timeFormat(timestamp) {
  const time = new Date(timestamp)
  const year = time.getFullYear()
  const month = time.getMonth() + 1
  const date = time.getDate()
  const hours = time.getHours()
  const minutes = time.getMinutes()
  const seconds = time.getSeconds()
  return (
    year +
    '-' +
    add0(month) +
    '-' +
    add0(date) +
    ' ' +
    add0(hours) +
    ':' +
    add0(minutes) +
    ':' +
    add0(seconds)
  )
}

function getDateStr(AddDayCount) {
  const dd = new Date()
  dd.setDate(dd.getDate() + AddDayCount)
  const y = dd.getFullYear()
  const m = dd.getMonth() + 1
  const d = dd.getDate()
  return y + '-' + (Array(2).join(0) + m).slice(-2) + '-' + d
}

// 保证不出现个位数情况
function add0(m) {
  return m < 10 ? '0' + m : m
}

// 根据时间获取任务类型
function getDateBgColor(startDate, endDate) {
  // 1:不在当前时间 无颜色  2:在当前范围  3:范围当前时间前面
  const dd = new Date().getTime()
  if (
    ((startDate || '') === '' || startDate === null) &&
    ((endDate || '') === '' || endDate === null)
  ) {
    return 1
  }
  if ((startDate || '') === '' || startDate === null) {
    if ((endDate || '') !== '' && endDate !== null) {
      const endDateTime = new Date(endDate.replace(/\-/g, '/')).getTime()
      if (endDateTime <= dd) {
        return 3
      } else {
        return 1
      }
    } else {
      return 1
    }
  } else if ((endDate || '') === '' || endDate === null) {
    return 1
  } else {
    const startDateTime = new Date(startDate.replace(/\-/g, '/')).getTime()
    const endDateTime = new Date(endDate.replace(/\-/g, '/')).getTime()
    if (dd >= startDateTime && dd <= endDateTime) {
      return 2
    } else if (dd >= endDateTime) {
      return 3
    } else {
      return 1
    }
  }
}
// 判断当前时间相差几天
function currentDay(data, type, date2) {
  var minutes = 1000 * 60
  var hours = minutes * 60
  var days = hours * 24
  var d = new Date()

  if (type === 4) {
    d = new Date(getDateStr(0).split('-').join('/'))
    data = data.substring(0, 10)
  }
  if (date2) {
    d = new Date(date2.split('-').join('/'))
  }
  var t = d.getTime()
  var x = new Date(data).getTime()
  var y = Math.round((x - t) / days)
  if (type === 1) {
    // 秒
    return (x - t) / 1000
  } else if (type === 2) {
    // 分钟
    return (x - t) / minutes
  } else if (type === 3) {
    // 小时
    return (x - t) / hours
  } else if (type === 4) {
    // 天  不省略
    return (x - t) / days
  } else {
    // 天
    return y
  }
}
// function currentDay(data) {
//   var td = new Date()
//   td = new Date(td.getFullYear(), td.getMonth(), td.getDate())
//   var od = new Date(data)
//   od = new Date(od.getFullYear(), od.getMonth(), od.getDate())
//   var xc = (od - td) / 1000 / 60 / 60 / 24
//   return xc
// }
// 秒 转 时分秒
function formatSeconds(value, type) {
  var theTime = parseInt(value) // 秒
  var middle = 0 // 分
  var hour = 0 // 小时

  if (theTime >= 60) {
    middle = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)
    if (middle >= 60) {
      hour = parseInt(middle / 60)
      middle = parseInt(middle % 60)
    }
  }
  var result = '' + parseInt(theTime) + '秒'
  if (middle > 0) {
    result = '' + parseInt(middle) + '分' + result
  }
  if (hour > 0) {
    result = '' + parseInt(hour) + '小时' + result
  }
  if (type) {
    result = parseInt(value / 60)
  }
  return result
}
// 时分秒转分钟
function formatMinute(value) {
  var result = value
  if (value.indexOf(':') !== -1) {
    var splitValue = result.split(':')
    result = Number(Number(splitValue[0] * 60) + splitValue[1])
  }
  return result
}
// 时间转年月日
function dateToYYYYMMDD(date) {
  var handleDate = new Date()
  if (date) {
    handleDate = new Date(date.split('-').join('/'))
  }
  return `${handleDate.getFullYear()}年${
    handleDate.getMonth() + 1
  }月${handleDate.getDate()}日`
}

export default {
  dateToYYYYMMDD,
  formatMinute,
  formatSeconds,
  formatRecentTime,
  showDates,
  getDateStr,
  getDateBgColor,
  currentDay,
  globalTime
}
