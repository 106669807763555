<template>
  <div class="ceshi">
    <div v-if="disabled">
      <Icon
        icon-class="icon-zhongfu1"
        class="cycle-icon"
        :style="
          row.cycleDate !== null
            ? row.cycleDate.type === 1
              ? ''
              : 'color: #FF9900 !important'
            : ''
        "
        :class="{ disabled: disabled }"
      />
    </div>
    <el-dropdown
      v-else
      trigger="click"
      @visible-change="dropdownVisibleChange"
      @command="typeChange"
    >
      <div class="info-title">
        <Icon
          icon-class="icon-zhongfu1"
          :class="{ active: isActive }"
          class="cycle-icon cycle-icon-te"
          :style="
            row.cycleDate
              ? row.cycleDate.type === 1
                ? ''
                : 'color: #FF9900'
              : ''
          "
        />
        <span v-show="isShow" style="color: #a0a3bd;margin: 4px 0 0 8px;">设置重复</span>
      </div>
      <el-dropdown-menu slot="dropdown" class="el-dropdown-menu-class-clock">
        <el-dropdown-item
          v-for="(val, key) in cycleTypeDict"
          :key="key"
          :command="key"
          :class="
            row.cycleDate
              ? { active: row.cycleDate.type === parseInt(key) }
              : { active: row.type === parseInt(key) }
          "
          >{{ val }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <!-- 自定义周期时间 -->
    <task-custom-cycle-detail
      v-if="customCycleVisible"
      :visible.sync="customCycleVisible"
      :task-id="taskId"
      :row="row"
      @save="customCycleSave"
      @getCustomType="getCustomType"
    />
  </div>
</template>
<script>
import formMixin from '@/utils/mixins/form'
import taskUtil from '@/utils/task.js' // 任务util
import repeatEnum from '@/utils/enums/repeat'
import TaskCustomCycleDetail from './TaskCustomCycleDetail' // 自定义周期时间
const cycleTypeEnum = repeatEnum.CYCLENUMTYPE // 周期类型枚举
export default {
  components: { TaskCustomCycleDetail },
  mixins: [formMixin],
  props: {
    // 任务id
    taskId: {
      required: false
    },
    // 是否禁用
    disabled: {
      required: false,
      default: false
    },
    isShow:{
      default: false
    },
    // 表单对象
    row: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      cycleTypeDict: repeatEnum.REPEATTYPE, // 周期类型字典
      customCycleVisible: false // 自定义重复弹框显示
    }
  },
  computed: {
    // 是否是自定义周期
    isCustomCycle() {
      return cycleTypeEnum.custom === this.form.type
    },
    // 是否有值
    isActive() {
      const type = this.form.type
      if (type && type !== cycleTypeEnum.not) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    // 获取详情
    // 20230330-修改重复调用
    // this.taskId && this.getDetail()
    // 默认重复类型(不重复)
    this.$set(this.form, 'type', cycleTypeEnum.not)
  },
  methods: {
    // 获取任务详情
    getDetail() {
      console.log('TaskCycleDetail-----')
      // const params = {
      //   id: this.taskId
      // }
      this.$apis.TASKDETAILNEW({}, this.taskId).then((rest) => {
        this.row = rest.data
      })
    },
    getCustomType(val) {
      this.form.type = null
      if (this.taskId) {
        this.getDetail()
      }
    },
    // 保存
    async save() {
      console.log('任务id', this.taskId)
      if (this.taskId) {
        this.form.taskId = this.taskId
      }
      this.form.id = this.id
      const path = this.id ? 'TASKREPEATUPDATE' : 'TASKREPEATADD'
      this.submitDetail(path, this.form)
    },
    async submitDetail(path, _obj) {
      this.$apis[path](_obj).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            this.id = res.data.id
            this.row = res.data
            let msg = ''
            // 自定义时间
            msg = taskUtil.cycleDateFmt(this.row)
            this.$emit('save', { id: this.taskId, msg: msg, data: this.row })
            this.$message.success('修改成功')
          }
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 自定义周期保存成功
    customCycleSave(row) {
      this.id = row.id
      this.form.cycleDateId = row.id
      this.row.cycleDateId = row.id
      // 获取详情
      this.getDetail()
      this.$emit('save', { id: row.id, obj: row })
    },
    // 下拉面板出现
    dropdownVisibleChange() {},
    // 周期选择
    typeChange(val) {
      // console.log('周期选择--', val,cycleTypeEnum.custom)
      this.form.type = parseInt(val)
      // 自定义重复
      if (cycleTypeEnum.custom === parseInt(val)) {
        this.customCycleVisible = true
      } else {
        // 保存
        this.save()
      }
    },
    // 周期类型点击事件
    typeClick(val) {
      if (cycleTypeEnum.custom === parseInt(val)) {
        this.customCycleVisible = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-title{
  display: flex;
  justify-content: center;
  align-items: center;
}
.cycle-icon {
  cursor: pointer;
  margin-left: 4px;
  font-size: 18px;
  width: 18px;
  height: 18px;
  color: #a0a3bd;
  margin-top: 4px;
  &.active {
    color: #ff9900;
  }
  &:hover {
    color: #3a78f6;
  }
  &.disabled {
    cursor: not-allowed;
    &.active {
      color: #999;
    }
    &:hover {
      color: #999;
    }
  }
}
</style>
<style lang="scss">
.el-dropdown-menu-class-clock {
  z-index: 3001 !important;
}
</style>
