// todo模块方法库
import apis from '../apis_moudles/index'
import req from '../request'
// 任务新增-新版
const TASKADDE = (params) => {
  return req.post(apis.taskAddE, params)
}
// 任务详情（新）
const TASKDETAILNEW = (params, id) => {
  return req.get(`${apis.taskDetailNew}${id}`, params)
}

// 修改任务状态
const TASKSTATECHANGE = (params) => {
  return req.post(apis.taskStateChange, params)
}
// 修改任务标题
const TASKTITLEUPDATE = (params) => {
  return req.post(apis.taskTitleUpdate, params)
}
// 修改任务优先级
const TASKPRIORITYUPDATE = (params) => {
  return req.put(apis.taskPriorityUpdate, params)
}
// 修改任务描述
const TASKINFORMATIONUPDATE = (params) => {
  return req.post(apis.taskInformationUpdate, params)
}
// 删除任务与KR关联
const TASKDELETEKR = (id) => {
  return req.delete(`${apis.taskDeleteKr}${id}`)
}
// 修改任务负责人及协作人
const TASKCHARGEUSERUPDATE = (params) => {
  return req.post(apis.taskChargeUserUpdate, params)
}
// 修改任务负责人及协作人
const TASKLEADER = (params, id) => {
  return req.put(`${apis.taskLeader}${id}`, params)
}
// 修改任务时间
const TASKTIMEUPDATE = (params) => {
  return req.post(apis.taskTimeUpdate, params)
}
// 删除任务时间
const TASKTIMEDELETE = (params) => {
  return req.post(apis.taskTimeDelete, params)
}
// 修改任务所属
const TASKBELONGUPDATE = (params) => {
  return req.put(apis.taskBelongUpdate, params)
}
// 删除任务协作人
const TASKCHARGEUSERDELETE = (params) => {
  return req.post(apis.taskChargeUserDelete, params)
}
// 删除任务
const TASKDELETE = (params) => {
  return req.post(apis.taskDelete, params)
}
// 查询任务最新动态
const TASKNEWDYNAMICLIST = (params) => {
  return req.get(apis.taskNewDynamicList, {
    params
  })
}
// 查询任务较早的动态
const TASKOLDDYNAMICLIST = (params) => {
  return req.get(apis.taskOldDynamicList, {
    params
  })
}
// 删除任务评论
const TASKDELCOMMENT = (appendUrl, params) => {
  return req.delete(`${apis.taskDelComment}${appendUrl}`, {
    params
  })
}
// 新增任务重复时间
const TASKREPEATADD = (params) => {
  return req.post(apis.taskRepeatAdd, params)
}
// 修改任务重复时间
const TASKREPEATUPDATE = (params) => {
  return req.post(apis.taskRepeatUpdate, params)
}
// 新增任务提醒时间
const TASKREMINDADD = (params) => {
  return req.post(apis.taskRemindAdd, params)
}
// 修改任务提醒时间
const TASKREMINDUPDATE = (params) => {
  return req.post(apis.taskRemindUpdate, params)
}
// 获取任务TODO列表（新）
const TASKTODOLISTNEW = (appendUrl, params) => {
  return req.post(`${apis.taskTodoListNew}${appendUrl}`, params)
}

// 获取TODO任务列表-查看团队某个人的
const TASKSOMETODOLIST = (params) => {
  return req.get(apis.taskSomeTodoList, {
    params
  })
}
// 获取TODO任务列表-查看团队某个人的（new）
const TASKSOMETODOLISTNEW = (params) => {
  return req.get(apis.taskSomeTodoListNew, {
    params
  })
}
// 获取TODO各类型任务数量
const TASKCOUNTBYTYPE = (params) => {
  return req.get(apis.taskCountByType, {
    params
  })
}
// 获取所有关注团队人员id列表
const TASKFOLLOWUSERIDLIST = (params) => {
  return req.get(apis.taskFollowUserIdList, {
    params
  })
}
// 获取关注人员列表
const TASKFOLLOWUSERLIST = (params) => {
  return req.get(apis.taskFollowUserList, {
    params
  })
}
// 批量添加关注
const TASKADDFOLLOWUSERS = (params) => {
  return req.post(apis.taskAddFollowUsers, params)
}
// 删除关注
const TASKDELFOLLOEUSER = (appendUrl, params) => {
  return req.delete(`${apis.taskDelFollowUser}${appendUrl}`, {
    params
  })
}
// 任务列表查询
const TASKALLLIST = (params) => {
  return req.get(apis.taskAllList, {
    params
  })
}
// 获取任务已读未读列表
const TASKREADORNOTLIST = (params) => {
  return req.get(apis.taskReadOrNotList, {
    params
  })
}
// 添加任务评论
const TASKADDCOMMENT = (params) => {
  return req.post(apis.taskAddComment, params)
}
// 获取任务重复
const GETTASKCYCLE = (params) => {
  return req.get(apis.getTaskCycle, {
    params
  })
}
// 获取任务提醒
const GETTASKREMIND = (params) => {
  return req.get(apis.getTaskRemind, {
    params
  })
}
// 任务可见范围修改
const TASKSHAREUPDATE = (params) => {
  return req.post(apis.taskShareUpdata, params)
}
// 任务新增字典-kr,临时
const TASKADDPARAMTODO = (id, params) => {
  return req.get(`${apis.taskAddParamTodo}${id}`, {
    params
  })
}
// 任务新增字典-项目
const TASKADDPARAMPROJECT = (id, params) => {
  return req.get(`${apis.taskAddParamProject}${id}`, {
    params
  })
}
// 任务字典-全局
const TASKPARAMDICT = (params) => {
  return req.get(`${apis.taskParamDict}`, {
    params
  })
}

// 新版-任务详情任务字典(子任务需要查询父任务字典)
const TASKDICTIONARY = (params) => {
  return req.get(apis.taskDictionary, {
    params
  })
}
// 新版-任务修改聚合接口 不支持任务归属不支持任务公开隐私状态不支持任务重复和提醒时间
const TASKUPDATE = (params, id) => {
  return req.put(`${apis.taskUpdate}${id}`, params)
}
// 删除标签

const UPDATETASKLABEL = (params) => {
  return req.put(`${apis.updateTaskLabel}`, params)
}

// 根据任务id获取甘特图依赖数量(如果数量大于0 需要提示清空开始开始时间or结束时间 将会删除依赖关系)
const TASKGANTTRELATION = (id, params) => {
  return req.get(`${apis.taskGanttRelation}${id}`, {
    params
  })
}
// 关注和取消关注任务
const TASKCARECHANGE = (params) => {
  return req.put(`${apis.taskCareChange}`, params)
}
// 待办列表
const TASKWAITLIST = (params) => {
  return req.get(`${apis.taskWaitList}`, {
    params
  })
}
// 添加工时
const ADDTASKWORKHOURS = (params) => {
  return req.post(apis.addTaskWorkHours, params)
}
// 修改更新工时
const UPDATETASKWORKHOURS = (params) => {
  return req.post(apis.updateTaskWorkHours, params)
}
// 删除工时
const DELETETASKWORKHOURS = (params) => {
  return req.post(apis.deleteTaskWorkHours, params)
}

// 工时明细列表
const TASKWORKHOURSINFOLIST = (params) => {
  return req.get(`${apis.taskworkHoursInfoList}`, {
    params
  })
}
// 查询工时明细列表总数
const TASKWORKHOURSINFOLISTTOTAL = (params) => {
  return req.get(`${apis.taskworkHoursInfoListTotal}`, {
    params
  })
}
// 项目工时统计
const WORKHOURSINFOSTATISTICS = (params) => {
  return req.get(`${apis.workingHoursInfoStatistics}`, {
    params
  })
}
// 获取工时能够添加的全部操作人
const WORKHOURSTASKALLUSER = (params) => {
  return req.get(`${apis.workingHoursTaskAllUser}`, {
    params
  })
}
// 获取捷径条件模版
const TODOCONDITONDICTIONARY = (params) => {
  return req.get(`${apis.todoConditionDictionary}`, {
    params
  })
}
// 获取捷径条件模版
const GETUSERSHORTCUTLIST = (params) => {
  return req.get(`${apis.getUserShortCutList}`, {
    params
  })
}
// 根据捷径id获取表格字段
const GETTODOSHORTCUTFIELD = (id, params) => {
  return req.get(`${apis.getTodoShortcutField}${id}`, {
    params
  })
}
// 获取捷径分组字典
const GETTODOSHORTCUTGROUPLIST = (params) => {
  return req.get(`${apis.getTodoShortcutGroupList}`, {
    params
  })
}
// 获取捷径任务
const GETTODOALLTASKBYSHRTCUTFILTER = (url, params) => {
  return req.post(`${url}`, params)
}
// 根据分组标识获取分组详细
const GETTODOGROUPDETAILBYGROUPIDENTIFICATION = (url, params) => {
  return req.post(`${url}`, params)
}
// 根据捷径id获取捷径详细信息
const GETTODOSHORTCUTDETAIL = (id, params) => {
  return req.get(`${apis.getTodoShortcutDetail}${id}`, {
    params
  })
}
// 修改捷径名称
const GETTODOSHORTCUTNAME = (params) => {
  return req.put(apis.getTodoShortcutName, params)
}
// 删除捷径
const GETTODOSHORTCUTDELETE = (id) => {
  return req.delete(`${apis.getTodoShortcutDelete}${id}`)
}
// 保存捷径表格字段
const GETTODOSHORTCUTFIELDEDIT = (params) => {
  return req.post(`${apis.getTodoShortcutFieldEdit}`, params)
}
// 任务搜索
const GETTODOSHORTCUTTASKSEARCH = (params) => {
  return req.get(`${apis.getTodoShortcutTaskSearch}`, {
    params
  })
}
// 新建捷径
const TODOADDSHORTCUT = (params) => {
  return req.post(`${apis.todoAddShortcut}`, params)
}
// 修改捷径
const TODOUPDATESHORTCUT = (params) => {
  return req.put(apis.todoUpdateShortcut, params)
}

// 获取任务全部动态接口
const ALLTASKCOMMENTLIST = (params) => {
  return req.get(`${apis.allTaskCommentList}`, {
    params
  })
}
// 修改任务回收状态
const UPDATETASKISDELLISTBYIDS = (id, params) => {
  return req.post(`${apis.updateTaskIsDelListByIds}${id}`, params)
}
// 修改任务归档状态
const UPDATETASKISCLOSELISTBYIDS = (id, params) => {
  return req.post(`${apis.updateTaskIsCloseListByIds}${id}`, params)
}

export default [
  {
  UPDATETASKISCLOSELISTBYIDS
},
{
  UPDATETASKISDELLISTBYIDS
},
{
  ALLTASKCOMMENTLIST
},
{
  GETTODOSHORTCUTTASKSEARCH
},
{
  GETTODOSHORTCUTFIELDEDIT
},
{
  GETTODOSHORTCUTDELETE
},
{
  GETTODOSHORTCUTNAME
},
{
  GETTODOSHORTCUTDETAIL
},
{
  TODOUPDATESHORTCUT
},
{
  TODOADDSHORTCUT
},
{
  GETTODOSHORTCUTTASKSEARCH
},
{
  GETTODOSHORTCUTFIELDEDIT
},
{
  GETTODOSHORTCUTDELETE
},
{
  GETTODOSHORTCUTNAME
},
{
  GETTODOSHORTCUTDETAIL
},
{
  GETTODOGROUPDETAILBYGROUPIDENTIFICATION
},
{
  GETTODOALLTASKBYSHRTCUTFILTER
},
{
  GETTODOSHORTCUTGROUPLIST
},
{
  GETTODOSHORTCUTFIELD
},
{
  GETUSERSHORTCUTLIST
},

{
  TODOCONDITONDICTIONARY
},
{
  WORKHOURSTASKALLUSER
},
{
  WORKHOURSINFOSTATISTICS
},
{
  TASKWORKHOURSINFOLISTTOTAL
},
{
  TASKWORKHOURSINFOLIST
},
{
  DELETETASKWORKHOURS
},
{
  UPDATETASKWORKHOURS
},
{
  ADDTASKWORKHOURS
},
{
  TASKWAITLIST
},
{
  TASKCARECHANGE
},
{
  TASKGANTTRELATION
},
{
  TASKSOMETODOLISTNEW
},
{
  UPDATETASKLABEL
},
{
  TASKPARAMDICT
},
{
  TASKUPDATE
},
{
  TASKDICTIONARY
},
{
  TASKTODOLISTNEW
},
{
  TASKSHAREUPDATE
},
{
  GETTASKREMIND
},
{
  GETTASKCYCLE
},
{
  TASKADDE
},
{
  TASKDETAILNEW
},
{
  TASKSTATECHANGE
},
{
  TASKTITLEUPDATE
},
{
  TASKPRIORITYUPDATE
},
{
  TASKINFORMATIONUPDATE
},
{
  TASKCHARGEUSERUPDATE
},
{
  TASKTIMEUPDATE
},
{
  TASKTIMEDELETE
},
{
  TASKBELONGUPDATE
},
{
  TASKCHARGEUSERDELETE
},
{
  TASKLEADER
},
{
  TASKDELETE
},
{
  TASKNEWDYNAMICLIST
},
{
  TASKOLDDYNAMICLIST
},
{
  TASKDELCOMMENT
},
{
  TASKREPEATADD
},
{
  TASKREPEATUPDATE
},
{
  TASKREMINDADD
},
{
  TASKREMINDUPDATE
},
{
  TASKSOMETODOLIST
},
{
  TASKCOUNTBYTYPE
},
{
  TASKFOLLOWUSERIDLIST
},
{
  TASKFOLLOWUSERLIST
},
{
  TASKADDFOLLOWUSERS
},
{
  TASKDELFOLLOEUSER
},
{
  TASKALLLIST
},
{
  TASKREADORNOTLIST
},
{
  TASKADDCOMMENT
},
{
  TASKDELETEKR
},
{
  TASKADDPARAMTODO
},
{
  TASKADDPARAMPROJECT
}
]
