<template>
  <div class="align-user-box flex">
    <div class="align-box left-box">
      <div class="align-title">{{ unreadList.length }}人未读</div>
      <div class="align-user-cont">
        <ul class="user-list">
          <li
            v-for="item in unreadList"
            :key="type === 'conclusion' ? item.receiverUser.name : item.name"
            class="user-item flex-a-c-center f-z-14 rel"
          >
            <el-image
              fit="cover"
              :src="
                type === 'conclusion' ? item.receiverUser.avatar || require('@/assets/imgs/common/avatar.png') : item.avatar || require('@/assets/imgs/common/avatar.png')
              "
              class="avatar-img border-radius-20"
            />
            <div class="user-name">
              <wx-open-data
                :id="item.receiverUser.name"
                :nameData="{name:item.receiverUser.name}"
                v-if="type === 'conclusion'"
              />
              <wx-open-data :id="item.name" :nameData="{name:item.name}" v-else />
            </div>
            <el-tooltip :open-delay="300" content="Ta还未读">
              <div class="dot abs" style="display: none"></div>
            </el-tooltip>
            <Icon v-if="item.receiverUserRole === 2" icon-class="icon-fenxiang" class="share-icon" />
          </li>
        </ul>
      </div>
    </div>
    <div class="align-box right-box">
      <div class="align-title">{{ readList.length }}人已读</div>
      <div class="align-user-cont">
        <ul class="user-list">
          <li
            v-for="item in readList"
            :key="type === 'conclusion' ? item.receiverUser.name : item.name"
            class="user-item flex-a-c-center f-z-14 rel"
          >
            <el-image
              fit="cover"
              :src="
                type === 'conclusion' ? item.receiverUser.avatar || require('@/assets/imgs/common/avatar.png') : item.avatar || require('@/assets/imgs/common/avatar.png')
              "
              class="avatar-img border-radius-20"
            />
            <div class="user-name">
              <wx-open-data
                :id="item.receiverUser.name"
                :nameData="{name:item.receiverUser.name}"
                v-if="type === 'conclusion'"
              />
              <wx-open-data :id="item.name" :nameData="{name:item.name}" v-else />
            </div>
            <Icon v-if="item.receiverUserRole === 2" icon-class="icon-fenxiang" class="share-icon" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    readArr: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 任务id
    taskId: {
      type: Number
    },
    // 任务id
    type: {
      type: String,
      default: 'task'
    }
  },
  data() {
    return {
      unreadList: [], // 未读列表
      readList: [] // 已读列表
    }
  },
  created() {
    // 获取已读未读列表
    this.listInit()
    // this.$bus.$on('refreshRead', () => {
    //   this.listInit()
    // })
  },
  destroyed() {
    // this.$bus.$off('refreshRead')
  },
  methods: {
    listInit() {
      this.unreadList = []
      this.readList = []
      if (this.type === 'conclusion') {
        this.unreadList = this.readArr.filter((item) => {
          return item.receiverUserStatus === 0
        })
        this.readList = this.readArr.filter((item) => {
          return item.receiverUserStatus === 1
        })
      } else {
        this.unreadList = this.readArr.filter((item) => {
          return item.status === 1
        })
        this.readList = this.readArr.filter((item) => {
          return item.status === 2
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.align-user-box {
  height: 300px;
  display: flex;
  // position: absolute;
  width: 360px;
  background: #fff;
  box-shadow: 0px 4px 12px 1px rgba(17, 17, 17, 0.04);
  border-radius: 16px;
  // z-index: 1;
  // top: 45px;
  // right: 0px;
  .align-box {
    flex-shrink: 0;
  }
  .left-box {
    width: 180px;
    border-right: 1px dashed #dedede;
    margin: 16px 0;
  }
  .right-box {
    width: 180px;
    margin: 16px 0;
  }
  .align-title {
    line-height: 20px;
    color: #14142b;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    padding: 0px 16px 16px;
  }
  .align-user-cont {
    height: 235px;
    padding: 0 5px;
    overflow-y: auto;
    // @include scroll-beautify;
    .user-item {
      display: flex;
      flex-direction: row;
      line-height: 40px;
      padding: 0 10px;
      align-items: center;
      .user-name {
        flex: 1;
        min-width: 0;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
        &:before {
          content: '';
          display: block;
        }
      }
      .share-icon {
        color: #a0a3bd;
        font-size: 16px;
        margin-left: 8px;
      }
      &:hover {
        background: #f4f8fc;
        border-radius: 12px;
      }
      .dot {
        top: 10px;
        right: 15%;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #eb0d0d;
      }
    }
  }
  .avatar-img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
  }
}
</style>
