<template>
  <div>
    <el-dialog
      custom-class="insertlink-dialog"
      title="添加链接"
      :visible.sync="isShow"
      :before-close="closeInsertLink"
      center
    >
      <el-form ref="linkForm" :model="linkForm" :rules="linkFormRules">
        <el-form-item label="" prop="name">
          <el-input v-model="linkForm.name" placeholder="链接文字" />
        </el-form-item>
        <el-form-item label="" prop="link">
          <el-input v-model="linkForm.link" placeholder="链接地址" />
        </el-form-item>
        <el-form-item>
          <el-button
            class="submit-link"
            type="primary"
            @click="submitLinkForm('linkForm')"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow: this.visible,
      linkForm: {
        name: '',
        link: ''
      },
      linkFormRules: {
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        link: [{ required: true, message: ' ', trigger: 'blur' }]
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    closeInsertLink() {
      this.visible = false
      this.bus.$emit('closeLinkForm')
      this.$emit('change', false)
    },
    submitLinkForm(formName) {
      var url = this.linkForm.link
      // var reg =
      // /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/
      var reg =
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
      if (!reg.test(url)) {
        this.$message({
          msg: '请输入正确的网址！',
          type: 'warn'
        })
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.bus.$emit('getLinkForm', this.linkForm)
          this.closeInsertLink()
          this.linkForm = {
            name: '',
            link: ''
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
<style lang="scss">
.submit-link.el-button {
  width: 100%;
  border-radius: 16px;
  background: #3a78f6;
  height: 50px;
  padding: 0;
  margin: 0;
  line-height: 50px;
  &:hover,
  &:active,
  &:focus {
    background: rgba(58, 120, 246, 0.87);
  }
}
.insertlink-dialog.el-dialog {
  width: 500px !important;
}
</style>
